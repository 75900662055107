import React, { Component } from "react";

const WindowSize = Cp => {
  class HOC extends Component {
    componentDidMount() {
      window.addEventListener("resize", this.windowResized);
      this.windowResized();
    }
    componentWillUnmount() {
      window.removeEventListener("resize", this.windowResized);
    }
    windowResized = () => {
      this.setState({ screenWidth: window.innerWidth });
    };
    state = {
      screenWidth: 0
    };
    render() {
      return <Cp {...this.props} screenWidth={this.state.screenWidth} />;
    }
  }

  return HOC;
};

export default WindowSize;
