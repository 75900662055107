import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./css/fonts.css";
import "./App.css";
import "react-placeholder/lib/reactPlaceholder.css";

import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import Colors from "./extras/Colors";
import HomeContent from "./components/Home/HomeContent";
import MainNav from "./components/Misc/MainNav";
import LoginPageContent from "./components/Login/LoginPage";
import ForgotPageContent from "./components/Login/ForgotPage";
import Footer from "./components/Misc/Footer";
import Classics from "./components/Content/Classics";
import StartlistContent from "./components/Startlist/StartlistContent";

import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";

import { SnackbarProvider } from "notistack";

import { createBrowserHistory } from "history";
import MoviePage from "./components/Movie/MoviePage";
import { CssBaseline, Typography } from "@material-ui/core";
import { Logo } from "./components/Misc/Images";
import FavoritesContent from "./components/Favorites/FavoritesContent";
import UpdatesContent from "./components/Updates/UpdatesContent";

import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";
import Strings from "./extras/Strings";
import ActivateContent from "./components/Admin/ActivateContent";
import { Link } from "react-router-dom";
import RejectContent from "./components/Admin/RejectContent";
import LegalContent from "./components/Misc/LegalContent";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Radium, { StyleRoot } from 'radium';
import MediaQuery from "react-responsive";

const history = createBrowserHistory();

Moment.globalMoment = moment;
Moment.globalLocale = "de";

const lineHeight = 1.3;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.fontGrey
    },
    secondary: {
      main: Colors.lightGrey
    }
  },
  shadows: Array(25).fill("none"),
  shape: { borderRadius: 0 },
  typography: {
    fontFamily: "Helvetica Neue",
    h1: {
      fontSize: 30,
      fontWeight: 500,
      lineHeight: lineHeight
    },
    h2: {
      fontSize: 22,
      fontWeight: 200,
      lineHeight: lineHeight
    },
    h3: {
      fontSize: 30,
      fontWeight: 500
    },
    h4: {},
    h5: {},
    h6: {
      fontSize: 18,
      fontWeight: 300
    },
    subtitle1: {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: lineHeight
    },
    subtitle2: {
      fontSize: 15,
      fontWeight: "normal",
      lineHeight: lineHeight
    },
    overline: {
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: lineHeight
    },
    caption: {},
    body1: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: lineHeight
    },
    body2: {
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: lineHeight
    },
    body3: {
      fontSize: 18,
      fontWeight: "normal",
      lineHeight: 1.6
    },
    button: {
      fontSize: 14
    },
    color: {
      primary: {
        color: Colors.grey
      },
      secondary: {
        color: Colors.lightGrey
      }
    },
    useNextVariants: true
  },
  overrides: {
    MuiSnackbar: {
      root: {
        zIndex: 99999
      }
    },
    MuiSnackbarContent: {
      root: {
        minWidth: "auto !important",
        borderWidth: 0,
        backgroundColor: Colors.grey
      }
    },
    MuiButtonBase: {
      root: {
        color: Colors.lightGrey
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(255, 255, 255, 0.95)"
      }
    },
    MuiDialog: {
      root: {
        zIndex: 9999
      },
      paper: {
        background: "white",
        borderColor: Colors.lightGrey
      },
      paperWidthSm: {
        maxWidth: 700
      },
      paperFullScreen: {
        border:0
      }
    },
    MuiDrawer: {
      docked: {
        zIndex: 9999
      }
    },
    MuiButton: {
      root: {
        fontSize: 18,
        fontWeight: 300,
        textTransform: "none",
        marginTop: 30,
        color: Colors.grey,
        "&&&&:hover": {
          background: Colors.lightLightGrey
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: Colors.lightGrey,
        fontSize: 14
      },// TODO error in browser focused
      /*focused: {
        "&$focused": {
          color: Colors.grey
        }
      }*/
    },
    MuiOutlinedInput: {
      root: {
        color: Colors.grey,
        fontSize: 14,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: Colors.lightGrey,
        "&&&&:hover": {
          borderColor: Colors.grey
        }
      },
      notchedOutline: {
        borderWidth: 0
      }
    },
    MuiInput: {
      root: {
        color: Colors.grey,
        fontSize: 14
      },
      underline: {
        "&&&&:hover:before": {
          borderBottom: "1px solid " + Colors.grey
        },
        "&&&&:hover:after": {
          borderBottom: "1px solid " + Colors.grey
        },
        "&:before": {
          borderBottom: "1px solid " + Colors.lightGrey
        },
        "&:after": {
          borderBottom: "1px solid " + Colors.lightGrey
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
        color: Colors.grey,
        paddingTop: 0,
        paddingBottom: 0,
        boxShadow: "none"
      }
    },
    MuiPaper: {
      root: {
        borderColor: Colors.lightGrey,
        borderWidth: 1,
        borderStyle: "solid"
      }
    },
    MuiListItem: {
      root: {
        minHeight: 50,
        "&$selected": {
          backgroundColor: Colors.lightLightGrey
        }
      }
    },
    MuiListItemText: {
      primary: {
        color: Colors.fontGrey,
        fontSize: 14,
        fontWeight: "normal"
      }
    },
    MuiBadge: {
      root: {
        color: Colors.blue,
        fontWeight: 500
      }
    },
    MuiTooltip: {
      popper: {
        zIndex: 99999,
        opacity: 1
      },
      tooltip: {
        fontSize: 12,
        padding: 8,
        paddingLeft: 12,
        paddingRight: 12,
        backgroundColor: Colors.grey
      }
    },
    MuiIconButton: {
      root: {
        padding: 10
      }
    },
    MuiSelect: {
      root: {},
      select: {
        "&:focus": {
          background: "white"
        }
      },
      icon: {}
    },
    MuiCheckbox: {
      root: {}
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
        color: Colors.grey
      }
    }
  }
});

class App extends Component {
  componentDidMount() {
    // SETUP PROGRESSBAR
    loadProgressBar();
  }
  state = {};
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          hideIconVariant={true}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <Router>
            <Switch>
              <Route exact path="/" component={HomePageComponent} />
              <Route path="/login" component={LoginPageComponent} />
              <Route path="/startliste" component={StartlistPageComponent} />
              <Route path="/classics" component={ClassicsPageComponent} />
              <Route path="/favoriten" component={FavoritesPageContent} />
              <Route path="/updates" component={UpdatesPageContent} />
              <Route path="/movie/:movie_slug/:category_slug" component={MoviePageComponent} />
              <Route path="/activate" component={ActivatePageContent} />
              <Route path="/reject" component={RejectPageContent} />
              <Route path="/legal" component={LegalPageContent} />
              <Route path="/forgot" component={ForgotPageComponent} />
              <Route component={ErrorPageComponent} />
            </Switch>
          </Router>
        </SnackbarProvider>
      </MuiThemeProvider>
    );
  }
}

function SizeWarning(props) {
  return (
    <div style={{ width: "100%", height: "100%", background: "white" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center"
        }}
      >
        <img src={Logo} />
        <Typography
          variant="body1"
          color="primary"
          style={{
            marginTop: 30,
            color: Colors.grey
          }}
        >
          {Strings.mobileWarning}
        </Typography>
      </div>
    </div>
  );
}

function LoginPageComponent() {
  return <StyleRoot><LoginPageContent /></StyleRoot>;
}

function HomePageComponent() {
  return (
    <StyleRoot>
    <React.Fragment>
      <div id={Component.displayname}>
        <MainNav />
        <HomeContent />
      </div>
      <Footer />
    </React.Fragment>
    </StyleRoot>
  );
}

function StartlistPageComponent() {
  return (
    <StyleRoot>
    <React.Fragment>
      <div id={Component.displayname}>
        <MainNav />
        <StartlistContent />
      </div>
      <Footer />
    </React.Fragment>
    </StyleRoot>
  );
}

function ClassicsPageComponent() {
  return (
    <StyleRoot>
    <React.Fragment>
      <div id={Component.displayname}>
        <MainNav />
        <Classics />
      </div>
      <Footer />
    </React.Fragment>
    </StyleRoot>
  );
}

function FavoritesPageContent() {
  return (
    <StyleRoot>
    <React.Fragment>
      <div id={Component.displayname}>
        <MainNav />
        <FavoritesContent />
      </div>
      <Footer />
    </React.Fragment>
    </StyleRoot>
  );
}

function UpdatesPageContent() {
  return (
    <StyleRoot>
    <React.Fragment>
      <div id={Component.displayname}>
        <MainNav />
        <UpdatesContent />
      </div>
      <Footer />
    </React.Fragment>
    </StyleRoot>
  );
}

function MoviePageComponent() {
  return <StyleRoot><MoviePage history={history} /></StyleRoot>;
}

function ErrorPageComponent() {
  return (
    <StyleRoot>
    <div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center"
        }}
      >
        <Link to="/">
          <img src={Logo} />
        </Link>
        <Typography variant="h1" color="primary" style={{ marginTop: 30 }}>
          {Strings.pageNotFound}
        </Typography>
      </div>
    </div>
    </StyleRoot>
  );
}

function ActivatePageContent() {
  return <StyleRoot><ActivateContent /></StyleRoot>;
}

function RejectPageContent() {
  return <StyleRoot><RejectContent /></StyleRoot>;
}

function LegalPageContent() {
  return (
    <StyleRoot>
    <React.Fragment>
      <div id={Component.displayname}>
        <MainNav />
        <LegalContent />
      </div>
      <Footer />
    </React.Fragment>
    </StyleRoot>
  );
}
function ForgotPageComponent() {
  return <StyleRoot><ForgotPageContent /></StyleRoot>;
}

export default Radium(App);
