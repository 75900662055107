import $ from "jquery";
import URLs from "./URLs";

import axios from "axios";
import { CART } from "./Keys";
import { DefaultErrorHandling } from "../components/Misc/AxiosManager";

var successHandler;
var errorHandler;

export default class CartManager {
  constructor(id, type, success, error) {
    successHandler = success;
    errorHandler = error;

    switch (type) {
      case "add_asset":
        Add(id, true);
        break;
      case "add_upload":
        Add(id, false);
        break;
      case "remove":
        Remove(id);
        break;
      case "content":
        Content();
        break;
      case "download":
        Download();
        break;
      case "clear":
        Clear();
        break;
    }
  }
}

function Add(id, isAsset) {
  axios
    .post(URLs.AddToCartURL, isAsset ? { asset_id: id } : { upload_id: id })
    .then(response => {
      const data = response.data;

      if (data.status === "success") {
        successHandler(data.data);
        saveCount(data.data.cart.length);
      } else {
        errorHandler();
      }
    })
    .catch(error => {
      errorHandler();
    });
}

function Remove(id) {
  axios
    .post(URLs.RemoveFromCartURL, { asset_id: id })
    .then(response => {
      const data = response.data;

      if (data.status === "success") {
        successHandler(data.data);
        saveCount(data.data.cart.length);
      } else {
        errorHandler();
      }
    })
    .catch(error => {
      errorHandler();
    });
}

function Content() {
  axios
    .post(URLs.CartURL)
    .then(response => {
      const data = response.data;

      if (data.status === "success") {
        successHandler(data.data);
        saveCount(data.data.cart.length);
      } else {
        errorHandler();
      }
    })
    .catch(error => {
      errorHandler();
    });
}

function Download() {
  axios({
    url: URLs.Domain + URLs.DownloadCartURL,
    method: "GET",
    responseType: "blob"
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "marketingserver.zip");
    document.body.appendChild(link);
    link.click();

    successHandler();
  });
}

function Clear() {
  axios
    .post(URLs.ClearCartURL)
    .then(response => {
      const data = response.data;

      if (data.status === "success") {
        successHandler(data.data);
        saveCount(0);
      } else {
        errorHandler();
      }
    })
    .catch(error => {
      errorHandler();
    });
}

function saveCount(count) {
  localStorage.setItem(CART, count);

  if (count === 0) count = "";
  $("#cartCount").html(count);
}
