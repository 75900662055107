import React, { Component } from "react";
import PageContent from "../Misc/PageContent";
import Colors from "../../extras/Colors";
import SearchBar from "../Topbar/SearchBar";
import SectionHeadline from "../Misc/SectionHeadline";

import "react-placeholder/lib/reactPlaceholder.css";
import ReactPlaceholder from "react-placeholder";
import { moviePosterBigPH, movieListPh } from "../Misc/Placeholder";
import { Link } from "react-router-dom";

import MediaQuery from "react-responsive";
import URLs from "../../extras/URLs";
import Moment from "react-moment";
import moment from "moment/moment.js";
import MoviePoster from "../Misc/MoviePoster";
import { IconButton, Tooltip, GridList, Typography, Button, MuiThemeProvider } from "@material-ui/core";
import { withAxios, DefaultErrorHandling } from "../Misc/AxiosManager";
import Strings from "../../extras/Strings";
import Document from "../Movie/Document";
import DownloadFile from "../../extras/DownloadFile";
import CartManager from "../../extras/CartManager";
import axios from "axios";
import { FavoritesBlackIcon, FavoritesBlackActiveIcon, NotificationsActiveIcon, NotificationsIcon } from "../Misc/Images";

const styles = {
  content: {
    padding: "115px 50px 30px 50px",
    minHeight: "100%",
    flex: 1,
    color: Colors.grey,
    display: "flex",
    '@media (max-width: 320px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 370px) and (min-width: 321px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 480px) and (min-width: 371px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 767px) and (min-width: 481px)': {
      padding: "195px 50px 30px 50px"
    },
    paddingBottom: 100,
  },
  loader: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  list: {
    marginRight: 100,
    width: 450,
    minWidth: 450
  },
  downloads: {
    flex: 1
  },
  poster: {
    width: 540
  },
  date: {
    textAlign: "left",
    width: 83
  },
  title: {
    flex: 1,
    textAlign: "left",
    flexBasis: "auto",
    flexShrink: 1
  },
  group: {
    paddingBottom: 10
  },
  groupTitle: {
    marginTop: 0,
    paddingBottom: 20,
    color: Colors.fontLightGrey
  },
  monthGroup: {
    paddingBottom: 15
  }
};

class MoviesContent extends Component {
  constructor(props) {
    super(props);
    
  }
  componentDidMount() {
    var favClick = this.favClick.bind(this);
    var cp = this;
    this.props.axios
      .post(URLs.StartlistURL)
      .then(response => {
        monthsCount = 0;
        cp.setState({ loaded: true });
        cp.setState({ movies: response.data.data.movies });
      })
      .catch(error => {
        DefaultErrorHandling(error);
      });
  }
  downloadFile = file => {
    axios({
      url: "https://universal-marketingserver.de/download/startliste/" + file,
      method: "GET",
      responseType: "blob"
    }).then(response => {
      const filename = file;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(new Blob([response.data]), filename);
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      }
    });
  };
  state = {
    loaded: false,
    movies: [],
    favoriteMovies: [], 
    favClicked: false
  };

favClick = (movie) => {
  this.props.axios
  .post(URLs.FavoriteURL, {movie_slug: movie.slug })
  .then(response => {
    var favoriteMovies = this.state.favoriteMovies;
    if(favoriteMovies.includes(movie.id)) 
      favoriteMovies.splice(favoriteMovies.indexOf(movie.id), 1 );
    else 
      favoriteMovies.push(movie.id) 
    this.setState({favClicked: true, favoriteMovies : favoriteMovies});
  })
  .catch(error => {
    DefaultErrorHandling(error);
  });

  
}
  render() {
    const cp= this;
    const tbaFilter = d => {
      console.log(d);
      if (moment(d).year() === 1970) return "tba";
      return moment(d).format("DD.MM.YYYY");
    };
    return (
      <PageContent>
        <SearchBar />
        <div style={styles.content}>
          <div style={styles.list}>
            <SectionHeadline>Startliste</SectionHeadline>
            {!this.state.loaded && getListPH(4)}

            {this.state.loaded && (
              <React.Fragment>
                {groupMovies(this.state.movies, true).map(function(group, i) {
                  return (
                    <div style={styles.group} key={i}>
                      <Typography variant="overline" style={styles.groupTitle}>
                      {group.year}
                      </Typography>
                      {group.months.map(function(month, m) {
                        return (
                          <div style={styles.monthGroup} key={m}>
                            {month.movies.map(function(movie, z) {
                              if(!cp.state.favClicked && movie.favorite && !cp.state.favoriteMovies.includes(movie.id)) {
                                cp.state.favoriteMovies.push(movie.id);
                              }
                              if(movie.id !== 194){
                                  return (
                                    <div key={z} style={{ display: "flex" }}>
                                      <MuiThemeProvider
                                        theme={{
                                          overrides: {
                                            MuiButton: {
                                              root: {
                                                background: "transparent",
                                                textTransform: "none",
                                                color: "rgba(0, 0, 0, 0.2)",
                                                "&&&&:hover": { background: "rgba(0, 0, 0, 0.05)" }
                                              }
                                            }
                                          }
                                        }}
                                      >
                                        
                                          <Typography color="primary" variant="body2" style={styles.date}>
                                            <Moment filter={tbaFilter} unix>
                                              {movie.startdate}
                                            </Moment>
                                          </Typography>
                                            <IconButton 
                                              style={{padding: 5,
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                margin: 0,
                                                marginLeft: -8,
                                                marginTop: -5,
                                                marginBottom: 3,
                                                height: 28}} 
                                              id={movie.id} 
                                              onClick={() => cp.favClick(movie, cp)}>
                                                    <img src={cp.state.favoriteMovies.includes(movie.id) ? FavoritesBlackActiveIcon : FavoritesBlackIcon} alt="" />
                                            </IconButton>
                                            <Button
                                            component={Link}
                                            to={"/" + Strings.movie + "/" + movie.slug + "/" + Strings.overview}
                                            style={{
                                              padding: 5,
                                              paddingLeft: 8,
                                              paddingRight: 8,
                                              margin: 0,
                                              marginLeft: -4,
                                              marginRight: -30,
                                              marginTop: -5,
                                              marginBottom: 3,
                                              minHeight: 0,
                                              width: "auto"
                                            }}
                                          >
                                          <Typography color="primary" variant="body1" style={styles.title}>
                                            {movie.title}
                                          </Typography>
                                        </Button>
                                      </MuiThemeProvider>
                                    </div>
                                  );
                                }
                            })}
                          </div>
                        );  
                      })}
                    </div>
                  );
                })}
              </React.Fragment>
            )}
          </div>
          <MediaQuery minWidth={910}>
            <div style={styles.downloads}>
              <SectionHeadline>Downloads</SectionHeadline>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <Document
                  style={{ marginRight: 10 }}
                  downloadFile={URLs.StaticDownloadURL + "/startliste/universal_startliste.pdf"}
                  docType="PDF"
                  docName="Startliste.pdf"
                />
              </div>
            </div>
          </MediaQuery>
        </div>
      </PageContent>
    );
  }
}

var monthsCount = 0;
function countMonths() {
  monthsCount++;
}

function fixedYear(date, groupNew) {
  if (date.year() === 1970) return "VORSCHAU";
  else if (groupNew) {
    if (moment() > date) return "BEREITS GESTARTET";
    else if (date.year() === moment().year()) return "DEMNÄCHST";
  }

  return date.year();
}

function groupMovies(movies, groupNew) {
  var currentYear = null;
  var years = [];
  var rawYears = [];
  var groupedMovies = [];

  // FIND DIFFERENT YEARS
  for (var i = 0; i < movies.length; i++) {
    var date = moment.unix(movies[i].startdate);
    if (fixedYear(date, groupNew) !== currentYear) {
      currentYear = fixedYear(date, groupNew);
      years.push(currentYear);
      rawYears.push(date.year());
    }
  }

  // GROUP MOVIES WITH SAME YEAR
  for (var y = 0; y < years.length; y++) {
    var year = years[y];
    var group = {
      year: year,
      raw_year: rawYears[y],
      movies: [],
      months: []
    };
    for (var i = 0; i < movies.length; i++) {
      var date = moment.unix(movies[i].startdate);
      if (years[y] === fixedYear(date, groupNew)) {
        group.movies.push(movies[i]);
      }
    }
    /*if(group.year === 'BEREITS GESTARTET'){
      group.movies.reverse();
    }*/
    groupedMovies.push(group);
  }

  for (var g = 0; g < groupedMovies.length; g++) {
    // FIND DIFFERENT MONTHS
    var months = [];
    var monthNames = [];
    var currentMonth = null;
    var group = groupedMovies[g];

    for (var m = 0; m < group.movies.length; m++) {
      var movie = group.movies[m];
      var date = moment.unix(movie.startdate);
      if (date.month() !== currentMonth) {
        months.push(date.month());
        monthNames.push(date.format("MMMM"));
        currentMonth = date.month();
      }
    }

    // GROUP MOVIES BY MONTH
    for (var m = 0; m < months.length; m++) {
      var monthGroup = {
        month: months[m],
        name: monthNames[m],
        movies: []
      };
      for (var i = 0; i < group.movies.length; i++) {
        var movie = group.movies[i];
        var date = moment.unix(movie.startdate);
        if (months[m] === date.month()) {
          monthGroup.movies.push(movie);
        }
      }
      group.months.push(monthGroup);
    }
    groupedMovies[g] = group;
  }
  // SEARCH VORSCHAU
  var index;
  for (var i = 0; i < groupedMovies.length; i++) {
    var group = groupedMovies[i];
    if (group.year === "VORSCHAU") index = i;
  }

  // REMOVE AND ADD TO END OF ARRAY
  if (index >= 0) {
    var temp = groupedMovies[0];
    groupedMovies.splice(index, 1);
    groupedMovies.push(temp);
  }

  return groupedMovies;
}

function getListPH(count) {
  return (
    <React.Fragment>
      {[...Array(count)].map(function(x, i) {
        return (
          <ReactPlaceholder key={i} ready={false} children={""} showLoadingAnimation customPlaceholder={movieListPh} />
        );
      })}
    </React.Fragment>
  );
}

function getPosterPH(count) {
  return (
    <React.Fragment>
      {[...Array(count)].map(function(x, i) {
        return (
          <ReactPlaceholder
            key={i}
            ready={false}
            children={""}
            showLoadingAnimation
            customPlaceholder={moviePosterBigPH}
          />
        );
      })}
    </React.Fragment>
  );
}
export default withAxios(MoviesContent);
