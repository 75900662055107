import React, { Component } from "react";
import { ButtonBase } from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "./Images";

const styles = {
  container: {
    zIndex: 99999,
    width: 50,
    height: 150
  }
};

class ArrowButton extends Component {
  state = {};
  render() {
    return (
      <ButtonBase style={{ ...styles.container, ...this.props.style }} onClick={this.props.onClick}>
        <img src={this.props.right ? ArrowRight : ArrowLeft} alt="" />
      </ButtonBase>
    );
  }
}

export default ArrowButton;
