import React, { Component } from "react";
import $ from "jquery";

class ResetPageScroll extends Component {
  componentDidMount() {
    // RESET SCROLL
    $("body").scrollTop(0);
  }
  state = {};
  render() {
    return <React.Fragment />;
  }
}

export default ResetPageScroll;
