import URLs from "./URLs";
import FileSaver from "file-saver";

import axios from "axios";
import { TOKEN } from "./Keys";

export default class DownloadFile {
  constructor(id, filename, isAsset) {
    axios({
      url: URLs.Domain + (isAsset ? URLs.DownloadAssetURL : URLs.DownloadURL) + "/" + id,
      method: "GET",
      responseType: "blob"
    }).then(response => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(new Blob([response.data]), filename);
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      }
    });
  }
}
