import React, { Component } from "react";
import axios from "axios";
import URLs from "../../extras/URLs";
import { TOKEN } from "../../extras/Keys";
import { setupCache } from "axios-cache-adapter";

const withAxios = Child => {
  class AxiosManager extends Component {
    componentWillMount() {
      var token = localStorage.getItem(TOKEN);

      const cache = setupCache({
        maxAge: 15 * 60 * 1000,
        debug: false
      });

      axios.defaults.baseURL = URLs.Domain;
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.adapter = cache.adapter;
    }
    goToLogin = () => {
      if (window.location.pathname !== "/login") window.location.assign("/login");
    };
    state = {};
    render() {
      return <Child {...this.props} axios={axios} />;
    }
  }

  return AxiosManager;
};

function DefaultErrorHandling(error) {
  if (window.location.pathname !== "/login") window.location.assign("/login");
}

export { withAxios, DefaultErrorHandling };
