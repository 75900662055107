import React, { Component } from "react";
import SectionHeadline from "../Misc/SectionHeadline";

import "react-placeholder/lib/reactPlaceholder.css";
import ReactPlaceholder from "react-placeholder";
import { TextBlock } from "react-placeholder/lib/placeholders";
import Colors from "../../extras/Colors";
import Moment from "react-moment";
import URLs from "../../extras/URLs";
import ImageLoader from "../Misc/ImageLoader";
import { Typography } from "@material-ui/core";
import { GS, TD, IMAX, OV } from "../Misc/Images";
import WindowSize from "../Misc/WindowSize";
import Radium, { StyleRoot } from 'radium';
import { detect } from "detect-browser";
const browser = detect();

const styles = {
  container: {
    display: "flex",
    '@media (max-width: 767px)': {
      display: "block"
    },
  },
  overview: {
    flex: 1,
    paddingRight: 50,
    '@media (min-width: 767px)': {
      minWidth: 400
    },
  },
  awards: {
    flex: 1,
    paddingRight: 50,
    minWidth: 320
  },
  supports: {
    width: 160
  },
  line: {
    display: "flex",
    paddingBottom: 7
  },
  title: {
    width: 120
  },
  content: {
    flex: 1
  }
};

class MovieOverview extends Component {
  componentDidUpdate() {
    if (this.props.data.movie && !this.state.loaded) {
      this.setState({ loaded: true });
    }
  }
  state = {
    loaded: false
  };
  render() {
    const isIE = browser.name === "ie";
    
    let hasAwards = false;
    let hasSupports = false;
    let hasAcademyAwards = false;
    if (typeof this.props.data.contents !== "undefined") {
        if (typeof this.props.data.contents.awards !== "undefined") {
          //console.log("awards: " + this.props.data.contents.awards.contents.length);
          hasAwards = this.state.loaded && this.props.data.contents.awards.contents.length > 0;
        }
        if (typeof this.props.data.contents.supports !== "undefined") {
          //console.log("supports: " + this.props.data.contents.supports.contents.length);
          hasSupports = this.state.loaded && this.props.data.contents.supports.contents.length > 0;
        }
        if (typeof this.props.data.contents.awards.contents !== "undefined") {
            hasAcademyAwards = this.state.loaded && hasAwards && checkAwards(this.props.data.contents.awards.contents);
        }
    }
    const isWide = this.props.screenWidth > 1300;
    return (
      <StyleRoot>
      <ReactPlaceholder customPlaceholder={OverviewPH(this.props.title)} ready={this.state.loaded} showLoadingAnimation>
        <div style={styles.container}>
          <div style={{ ...styles.overview, flexGrow: isIE ? 0 : "auto" }}>
            <SectionHeadline>{this.props.title}</SectionHeadline>
            {this.state.loaded && (
              <React.Fragment>
                {this.props.data.contents.overview.contents.map(function(item, i) {
                  return (
                    <div style={{ ...styles.line, paddingTop: item.title.indexOf("FSK") !== -1 ? 7 : 0 }} key={i}>
                      <Typography color="primary" variant="body1" style={styles.title}>
                        {item.title + ":"}
                      </Typography>
                      <Typography color="primary" variant="body2" style={styles.content}>
                      {dynamicContent(item)}
                      </Typography>
                    </div>
                  );
                })}
              </React.Fragment>
            )}
          </div>

          {(hasAwards || hasSupports) && (
            <div style={{ display: hasAcademyAwards && isWide ? "flex" : "block", flex: 1 }}>
              {hasAwards && (
                <div style={styles.awards}>
                  <SectionHeadline style={{ opacity: hasAcademyAwards ? 1 : 0 }}>Auszeichnungen</SectionHeadline>
                  {this.props.data.contents.awards.contents.map(function(item, i) {
                    return (
                      <div style={{ ...styles.line, display: hasAcademyAwards ? "flex" : "block" }} key={i}>
                        <Typography color="primary" variant="body1" style={styles.title}>
                          {item.title + ":"}
                        </Typography>
                        {!hasAcademyAwards && <div style={{ height: 20 }} />}
                        <Typography color="primary" variant="body2" style={styles.content}>
                          {dynamicContent(item)}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              )}
              {hasSupports && (
                <div style={{ ...styles.supports, marginTop: (hasAcademyAwards || !hasAwards) && isWide ? 48 : 20 }}>
                  <Typography color="primary" variant="body1">
                    Dieser Film unterstützt:
                  </Typography>
                  <div style={{ display: hasAcademyAwards && isWide ? "block" : "flex", alignItems: "center" }}>
                    {this.props.data.contents.supports.contents.map(function(item, i) {
                      return <SupportsImage item={item} />;
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </ReactPlaceholder>
      </StyleRoot>
    );
  }
}

function OverviewPH(title) {
  const content = (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1, marginRight: 50, minWidth: 350 }}>
        <SectionHeadline>{title}</SectionHeadline>
        <TextBlock rows={10} color={Colors.lightGrey} />
      </div>
      <TextBlock rows={9} color={Colors.lightGrey} style={{ marginTop: 45, paddingRight: 50 }} />
      <TextBlock rows={3} color={Colors.lightGrey} style={{ marginTop: 45 }} />
    </div>
  );

  return content;
}

function SupportsImage(props) {
  var img = null;
  var size = {};
  switch (props.item.title) {
    case "greta":
      img = GS;
      size = { width: 98, height: 41 };
      break;
    case "3d":
      img = TD;
      size = { width: 35, height: 14 };
      break;
    case "imax":
      img = IMAX;
      size = { width: 70, height: 13 };
      break;
    case "ov":
      img = OV;
      size = { width: 33, height: 24 };
      break;
  }

  return (
    <div style={{ marginTop: 20, marginRight: 30 }}>
      <img src={img} alt="" />
    </div>
  );
}

function dynamicContent(item) {
  var imageWidth = 120;
  switch (item.type) {
    case "timestamp":
      return (
        <Moment format="DD. MMMM YYYY" unix>
          {item.content}
        </Moment>
      );
      case "text":
        let output;
        if(item.content === '1970-01-01'){
            output = 'tba';
        }else{
          output = item.content;
        }
        return(output);
    case "image":
      if (item.title.indexOf("FSK") != -1) {
        imageWidth = 45;
      } else if (item.title.indexOf("Prädikat") != -1) {
        imageWidth = 84;
      }
      return (
        <ImageLoader
          containerStyle={{ width: imageWidth, marginTop: 0, marginBottom: 10, alignItems: "flex-start" }}
          imageStyle={{ width: "100%" }}
          src={URLs.MediaURL + "/" + item.content}
        />
      );
  }

  var textParts = item.content.split("\n");

  return (
    <React.Fragment>
      {textParts.map(function(text, i) {
        return (
          <div
            style={{ marginTop: i == 1 ? 5 : 0 }}
            dangerouslySetInnerHTML={{
              __html: text
            }}
          />
        );
      })}
    </React.Fragment>
  );
}

function checkAwards(awards) {
  for (var i = 0; i < awards.length; i++) {
    var award = awards[i];
    if (award.title.toLowerCase() === "gewonnen" || award.title.toLowerCase() === "nominierungen") return true;
  }
  return false;
}

export default WindowSize(Radium(MovieOverview));
