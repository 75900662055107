import React, { Component } from "react";
import { Fade } from "@material-ui/core";

import Radium from "radium";
import { Logo } from "./Images";
import URLs from "../../extras/URLs";

const styles = {
  container: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  logoContainer: {
    position: "absolute",
    background: "white",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0
  },
  logo: {
    position: "absolute",
    width: "50%",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
};

class ImageLoader extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.src !== this.props.src) this.loadImage(this);
  }
  componentWillMount() {
    this.loadImage(this);
  }
  loadImage = cp => {
    this.setState({ loaded: false });

    var image = new Image();
    image.src = cp.props.src;
    image.onload = function() {
      cp.setState({ loaded: true, src: cp.props.src });
    };
    image.onerror = function() {
      cp.setState({ default: true });
    };
  };

  state = {
    default: false,
    loaded: true,
    src: null
  };
  render() {
    const showImage = this.state.loaded && this.state.src !== URLs.MediaURL;
    return (
      <React.Fragment>
        <div ref="container" style={[styles.container, this.props.containerStyle]}>
          <Fade timeout={500} in={this.props.showDefault && this.state.default}>
            <div style={styles.logoContainer}>
              <img style={styles.logo} src={Logo} alt="" />
            </div>
          </Fade>
          <Fade timeout={500} in={showImage}>
            <img ref="image" style={[ImageStyle(this), this.props.imageStyle]} src={this.state.src} />
          </Fade>
        </div>
      </React.Fragment>
    );
  }
}

function ImageStyle(cp) {
  if (cp.props.full) {
    return { width: "100%", height: "100%", objectFit: "cover" };
  }
  if (cp.props.poster) {
    return { width: "100%", height: "auto" };
  }
  if (cp.props.adaptive) {
    return {
      maxWidth: "100%",
      maxHeight: "100%",
      height: "inherit !important"
    };
  }
  if (cp.props.classic) {
    return {
      width: "100%",
      height: "100%"
    };
  }
}

export default Radium(ImageLoader);
