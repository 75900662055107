import React, { Component } from "react";
import MainNav from "../Misc/MainNav";
import MovieContent from "./MovieContent";
import Footer from "../Misc/Footer";
import MovieNav from "./MovieNav";
import MediaQuery from "react-responsive";
import Radium, { StyleRoot } from 'radium';
import $ from "jquery";
import MovieNavMobile from "./MovieNavMobile";
class MoviePage extends Component {
  componentDidUpdate(prevProps, prevState) {
    //alert(this.state.currentPage);
  }
  state = {
    currentPage: "",
    currentPageTitle: ""
  };
  componentDidMount() {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
      var goodHeight = $(document).innerHeight() - 100;
      $("#movie-page").css("height", goodHeight + 'px'); 
      $("#movie-page").css("min-height", goodHeight + 'px');
    }
      handleResize = () => {
        this.setState({ documentHeight: $(document).innerHeight() });
        var goodHeight = $(document).innerHeight() - 100;
        $("#movie-page").css("min-height", goodHeight + 'px'); 
        $("#movie-page").css("height", goodHeight + 'px'); 
      };    
  render() {
    return (
      <React.Fragment>
        <MediaQuery minWidth={768}>
        <div id="movie-page" style={{ display: "flex" }}>
          <MainNav collapsed={true} history={this.props.history} moviePage={this} currentPage={this.state.currentPage}>
            <MovieNav
              currentPage={this.state.currentPage}
              moviePage={this}
              history={this.props.history}
              style={{ marginTop: 43 }}
            />
          </MainNav>
          <MovieContent currentPage={this.state.currentPage} />
        </div>
          <Footer />
        </MediaQuery>
        <MediaQuery maxWidth={767}>
        <div id="movie-page" style={{ display: "flex" }}>
          <MainNav collapsed={true} history={this.props.history} moviePage={this} currentPage={this.state.currentPage}>
            <MovieNavMobile
              currentPage={this.state.currentPage}
              moviePage={this}
              currentPageTitle={this.state.currentPageTitle}
              history={this.props.history}
              style={{ marginTop: 43 }}
            />
          </MainNav>
          <MovieContent currentPage={this.state.currentPage} currentPageTitle={this.state.currentPageTitle} />
        </div>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

export default MoviePage;
