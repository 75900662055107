import React, { Component } from "react";
import { Button, CircularProgress, Fade } from "@material-ui/core";
import Colors from "../../extras/Colors";
import Radium, { StyleRoot } from 'radium';

const styles = {
  container: {
    position: "relative",
    float: "left"
  },
  button: {
    position: "relative",
    height: 44,
    '@media (max-width: 371px)': {
      fontSize:13
    }
  },
  loaderContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
    top: 0
  },
  loader: {
    color: Colors.grey
  }
};

class LoadingButton extends Component {
  state = {};
  render() {
    return (
      <StyleRoot>
      <Button
        type={this.props.type}
        style={{ ...styles.button, ...this.props.style }}
        disabled={this.props.loading}
        onClick={this.props.onClick}
        variant="outlined"
        fullWidth={this.props.fullWidth}
      >
        <Fade in={!this.props.loading}>
          <div>{this.props.children}</div>
        </Fade>
        {this.props.loading && (
          <div style={styles.loaderContainer}>
            <CircularProgress size={20} style={styles.loader} />
          </div>
        )}
      </Button>
      </StyleRoot>
    );
  }
}

export default LoadingButton;
