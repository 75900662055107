import React, { Component } from "react";
import SearchBar from "../Topbar/SearchBar";

import PageContent from "../Misc/PageContent";
import SectionHeadline from "../Misc/SectionHeadline";
import Colors from "../../extras/Colors";

import ReactPlaceholder from "react-placeholder";

import MediaQuery from "react-responsive";

import { uploadPH } from "../Misc/Placeholder";
import Upload from "./Upload";
import URLs from "../../extras/URLs";
import MovieList from "./MovieList";
import { withAxios, DefaultErrorHandling } from "../Misc/AxiosManager";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import moment from "moment";
import $ from "jquery";
import Radium, { StyleRoot } from 'radium';

const styles = {
  content: {
    padding: "115px 50px 30px 50px",
    minHeight: "100%",
    color: Colors.grey,
    '@media (max-width: 320px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 370px) and (min-width: 321px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 480px) and (min-width: 371px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 767px) and (min-width: 481px)': {
      padding: "195px 50px 30px 50px"
    },
    paddingBottom: 100,
  },
  movies: {
    position: "relative",
    flex: 1,
    paddingRight: 50
  },
  updates: {
    width: 230
  },
  toggleIconsContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: -10
  },
  toggleIcons: {
    padding: 5,
    marginTop: -18
  }
};

const UPDATES_HEIGHT = 80;

class StartContent extends Component {
  componentDidMount() {
    // LOAD DATA
    var cp = this;

    // FAV MOVIES
    this.props.axios
      .post(URLs.FavoritesURL)
      .then(response => {
        cp.setState({ favMovies: this.sortByRelease(response.data.data.favorites), favsLoaded: true });
      })
      .catch(error => {
        DefaultErrorHandling(error);
      });

    // MOVIES
    this.props.axios
      .post(URLs.NewMoviesURL)
      .then(response => {
        cp.setState({
          newMovies: this.newMoviesFilter(response.data.data.movies),
          cinemaMovies: this.cinemaMoviesFilter(response.data.data.movies),
          moviesLoaded: true
        });
      })
      .catch(error => {
        DefaultErrorHandling(error);
      });

    // UPDATES
    this.props.axios
      .post(URLs.UpdatesURL)
      .then(response => {
        cp.setState({ updates: response.data.data.updates, updatesLoaded: true }, () => this.setContentHeight());
      })
      .catch(error => {
        DefaultErrorHandling(error);
      });
  }
  setContentHeight = () => {
    // SET CONTENT HEIGHT
    const updates = this.state.updates;
    this.setState({ updates: [] }, () => {
      const contentHeight = $("#content").outerHeight();
      this.setState({ contentHeight: contentHeight });
      this.setState({ updates: updates });
    });
  };
  getUpdates = nr => {
    return this.state.updates.slice(0, nr);
  };
  cinemaMoviesFilter = movies => {
    let cinemaArray = [];

    movies.forEach(movie => {
      const startDate = moment.unix(movie.startdate);
      const date = moment();
      if (startDate.isBefore(date) && movie.startdate !== 0)
      {
        cinemaArray.push(movie);
      }
    });
      // reverse movies
      cinemaArray.reverse();
    return cinemaArray;
  };
  newMoviesFilter = movies => {
    let newArray = [];
    let tbaArray = [];
    let returnArray = [];

    movies.forEach(movie => {
      const startDate = moment.unix(movie.startdate);
      const date = moment();

      if (startDate.isAfter(date) && movie.startdate !== 0) newArray.push(movie);
      if (movie.startdate === 0) tbaArray.push(movie);
      
    });
    returnArray = $.merge( $.merge( [], newArray ), tbaArray );
    return returnArray;
  };
  sortByRelease = movies => {
    movies = movies.sort(function(a, b) {
      return a.startdate > b.startdate;
    });

    return (movies = this.withContent(movies));
  };
  withContent = movies => {
    var newMovies = movies.filter(function(movie) {
      return movie.has_content;
    });

    return newMovies;
  };
  state = {
    contentHeight: 0,
    cinemaMovies: [],
    newMovies: [],
    moviesLoaded: false,
    favMovies: [],
    favsLoaded: false,
    updatesLoaded: false,
    updates: []
  };
  
  render() {
    const cp = this;
    return (
      <StyleRoot>
      <PageContent>
        <SearchBar />
    
        <div style={styles.content}>
          <MediaQuery minWidth={768}>
            <Typography variant="h1" color="primary" style={{ marginBottom: 20 }}>
              Willkommen auf dem Universal Marketingserver
            </Typography>
          </MediaQuery>
          <div id="content" style={{ flex: 1, display: "flex", paddingBottom: 100 }}>
            <div style={styles.movies}>
              <MovieList
                title="Bereits gestartet"
                size="big"
                movies={this.state.cinemaMovies}
                toggleWrap
                onToggleWrap={() => this.setContentHeight()}
                loaded={this.state.moviesLoaded}
                href="/startliste"
              />
              <div style={{ marginTop: 10 }} />
              <MovieList
                title="Demnächst"
                size="big"
                movies={this.state.newMovies}
                toggleWrap
                onToggleWrap={() => this.setContentHeight()}
                loaded={this.state.moviesLoaded}
                href="/startliste"
              />
              <div style={{ marginTop: 10 }} />
              <MovieList
                title="Favoriten"
                size="small"
                movies={this.state.favMovies}
                toggleWrap
                onToggleWrap={() => this.setContentHeight()}
                loaded={this.state.favsLoaded}
                href="/favoriten"
              />
            </div>
            <MediaQuery minWidth={1150}>
              <div style={styles.updates}>
                {/* UPDATES */}
                <Link to="/updates">
                  <SectionHeadline>Letzte Updates</SectionHeadline>
                </Link>
                <div>
                  {/* PLACEHOLDER */}
                  {!this.state.updatesLoaded && (
                    <React.Fragment>
                      <ReactPlaceholder ready={false} children={""} customPlaceholder={uploadPH} showLoadingAnimation />
                      <ReactPlaceholder ready={false} children={""} customPlaceholder={uploadPH} showLoadingAnimation />
                      <ReactPlaceholder ready={false} children={""} customPlaceholder={uploadPH} showLoadingAnimation />
                      <ReactPlaceholder ready={false} children={""} customPlaceholder={uploadPH} showLoadingAnimation />
                      <ReactPlaceholder ready={false} children={""} customPlaceholder={uploadPH} showLoadingAnimation />
                      <ReactPlaceholder ready={false} children={""} customPlaceholder={uploadPH} showLoadingAnimation />
                      <ReactPlaceholder ready={false} children={""} customPlaceholder={uploadPH} showLoadingAnimation />
                    </React.Fragment>
                  )}
                  {this.state.updatesLoaded && (
                    <React.Fragment>
                      {this.getUpdates(Math.floor(this.state.contentHeight / UPDATES_HEIGHT)).map(function(movie, i) {
                        return (
                          <Upload
                            style={{
                              position: "relative",
                              paddingTop: i === 0 ? 0 : 15,
                              paddingBottom: i === Math.floor(cp.state.contentHeight / UPDATES_HEIGHT) ? 0 : 15
                            }}
                            key={i}
                            id={i}
                            count={Math.floor(cp.state.contentHeight / UPDATES_HEIGHT) - 1}
                            data={movie}
                            home
                          />
                        );
                      })}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </MediaQuery>
          </div>
        </div>
      </PageContent>
      </StyleRoot>
    );
  }
}

export default withAxios(Radium(StartContent));
