const URLs = {
  //Domain: "http://adminms.upig.de/api",
  Domain: "https://admin.universal-marketingserver.de/api",
  //MediaURL: "http://ms.upig.de/storage/",
  MediaURL: "https://universal-marketingserver.de/storage/",
  //StaticDownloadURL: "http://ms.upig.de/download",
  StaticDownloadURL: "https://universal-marketingserver.de/download",
  MovieURL: "/movie",
  MovieContentURL: "/contents/category",
  MovieMenuURL: "/menu",
  StartlistURL: "/movie/startlist",
  FavoritesURL: "/movie/favorites",
  FavoritesPageURL: "/movie/favorites/extended",
  NewMoviesURL: "/movie/current",
  UpdatesURL: "/updates/latest",
  FavoritesUpdatesURL: "/updates/favorite",
  LoginURL: "/auth/login",
  RegisterURL: "/auth/register",
  LogoutURL: "",
  DownloadURL: "/download/zip",
  DownloadAssetURL: "/download/zip/asset",
  FavoriteURL: "/favorite",
  SearchURL: "/search/movie",
  AddToCartURL: "/cart/add",
  RemoveFromCartURL: "/cart/remove",
  CartURL: "/cart/show",
  ClearCartURL: "/cart/delete",
  DownloadCartURL: "/cart/download",
  ProfileURL: "/auth/user",
  UpdateProfileURL: "/auth/user/update",
  AcceptTermsURL: "/auth/user/terms",
  RegisterFieldsURL: "/auth/register/fields",
  ForgotPasswordURL: "/password/forgot",
  ResetPasswordURL: "/password/reset",
  ActivateURL: "/auth/activate/",
  RejectURL: "/auth/reject/"
};

export default URLs;
