import React, { Component } from "react";
import Colors from "../../extras/Colors";

import IconButton from "@material-ui/core/IconButton";

import FacebookIcon from "../../img/FacebookWhite.svg";
import YoutubeIcon from "../../img/YoutubeWhite.svg";
import InstagramIcon from "../../img/InstagramWhite.svg";
import NotificationsIcon from "../../img/NotificationsWhite.svg";
import DownloadsIcon from "../../img/DownloadsWhite.svg";
import SearchIcon from "../../img/SearchIcon.svg";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/de";
import {
  Button,
  Avatar,
  Grid,
  Input,
  MenuItem,
  Menu,
  Typography,
  Fade,
  GridList,
  CircularProgress,
  Tooltip,
  Popper,
  Paper,
  MenuList,
  ClickAwayListener
} from "@material-ui/core";

import URLs from "../../extras/URLs";
import MediaQuery from "react-responsive";
import Cart from "./Cart";

import $ from "jquery";
import { DropdownIconWhite, AppStoreIcon, GooglePlayIcon } from "../Misc/Images";
import Profile from "./Profile";

import Radium, { StyleRoot } from 'radium';
import { CART, BUSINESS_NAME, USER_NAME } from "../../extras/Keys";
import MoviePoster from "../Misc/MoviePoster";
import Strings from "../../extras/Strings";
import { withAxios, DefaultErrorHandling } from "../Misc/AxiosManager";
import ImageLoader from "../Misc/ImageLoader";
import { Z_FIXED } from "zlib";

const styles = {
  bar: {
    position: "fixed",
    width: "calc(100% - 220px)",
    height: 85,
    zIndex: 20,
  '@media (max-width: 320px)': {
    width: "100%"
  },
  '@media (max-width: 370px) and (min-width: 321px)': {
    width: "100%"
  },
  '@media (max-width: 480px) and (min-width: 371px)': {
    width: "100%"
  },
  '@media (max-width: 700px) and (min-width: 481px)': {
    width: "100%"
  },
  '@media (max-width: 767px)': {
    width: "100%",
    top:110,
    height:50,
    marginBottom:20
  }
  },
  bgColor: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: Colors.blue,
    '@media (max-width: 767px)': {
      height: 65
    }
   
  },
  search: {
    position: "absolute",
    left: 50,
    top: "50%",
    transform: "translateY(-50%)",
    width: "calc(100% - 100px)",
    margin: 0
  },
  searchmobile: {
    position: "absolute",
    left: 20,
    top: "50%",
    transform: "translateY(-50%)",
    width: "calc(100% - 100px)",
    margin: 0,
    borderBottom: "1px solid white"
  },
  searchIcon: {
    paddingBottom: 0
  },
  input: {
    fontSize: 22,
    color: "white",
    fontWeight: 300,
    width: "100%"
  },
  inputmobile: {
    fontSize: 20,
    color: "white",
    fontWeight: 300,
    width: "calc(100% - 100px)",
    opacity: 1
  },
  icons: {
    display: "flex",
    position: "absolute",
    right: 50,
    top: "50%",
    transform: "translateY(-50%)",
    alignItems: "center"
  },
  icon: {
    padding: 8
  },
  profileButton: {
    backgroundColor: "rgba(0,0,0,0)",
    maxHeight: 45,
    padding: 0,
    margin: 0
  },
  name: {
    color: "white",
    padding: 5,
    marginLeft: 15
  },
  avatar: {
    backgroundColor: "white",
    color: Colors.fontGrey,
    fontWeight: 400,
    fontSize: 14,
    marginLeft: 5,
    width: 45,
    height: 45
  },
  carticon :{
     display: "flex", 
     alignItems: "center", 
     marginRight: 10,
     //position: "fixed",
     top:10,
    right: 20,
    position: "absolute"
  }
};

const calendarStrings = {
  lastDay: "[Gestern -] LT",
  sameDay: "[Heute -] LT",
  nextDay: "[Morgen -] LT",
  lastWeek: "DD. MMMM YYYY [-] LT",
  nextWeek: "DD. MMMM YYYY [-] LT",
  sameElse: "DD. MMMM YYYY [-] LT"
};

const currentRoute = window.location.pathname;

var searchTimer;

class SearchBar extends Component {
  componentDidMount() {
    // Load User Info
    this.setState({ businessName: localStorage.getItem(BUSINESS_NAME) });
    this.setState({ username: localStorage.getItem(USER_NAME) });
    this.loadNotifications();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if($(document).innerWidth() >= 768){
      this.setState({ mediaqueryWidth: $(document).innerWidth() - 320 }); 
    }
    else{
      this.setState({ mediaqueryWidth: $(document).innerWidth() - 93 }); 
    }
    let path = window.location.pathname.split("/");
    let movieSlug = path[1];
    if(movieSlug !== 'movie'){
      this.setState({ showSearchBar: true });
    }
    else {
      this.setState({ showSearchBar: false });
    }  
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({ cartCount: localStorage.getItem(CART) });
    }
  }
  search = e => {
    this.setState({ searching: true });
    var cp = this;
    var target = e.target;

    // SEARCH ONLY WHEN USER STOPS TIPPING
    clearTimeout(searchTimer);
    searchTimer = setTimeout(function() {
      // SEARCH
      cp.props.axios
        .post(URLs.SearchURL, { searchterm: target.value })
        .then(response => {
          cp.setState({ searching: false });
          if (target.value.length < 1) {
            cp.setState({ movies: [] });
            return;
          }
          cp.setState({ movies: response.data.data.movies });
        })
        .catch(error => {});
    }, 300);
  };
  hideSearch = () => {
    this.setState({ movies: [] });
  };
  toggleCart = () => {
    this.setState({ showCart: !this.state.showCart });
  };
  toggleProfile = () => {
    this.setState({ showProfile: !this.state.showProfile });
    this.setState({ anchorEl: null });
  };
  handleProfileDropdownOpen = e => {
    this.setState({ anchorEl: e.currentTarget });
  };
  handleProfileDropdownClose = () => {
    this.setState({ anchorEl: null });
  };
  logout = () => {
    localStorage.removeItem("token");
    window.location.assign("/login");
  };
  openNotifications = e => {
    this.setState({ notificationsMenu: e.target });
  };
  sortNotifications = notifications => {
    return notifications.sort(function(a, b) {
      const isNew = a.updated_at > a.movie.latest_called;
      return isNew;
    });
    
  };
  handleResize = () => {
      //this.setState({ documentWidth: window.innerWidth });
      this.setState({ documentWidth: $(document).innerWidth() });
      if($(document).innerWidth() >= 768){
        this.setState({ mediaqueryWidth: $(document).innerWidth() - 320 }); 
      }
      else{
        this.setState({ mediaqueryWidth: $(document).innerWidth() - 93 }); 
      }
      
    };
  loadNotifications = () => {
    this.setState({ notifications: [] });
    this.setState({ loadingNotifications: true });
    this.props.axios
      .post(URLs.FavoritesUpdatesURL)
      .then(response => {
        const updates = response.data.data.updates;
        this.setState({ notifications: updates.slice(0, 15) });
        this.setState({ loadingNotifications: false });

        var newCount = 0;
        for (var i = 0; i < this.state.notifications.length; i++) {
          const item = this.state.notifications[i];
          if (item.updated_at > item.movie.latest_called) newCount++;
        }
        this.setState({ notificationsCount: newCount });

        // UPDATE NAV NOTIFICATION BADGE
        $("#navNotifications span").html(newCount > 0 ? newCount : "");
      })
      .catch(error => {});
  };
  state = {
    searching: false,
    movies: [],
    showCart: false,
    showProfile: false,
    profileDropdown: false,
    anchorEl: null,
    loadingNotifications: false,
    notificationsMenu: null,
    notifications: [],
    notificationsCount: 0,
    cartCount: 0,
    businessName: "UCI Kinowelt",
    username: "Rolando Rodrigues",
    mediaqueryWidth: 0,
    documentWidth:0,
    showSearchBar: false
  };
  render() {
    
    let searchBarMobile;
    
    if($(document).innerWidth() >= 768){
      
      searchBarMobile = {
        marginTop: 85,
        marginLeft: 50, 
        marginRight: 50, 
        flexWrap: "nowrap", 
        width: this.state.mediaqueryWidth 
      };
    }
    else{
      searchBarMobile = {
        marginTop: 175,
        marginLeft: 50, 
        marginRight: 50, 
        flexWrap: "nowrap", 
        width: this.state.mediaqueryWidth 
       };
    }

    return (
    <StyleRoot>
      <React.Fragment>
      {this.state.showSearchBar && (
        <div>
        {this.state.movies.length > 0 && (
          <div
            id="search-bar"
            onClick={() => this.hideSearch()}
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              background: "rgba(255, 255, 255, 0.95)",
              transform: "opacity 1s",
              opacity: this.state.movies.length > 0 ? 1 : 0,
              zIndex: 2
            }}
          />
        )}
        <div id="search-result"
          style={{
            position: "fixed",
            width: "100%",
            height: this.state.movies.length > 0 ? 340 : 0,
            '@media (max-width: 767px)': {
              height: this.state.movies.length > 0 ? 420 : 0,
            },            
            background: Colors.blue,
            transition: "height 0.3s",
            overflow: "hidden",
            transitionTimingFunction: "ease-out",
            zIndex: 3
         }}
        >

          <GridList
            style={searchBarMobile}
          >
            {this.state.movies.map(function(movie, i) {
              return (
                <MoviePoster
                  key={i}
                  search
                  title={movie.title}
                  image={movie.thumb}
                  href={"/" + Strings.movie + "/" + movie.slug + "/" + Strings.overview}
                  size="small"
                />
              );
            })}
          </GridList>
        </div>

        <div style={styles.bar}>
          {!this.props.opaque && <div style={styles.bgColor} />}
          <MediaQuery minWidth={768}>
            <Grid style={styles.search} container spacing={8} alignItems="center">
              <Grid item style={{ position: "relative" }}>
                <Fade in={this.state.searching}>
                  <CircularProgress style={{ position: "absolute", color: "white" }} size={17} thickness={2.5} />
                </Fade>
                <Fade in={!this.state.searching}>
                  <img src={SearchIcon} alt="" style={styles.searchIcon} />
                </Fade>
              </Grid>
              
                <Grid item style={{ width: "40%" }}>
                  <Input
                    style={styles.input}
                    placeholder="Film suchen"
                    disableUnderline
                    onChange={e => {
                      this.search(e);
                    }}
                  />
                </Grid>
            </Grid>
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <Grid style={styles.searchmobile} container spacing={8} alignItems="center">
              <Grid item style={{ position: "relative" }}>
                <Fade in={this.state.searching}>
                  <CircularProgress style={{ position: "absolute", color: "white" }} size={17} thickness={2.5} />
                </Fade>
                <Fade in={!this.state.searching}>
                  <img src={SearchIcon} alt="" style={styles.searchIcon} />
                </Fade>
              </Grid>
              <Grid item style={{ width: "80%"}}>
                <Input
                  style={styles.inputmobile}
                  placeholder="Film suchen"
                  disableUnderline
                  onChange={e => {
                    this.search(e);
                  }}
                />
              </Grid>
            </Grid>

            {/* CART ICON ////////////////////////////////////////////////////////////////////////// */}
            <div id="cart-item" style={styles.carticon}>
              <Tooltip placement="bottom" title={Strings.cart}>
                <IconButton style={styles.icon} onClick={() => this.toggleCart()}>
                  <img src={DownloadsIcon} alt="" />
                </IconButton>
              </Tooltip>
              <Typography id="cartCount" style={{ color: "white", marginLeft: -4, marginRight: -4 }} variant="body1">
                {this.state.cartCount > 0 && this.state.cartCount}
              </Typography>
            </div>

          </MediaQuery>
          <div id="social-links" style={styles.icons}>
            <MediaQuery minWidth={1100}>
              <IconButton
                style={{ padding: 8, marginRight: -5, marginTop: -2 }}
                component="a"
                href="https://www.facebook.com/UniversalPicturesDE/"
                target="_blank"
              >
                <img src={FacebookIcon} alt="" />
              </IconButton>
              <IconButton
                style={styles.icon}
                component="a"
                href="https://www.youtube.com/user/UniversalPicturesDE"
                target="_blank"
              >
                <img src={YoutubeIcon} alt="" />
              </IconButton>
              <IconButton
                style={styles.icon}
                component="a"
                href="https://www.instagram.com/universalpicturesde"
                target="_blank"
              >
                <img src={InstagramIcon} alt="" />
              </IconButton>
            </MediaQuery>
            <MediaQuery minWidth={768}>
            <IconButton
              style={styles.icon}
              component="a"
              href="https://itunes.apple.com/de/app/universal-pictures-germany/id1458411188"
              target="_blank"
            >
              <img src={AppStoreIcon} alt="" />
            </IconButton>

            <IconButton
              style={styles.icon}
              component="a"
              href="https://play.google.com/store/apps/details?id=de.upig.universalapp&hl=de"
              target="_blank"
            >
              <img src={GooglePlayIcon} alt="" />
            </IconButton>
            </MediaQuery>

            {/* NOTIFICATIONS ICON ///////////////////////////////////////////////////////////////// */}
            <MediaQuery minWidth={768}>
            <div style={{ display: "flex", alignItems: "center", marginLeft: 10 }}>
              <Tooltip
                placement="bottom"
                title={this.state.notificationsCount > 0 ? Strings.notifications : Strings.noNotifications}
              >
                <IconButton onClick={e => this.openNotifications(e)} style={{ padding: 8 }}>
                  <img src={NotificationsIcon} alt="" />
                </IconButton>
              </Tooltip>
              <Typography style={{ color: "white", marginLeft: -4 }} variant="body1">
                {this.state.notificationsCount > 0 && this.state.notificationsCount}
              </Typography>
            </div>
            <Menu
              PaperProps={{
                style: {
                  width: 350,
                  right: 50,
                  top: 75
                }
              }}
              anchorReference="none"
              anchorEl={this.state.notificationsMenu}
              open={Boolean(this.state.notificationsMenu)}
              onClose={() => {
                this.setState({ notificationsMenu: false });
              }}
            >
              <div id="message-region"
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  marginTop: 10,
                  marginBottom: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography variant="h2" color="primary" style={{ flex: 1 }}>
                  Benachrichtigungen
                </Typography>
                {this.state.loadingNotifications && <CircularProgress size={20} color="secondary" />}
              </div>

              <div style={{ height: 1, background: Colors.lightLightGrey, marginBottom: 10 }} />

              {this.state.notifications.map(function(item, i) {
                const isNew = item.updated_at > item.movie.latest_called;
                return (
                  <MenuItem
                    key={i}
                    style={{
                      height: 100,
                      backgroundColor: isNew ? Colors.blue : "auto"
                    }}
                    onClick={() => {
                      window.location.assign("/movie/" + item.movie_slug + "/" + item.category_slug);
                    }}
                  >
                    <div style={{ width: "100%", display: "flex" }}>
                      <ImageLoader
                        showDefault
                        poster
                        containerStyle={{
                          minWidth: 50,
                          maxWidth: 50,
                          minHeight: 70,
                          maxHeight: 70,
                          marginRight: 10,
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderColor: Colors.lightLightGrey,
                          boxSizing: "border-box"
                        }}
                        src={URLs.MediaURL + item.movie.thumb}
                      />
                      <div style={{ width: "calc(100% - 60px)", display: "flex", alignItems: "center" }}>
                        <div style={{ width: "100%" }}>
                          <Typography
                            style={{
                              textTransform: "none",
                              color: isNew ? "white" : "auto",
                              opacity: isNew ? 0.5 : 1
                            }}
                            color="secondary"
                            variant="overline"
                          >
                            <Moment calendar={calendarStrings} unix>
                              {item.updated_at}
                            </Moment>
                          </Typography>
                          <Typography
                            variant="body1"
                            color="primary"
                            noWrap
                            style={{ color: isNew ? "white" : "auto" }}
                          >
                            {item.movie_title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="primary"
                            noWrap
                            style={{ color: isNew ? "white" : "auto" }}
                          >
                            {item.category_name}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </MenuItem>
                );
              })}
            </Menu>
            {/* CART ICON ////////////////////////////////////////////////////////////////////////// */}
            <div id="cart-item" style={{ display: "flex", alignItems: "center", marginRight: 10 }}>
              <Tooltip placement="bottom" title={Strings.cart}>
                <IconButton style={styles.icon} onClick={() => this.toggleCart()}>
                  <img src={DownloadsIcon} alt="" />
                </IconButton>
              </Tooltip>
              <Typography id="cartCount" style={{ color: "white", marginLeft: -4, marginRight: -4 }} variant="body1">
                {this.state.cartCount > 0 && this.state.cartCount}
              </Typography>
            </div>
            {/* PROFILE //////////////////////////////////////////////////////////////////////////// */}
            <Button
              id="menuButton"
              aria-owns={this.state.anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={this.handleProfileDropdownOpen}
              style={styles.profileButton}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <MediaQuery minWidth={781}>
                  <Typography style={styles.name} variant="body2">
                    {this.state.businessName}
                  </Typography>
                  <img src={DropdownIconWhite} alt="" style={{ padding: 4, width: 20, height: 13 }} />
                </MediaQuery>
                <Avatar style={styles.avatar}>{shortName(this.state.username)}</Avatar>
              </div>
            </Button>

            <Menu
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleProfileDropdownClose}
            >
              <MenuItem onClick={() => this.toggleProfile()} style={{ width: $("#menuButton").width() - 30 }}>
                <Typography color="primary" variant="body2">
                  Profil
                </Typography>
              </MenuItem>
              <MenuItem onClick={this.logout}>
                <Typography color="primary" variant="body2">
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
            </MediaQuery>
            

          </div>
        </div>
        {/* WARENKORB CONTENT ////////////////////////////////////////////////////////////////////////////*/}
          <Cart open={this.state.showCart} toggleCart={() => this.toggleCart()} />
        {/* PROFILE CONTENT /////////////////////////////////////////////////////////////////////////////*/}
          <Profile open={this.state.showProfile} toggleProfile={() => this.toggleProfile()} />
      </div>)}
      </React.Fragment>
      </StyleRoot>
    );
  }
}

function shortName(name) {
  var short = "";
  var parts = name.split(" ");

  for (var i = 0; i < parts.length; i++) {
    if (i > 1) break;
    short += parts[i].charAt(0);
  }

  return short;
}

export default withAxios(Radium(SearchBar));
