import React, { Component } from "react";
import { Checkbox } from "@material-ui/core";

import CheckedIcon from "../../img/CheckBoxChecked.svg";
import UncheckedIcon from "../../img/CheckBoxUnchecked.svg";

class CustomCheckBox extends Component {
  state = {};
  render() {
    return (
      <Checkbox
        onChange={this.props.onChange}
        defaultChecked={this.props.defaultChecked}
        style={{ marginLeft: 3, marginTop: "-1%", alignSelf: "flex-start" }}
        checkedIcon={<img src={CheckedIcon} alt="" />}
        icon={<img src={UncheckedIcon} alt="" />}
      />
    );
  }
}

export default CustomCheckBox;
