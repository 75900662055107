import React, { Component } from "react";
import $ from "jquery";

class ScrollBottomListener extends Component {
  componentDidMount() {
    //window.addEventListener("scroll", this.didScroll);

    $(window).scroll(this.didScroll);
  }
  componentWillUnmount() {
    //window.removeEventListener("scroll", this.didScroll);
  }
  didScroll = () => {
    //const scroll = window.innerHeight + window.scrollY;
    const scroll = $(window).innerHeight() + $(window).scrollTop();
    //const height = document.body.offsetHeight;
    const height = $(document).height() - 50;

    if (scroll >= height && height > this.state.currentHeight) {
      // REACHED BOTTOM
      this.setState({ currentHeight: height });
      this.props.onBottom();
    }
  };
  state = {
    currentHeight: 0
  };
  render() {
    return <React.Fragment />;
  }
}

export default ScrollBottomListener;
