const Strings = {
  pageNotFound: "Seite nicht gefunden",
  submitError: "Ein Fehler ist aufgetretten. Überprüfen Sie Ihre Angaben.",
  login: "login",
  movie: "movie",
  overview: "uebersicht",
  startlist: "startliste",
  updates: "updates",
  favorites: "favoriten",
  save: "Speichern",
  cancel: "Abbrechen",
  toCart: "Zum Warenkorb",
  allToCart: "Alle Assets zum Warenkorb hinzufügen",
  addedToCart: "Zum Warenkorb hinzugefügt",
  download: "Direkt Download",
  allDownload: "Alle Assets direkt laden",
  showAssets: "Alle Assets anschauen",
  addFavorite: "Favorit hinzufügen",
  addedFavorite: "Favorit hinzugefügt",
  removeFavorite: "Favorit entfernen",
  removedFavorite: "Favorit entfernt",
  backText: "Zurück",
  addNotification: "Benachrichtigungen aktivieren",
  removeNotification: "Benachrichtigungen deaktivieren",
  addedNotifications: "Benachrichtigung aktiviert",
  removedNotifications: "Benachrichtigungen deaktiviert",
  startingDownload: "Download wurde gestartet",
  copyToClipboard: "In die Zwischenablage kopieren",
  copiedToClipboard: "Kurzinhalt in die Zwischenablage kopiert",
  cart: "Warenkorb",
  notifications: "Es gibt neue Benachrichtigungen",
  noNotifications: "Keine neuen Benachrichtigungen zu deinen Favoriten",
  viewPDF: "PDF anschauen",
  noFavorites: "Sie haben noch keine Favoriten. Besuchen Sie unsere Startliste, um Filme zu favorisieren",
  mobileWarning: "Unsere App ist bald verfügbar",
  assets: "Assets anschauen",
  registerSuccess: "Zugang erfolgreich beantragt. Sie erhalten eine E-Mail, sobald Ihr Zugang freigeschaltet wurde.",
  saveSuccess: "Erfolgreich gespeichert",
  loginError: "Login nicht erfolgreich. Überprüfen Sie Ihre Angaben."
};

export default Strings;
