import React, { Component } from "react";
import CloseButton from "../Misc/CloseButton";
import URLs from "../../extras/URLs";
import TextFieldMultiSelect from "../Misc/TextFieldMultiSelect";

import {
  Grid,
  TextField,
  FormControlLabel,
  FormGroup,
  Button,
  Typography,
  Drawer,
  Collapse,
  Dialog, DialogContent
} from "@material-ui/core";
import SectionHeadline from "../Misc/SectionHeadline";

import DropDown from "../Misc/DropDown";
import CustomCheckbox from "../Misc/CustomCheckBox";
import { BUSINESS_NAME, USER_NAME } from "../../extras/Keys";

import SimpleReactValidator from "simple-react-validator";
import { withSnackbar } from "notistack";

import Log from "../Misc/Log";

import countries from "i18n-iso-countries";
import LoadingButton from "../Misc/LoadingButton";
import Strings from "../../extras/Strings";
import { withAxios } from "../Misc/AxiosManager";
import Radium, { StyleRoot } from 'radium';


countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

const genders = ["keine Angabe", "männlich", "weiblich"];
const defaultCountry = "Deutschland";

const styles = {
  content: {
    width: 760,
    overflowY: "scroll",
    padding: "30px 50px 50px 50px",
    '@media (max-width: 767px)': {
      width: "100%",
      padding: "30px 30px 30px 30px",
    }
  },
  close: {
    position: "fixed",
    top: 20,
    right: 50,
    zIndex: 99999999
  },
  subline: {
    marginTop: 10
  },
  sectionHeadline: {
    marginTop: 30
  },
  container: {
    marginRight: 70
  },
  errorText: {
    color: "#f44336",
    fontSize: "0.75rem",
    textAlign: "left",
    marginTop: 8,
    minHeight: "1em",
    fontFamily: "Helvetica Neue",
    lineHeight: "1em"
  },
  savebutton:{
    marginBottom:50,
    '@media(max-width: 371px)': {
      marginBottom:0,
      fontSize:13
    }
  },
  cancelbutton:{
    marginLeft: 10, 
    marginBottom: 50,
    '@media(max-width: 371px)': {
      marginLeft: 0, 
    marginBottom: 0,
    fontSize:13
    }
  }
};

class Profile extends Component {
  componentWillMount() {
    this.validator = new SimpleReactValidator({
      validators: {
        cinemas: {
          rule: (val, params, validator) => {
            return val;
          },
          required: true
        },
        password: {
          rule: (val, params, validator) => {
            return val;
          },
          required: true
        },
        custom_email: {
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
          }
        }
      },
      messages: {
        email: "Email ist nicht gültig.",
        custom_email: "Email ist nicht gültig.",
        cinemas: "Kinokette(n) und/oder Kino(s) müssen hinzugefügt werden.",
        password: "Kennwörter stimmen nicht überein.",
        required: "Dieses Feld muss ausgefühlt werden.",
        accepted: "Sie müssen den Bedingungen zustimmen.",
        default: "Eingabe nicht gültig."
      }
    });
    this.validatorTerms = new SimpleReactValidator({
      messages: {
        accepted: "Bitte bestätigen Sie, dass Sie die Information gelesen und verstanden haben.",
      }
    });
  }
  componentDidMount() {
    this.loadData();

    var countriesList = [];
    var tempList = countries.getNames("de");
    for (var key in tempList) {
      countriesList.push(tempList[key]);
    }

    this.setState({ countriesList: countriesList });
  }
  loadData = () => {
    const cp = this;
    this.props.axios
      .get(this.props.register ? URLs.RegisterFieldsURL : URLs.ProfileURL)
      .then(response => {
        const data = response.data.data;

        // LOAD DATA
        cp.setState({ data: data });
        cp.setState({ email: data.email });
        cp.setState({ lastName: data.name });
        cp.setState({ firstName: data.vorname });
        cp.setState({
          gender: this.props.register
            ? genders[0]
            : data.geschlecht === "-"
            ? genders[0]
            : data.geschlecht === "w"
            ? genders[2]
            : genders[1]
        });

        cp.setState({ business: data.kino_firma });
        cp.setState({ types: data.art.options });
        cp.setState({ type: data.art.selected.name ? data.art.selected.name : "" });
        cp.setState({ cinemaChains: data.kino_ketten.options });
        cp.setState({
          selectedCinemaChains: Array.isArray(data.kino_ketten.selected) ? cleanList(data.kino_ketten.selected) : []
        });
        cp.setState({
          selectedCinemas: data.kinos && Array.isArray(data.kinos) && !this.props.register ? data.kinos : []
        });
        cp.setState({ position: data.position });
        cp.setState({ addressBusiness: data.adresse });
        cp.setState({ addressBusinessExtra: data.adresse_add });
        cp.setState({ postcodeBusiness: data.plz });
        cp.setState({ locationBusiness: data.ort });
        cp.setState({ stateBusiness: data.bundesland });
        cp.setState({ countryBusiness: this.props.register ? defaultCountry : data.land });
        cp.setState({ phoneBusiness: data.telefon });
        cp.setState({ mobileBusiness: data.mobil });
        cp.setState({ faxBusiness: data.fax });
        cp.setState({ websiteBusiness: data.internet });

        cp.setState({ addressPrivat: data.privat_adresse });
        cp.setState({ addressPrivatExtra: data.privatadd });
        cp.setState({ postcodePrivat: data.privat_plz });
        cp.setState({ locationPrivat: data.privat_ort });
        cp.setState({ statePrivat: data.privat_bundesland });
        cp.setState({ countryPrivat: this.props.register ? defaultCountry : data.privat_land });
        cp.setState({ phonePrivat: data.privat_telefon });
        cp.setState({ mobilePrivat: data.privat_mobil });
        cp.setState({ faxPrivat: data.privat_fax });
        cp.setState({ websitePrivat: data.privat_internet });

        cp.setState({ pressEmail: data.email_abo === "1" ? true : false });
        cp.setState({ materialEmail: data.email_abo1 === "1" ? true : false });
        cp.setState({ comments: data.bemerkungen });
        cp.setState({ saveData: data.checkbox1 === "1" ? true : false });
        cp.setState({ useData: data.checkbox2 === "1" ? true : false });
        cp.setState({ termsAccepted: data.terms_accepted });
        cp.setState({ userActive: data.active });

        if (!cp.props.register) {
          // Save Infos
          saveInfos(data);
        }
      })
      .catch(error => {});
  };
  handleChange = name => e => {
    this.setState({ [name]: e.target.value });
  };
  handleCheckBox = name => e => {
    this.setState({ [name]: e.target.checked });
  };
  hideSnack = name => {
    this.setState({ [name]: false });
  };
  addItem = item => list => {
    list.push(item);
  };
  removeItem = pos => list => {
    list.splice(pos, 1);
  };
  updateData = cp => {
    // SHOW LOADING
    cp.setState({ saving: true });

    // SAVE DATA
    var data = {
      email: cp.state.email,
      vorname: cp.state.firstName,
      name: cp.state.lastName,
      geschlecht: cp.state.gender === genders[0] ? "-" : cp.state.gender === genders[1] ? "m" : "w",
      kino_firma: cp.state.business,
      art: findObjectByName(cp.state.type, cp.state.types).id,
      kino_ketten: idList(cp.state.selectedCinemaChains, cp.state.cinemaChains),
      kinos: cp.state.selectedCinemas,
      position: cp.state.position,
      adresse: cp.state.addressBusiness,
      adresse_add: cp.state.addressBusinessExtra,
      plz: cp.state.postcodeBusiness,
      ort: cp.state.locationBusiness,
      bundesland: cp.state.stateBusiness,
      land: cp.state.countryBusiness,
      telefon: cp.state.phoneBusiness,
      mobil: cp.state.mobileBusiness,
      fax: cp.state.faxBusiness,
      internet: cp.state.websiteBusiness,
      privat_adresse: cp.state.addressPrivat,
      privatadd: cp.state.addressPrivatExtra,
      privat_plz: cp.state.postcodePrivat,
      privat_ort: cp.state.locationPrivat,
      privat_bundesland: cp.state.statePrivat,
      privat_land: cp.state.countryPrivat,
      privat_telefon: cp.state.phonePrivat,
      privat_mobil: cp.state.mobilePrivat,
      privat_fax: cp.state.faxPrivat,
      privat_internet: cp.state.websitePrivat,
      email_abo: cp.state.pressEmail ? "1" : "0",
      email_abo1: cp.state.materialEmail ? "1" : "0",
      bemerkungen: cp.state.comments,
      checkbox1: cp.state.saveData ? "1" : "0",
      checkbox2: cp.state.useData ? "1" : "0"
    };

    if (this.state.password !== "") {
      data.password = this.state.password;
      data.password_confirmation = this.state.passwordRepeat;
    }

    Log(data);

    // VALIDATE
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      cp.setState({ saving: false });
      cp.props.enqueueSnackbar(Strings.submitError, {
        variant: "error"
      });
      this.forceUpdate();
      return;
    }

    this.props.axios
      .post(this.props.register ? URLs.RegisterURL : URLs.UpdateProfileURL, data)
      .then(response => {
        if (!cp.props.register) saveInfos(response.data.data);
        cp.setState({ saving: false });
        cp.props.enqueueSnackbar(cp.props.register ? Strings.registerSuccess : Strings.saveSuccess, {
          autoHideDuration: 10000
        });
        cp.props.toggleProfile();
      })
      .catch(error => {
        cp.setState({ saving: false });
        if (cp.props.register) {
          cp.props.enqueueSnackbar(
            "Ihre Anfrage war nicht erfolgreich. Falls Sie sich bereits mit dieser Email registriert haben, haben Sie noch etwas Geduld. Wir überprüfen Ihre Daten so schnell wie möglich.",
            {
              variant: "error",
              autoHideDuration: 10000
            }
          );
        }
      });
  };

  acceptTerms = cp => {
    // SHOW LOADING
    cp.setState({ saving: true });

    // SAVE DATA
    var data = {
      termsAccepted: cp.state.termsAcceptedChecked,
    };

    // VALIDATE
    if (!this.validatorTerms.allValid()) {
      const errormess = this.validatorTerms.getErrorMessages();
      this.validatorTerms.showMessages();
      cp.setState({ saving: false });
      cp.props.enqueueSnackbar(Strings.submitError, {
        variant: "error"
      });
      this.forceUpdate();
      return;
    }

    this.props.axios
        .post(URLs.AcceptTermsURL, data)
        .then(response => {
          cp.setState({ saving: false });
          cp.setState({ termsAccepted: 1 });
        })
        .catch(error => {
          cp.setState({ saving: false });
          cp.props.enqueueSnackbar(
              "Ihre Anfrage war nicht erfolgreich. Bitte versuchen Sie es erneut.",
              {
                variant: "error",
                autoHideDuration: 10000
              }
          );
        });
  };

  logout = () => {
    localStorage.removeItem("token");
    window.location.assign("/login");
  };

  state = {
    anchorEl: null,
    saving: false,
    data: null,
    email: "",
    password: "",
    passwordRepeat: "",
    firstName: "",
    lastName: "",
    gender: "",
    business: "",
    position: "",
    types: [],
    type: "",
    cinemaChains: [],
    selectedCinemaChains: [],
    selectedCinemas: [],
    addressBusiness: "",
    addressBusinessExtra: "",
    postcodeBusiness: "",
    locationBusiness: "",
    stateBusiness: "",
    countryBusiness: "",
    countriesList: [],
    phoneBusiness: "",
    mobileBusiness: "",
    faxBusiness: "",
    websitePrivat: "",
    addressPrivat: "",
    addressPrivatExtra: "",
    postcodePrivat: "",
    locationPrivat: "",
    statePrivat: "",
    countryPrivat: "",
    phonePrivat: "",
    mobilePrivat: "",
    faxPrivat: "",
    pressEmail: false,
    materialEmail: false,
    comments: "",
    saveData: false,
    useData: false,
    termsAccepted: false,
    termsAcceptedChecked: false,
    userActive: "0",
  };
  render() {
    const cp = this;
    const emailError = this.validator.message("Email", this.state.email, "required|custom_email");
    const passwordError = this.validator.message(
      "Passwort",
      this.state.password === this.state.passwordRepeat ||
        (this.state.password == "" && this.state.passwordRepeat == ""),
      "password|required"
    );
    const firstNameError = this.validator.message("Vorname", this.state.firstName, "required");
    const lastNameError = this.validator.message("Nachname", this.state.lastName, "required");
    const genderError = this.validator.message("Geschlecht", this.state.gender, "required");
    const businessError = this.validator.message("Kino/Firma", this.state.business, "required");
    const positionError = this.validator.message("Position", this.state.position, "required");
    const typeError = this.validator.message("Art", this.state.type, "required");
    const cinemasError = this.validator.message(
      "Kinos",
      this.state.selectedCinemas.length > 0 ||
        this.state.selectedCinemaChains.length > 0 ||
        this.state.type !== "Kinobetreiber",
      "cinemas"
    );
    const addressBusinessError = this.validator.message("Adresse", this.state.addressBusiness, "required");
    const postcodeBusinessError = this.validator.message("PLZ", this.state.postcodeBusiness, "numeric");
    const locationBusinessError = this.validator.message("Ort", this.state.locationBusiness, "required");
    const phoneBusinessError = this.validator.message("Telefon", this.state.phoneBusiness, "phone");
    const saveDataError = this.validator.message("Daten speichern", this.state.saveData, "accepted");
    const useDataError = this.validator.message("Benutzung der Daten", this.state.useData, "accepted");
    const termsAcceptedError = this.validatorTerms.message("Bedingungen zustimmen", this.state.termsAcceptedChecked, "accepted");
    return (
      <React.Fragment>
        <StyleRoot>
        <div style={{zIndex: 99999999}}>
              <Dialog open={this.state.email !== '' && (this.state.userActive == '1' || this.state.userActive == 1) && !this.state.termsAccepted} scroll="body" maxWidth="sm">
                <DialogContent>
                  <React.Fragment>
                    <Typography color="primary" variant="h1" style={{ marginBottom: 20 }}>
                      Wichtige Information
                    </Typography>
                    <div>
                      <Typography color="primary" variant="body2" style={{ marginBottom: 10 }}>
                        <p>
                          Liebe Kinobetreiber, Partner und Kollegen,
                          <br/><br/>
                        </p>
                        <p>
                          bitte beachten sie, dass jegliches Material von ARGYLLE aufgrund rechtlicher Einschränkungen ab sofort nicht mehr in <b>Österreich</b> verwendet werden darf!
                          <br/>Wir bitten Sie daher, alle bisherigen Veröffentlichungen des Trailers + Plakats oder Bildinhalten von ARGYLLE umgehend von Ihren Plattformen zu entfernen und nicht mehr zu verwenden (dies betrifft alle Kanäle inkl. Website / Social Media sowie auch den Trailereinsatz auf der großen Leinwand und Digi Screens).
                          <br/><br/>
                        </p>
                        <p>
                          Für Deutschland gilt diese Regelung nicht.
                        </p>
                      </Typography>

                      <FormGroup style={{ marginTop: 30 }}>
                        <FormControlLabel
                            label="Ich habe diese Information gelesen und verstanden."
                            control={
                              <CustomCheckbox style={{ fontSize: 30 }} onChange={this.handleCheckBox("termsAcceptedChecked")} defaultChecked={false} />
                            }
                        />
                        <div style={styles.errorText}>{termsAcceptedError}</div>
                      </FormGroup>
                    </div>
                    <LoadingButton onClick={() => this.acceptTerms(this)}>
                      Bestätigen
                    </LoadingButton>
                  </React.Fragment>
                  {/* CLOSE BUTTON /////////////////////////////////////// */}
                  <CloseButton onClick={this.logout} />
                </DialogContent>
              </Dialog>
          )}
        <Drawer open={this.props.open} anchor="right" onClose={this.props.toggleProfile}>
          <div style={styles.content}>
            <form style={styles.container}>
              <Typography color="primary" variant="h3">
                {this.props.register ? "Registrierung" : "Mein Konto"}
              </Typography>
              <Typography color="primary" variant="body1" style={styles.subline}>
                Bitte überprüfen Sie Ihre Angaben auf Richtigkeit und editieren Sie diese gegebenenfalls. Alle mit
                &quot;*&quot; gekennzeichneten Felder müssen ausgefüllt werden.
              </Typography>

              {/* PERSONAL INFOS ////////////////////////////////////////// */}
              <SectionHeadline style={styles.sectionHeadline}>Persönliche Angaben</SectionHeadline>

              {/* INPUTS */}
              <Grid container spacing={24}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("firstName")}
                    defaultValue={this.state.firstName}
                    required
                    label="Vorname"
                    fullWidth
                    error={firstNameError}
                    helperText={firstNameError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("lastName")}
                    defaultValue={this.state.lastName}
                    required
                    label="Nachname"
                    fullWidth
                    error={lastNameError}
                    helperText={lastNameError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("email")}
                    defaultValue={this.state.email}
                    required
                    label="E-Mail"
                    type="email"
                    fullWidth
                    error={emailError}
                    helperText={emailError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DropDown
                    defaultValue={this.state.gender}
                    label="Geschlecht"
                    list={genders}
                    onChange={this.handleChange("gender")}
                    error={genderError}
                    helperText={genderError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("password")}
                    defaultValue={this.state.password}
                    required
                    label="Passwort"
                    type="password"
                    fullWidth
                    error={passwordError}
                    helperText={passwordError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("passwordRepeat")}
                    defaultValue={this.state.passwordRepeat}
                    required
                    label="Passwort wiederholen"
                    type="password"
                    fullWidth
                    error={passwordError}
                    helperText={passwordError}
                  />
                </Grid>
                {passwordError && (
                  <div style={{ ...styles.errorText, marginLeft: 12 }}>
                    Lassen Sie diese Felder leer, wenn Sie Ihr Passwort nicht ändern wollen.
                  </div>
                )}
              </Grid>

              {/* CINEMA INFOS ////////////////////////////////////////// */}
              <SectionHeadline style={styles.sectionHeadline}>Angaben zum Kino / zur Firma</SectionHeadline>

              <Grid container spacing={24}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("business")}
                    defaultValue={this.state.business}
                    label="Kino/Firma"
                    fullWidth
                    required
                    error={businessError}
                    helperText={businessError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DropDown
                    defaultValue={this.state.type}
                    label="Art"
                    required
                    list={cleanList(this.state.types)}
                    onChange={this.handleChange("type")}
                    error={typeError}
                    helperText={typeError}
                  />
                </Grid>
                {/* */}
                {this.state.type === "Kinobetreiber" && (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Typography color="primary" variant="body1">
                        Sie sind tätig für folgende Kinokette(n) und/oder Kino(s)*
                      </Typography>
                      <div style={styles.errorText}>{cinemasError}</div>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ marginTop: -10 }}>
                      <TextFieldMultiSelect
                        parent={this}
                        label="Kinokette hinzufügen"
                        list={cleanList(this.state.cinemaChains)}
                        selectedList={this.state.selectedCinemaChains}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ marginTop: -10 }}>
                      <TextFieldMultiSelect
                        parent={this}
                        label="Kino hinzufügen"
                        selectedList={this.state.selectedCinemas}
                      />
                    </Grid>
                  </React.Fragment>
                )}

                {/** */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("position")}
                    defaultValue={this.state.position}
                    label="Position"
                    fullWidth
                    required
                    error={positionError}
                    helperText={positionError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("addressBusiness")}
                    defaultValue={this.state.addressBusiness}
                    label="Adresse"
                    fullWidth
                    required
                    error={addressBusinessError}
                    helperText={addressBusinessError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("addressBusinessExtra")}
                    defaultValue={this.state.addressBusinessExtra}
                    label="Adresszusatz/Postfach"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("postcodeBusiness")}
                    defaultValue={this.state.postcodeBusiness}
                    label="PLZ"
                    fullWidth
                    required
                    error={postcodeBusinessError}
                    helperText={postcodeBusinessError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("locationBusiness")}
                    defaultValue={this.state.locationBusiness}
                    label="Ort"
                    fullWidth
                    required
                    error={locationBusinessError}
                    helperText={locationBusinessError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("stateBusiness")}
                    defaultValue={this.state.stateBusiness}
                    label="Bundesland"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DropDown
                    label="Land"
                    list={this.state.countriesList}
                    onChange={this.handleChange("countryBusiness")}
                    defaultValue={this.state.countryBusiness}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("phoneBusiness")}
                    defaultValue={this.state.phoneBusiness}
                    label="Telefon"
                    fullWidth
                    required
                    error={phoneBusinessError}
                    helperText={phoneBusinessError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("mobileBusiness")}
                    defaultValue={this.state.mobileBusiness}
                    label="Mobil"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("faxBusiness")}
                    defaultValue={this.state.faxBusiness}
                    label="Fax"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={this.handleChange("websiteBusiness")}
                    defaultValue={this.state.websiteBusiness}
                    label="Internet"
                    fullWidth
                  />
                </Grid>
              </Grid>

              {/* PERSONAL ADDRESS ////////////////////////////////////////// */}
              <SectionHeadline style={styles.sectionHeadline}>Persönliche Anschrift</SectionHeadline>

              <Grid container spacing={24}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    defaultValue={this.state.addressPrivat}
                    onChange={this.handleChange("addressPrivat")}
                    label="Privatadresse"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    defaultValue={this.state.addressPrivatExtra}
                    onChange={this.handleChange("addressPrivatExtra")}
                    label="Privatadresse (Zusatz)"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    defaultValue={this.state.postcodePrivat}
                    onChange={this.handleChange("postcodePrivat")}
                    label="PLZ"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    defaultValue={this.state.locationPrivat}
                    onChange={this.handleChange("locationPrivat")}
                    label="Ort"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    defaultValue={this.state.statePrivat}
                    onChange={this.handleChange("statePrivat")}
                    label="Bundesland"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DropDown
                    label="Land"
                    list={this.state.countriesList}
                    onChange={this.handleChange("countryPrivat")}
                    defaultValue={this.state.countryPrivat}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    defaultValue={this.state.phonePrivat}
                    onChange={this.handleChange("phonePrivat")}
                    label="Telefon"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    defaultValue={this.state.mobilePrivat}
                    onChange={this.handleChange("mobilePrivat")}
                    label="Mobil"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    defaultValue={this.state.faxPrivat}
                    onChange={this.handleChange("faxPrivat")}
                    label="Fax"
                    fullWidth
                  />
                </Grid>
              </Grid>

              {/* EMAIL ////////////////////////////////////////// */}
              <SectionHeadline style={styles.sectionHeadline}>E-Mail</SectionHeadline>

              <FormGroup row>
                <FormControlLabel
                  label="Pressemitteilungen"
                  control={
                    <CustomCheckbox
                      onChange={this.handleCheckBox("pressEmail")}
                      defaultChecked={this.state.pressEmail}
                    />
                  }
                />
                <FormControlLabel
                  style={{ marginLeft: 10 }}
                  label="Material-Updates (wl.)"
                  control={
                    <CustomCheckbox
                      onChange={this.handleCheckBox("materialEmail")}
                      defaultChecked={this.state.materialEmail}
                    />
                  }
                />
              </FormGroup>

              {/* ANNOTATIONS ////////////////////////////////////////// */}
              <SectionHeadline style={styles.sectionHeadline}>Bemerkungen</SectionHeadline>

              <TextField
                onChange={this.handleChange("comments")}
                defaultValue={this.state.comments}
                multiline
                fullWidth
                variant="outlined"
                rows={5}
              />

              <FormGroup style={{ marginTop: 30 }}>
                <FormControlLabel
                  label="Hiermit willige ich jederzeit widerruflich ein, dass die Universal Pictures International Germany GmbH meine bei der Registrierung erhobenen personenbezogenen Daten (E-Mail, Telefonnummer, Postanschrift), sowie erfasste Daten zu bisherigen Abrufen aus dem Marketingserver speichert."
                  control={
                    <CustomCheckbox onChange={this.handleCheckBox("saveData")} defaultChecked={this.state.saveData} />
                  }
                />
                <div style={styles.errorText}>{saveDataError}</div>
                <FormControlLabel
                  style={{ marginTop: 20 }}
                  label="Ich willige ferner jederzeit widerruflich ein, dass mich die Universal Pictures International Germany GmbH unter Verwendung der vorgenannten Daten zu Neuerscheinungen von Filmen kontaktieren darf."
                  control={
                    <CustomCheckbox onChange={this.handleCheckBox("useData")} defaultChecked={this.state.useData} />
                  }
                />
                <div style={styles.errorText}>{useDataError}</div>
              </FormGroup>

              {/* BUTTONS /////////////////////////////////////////// */}
              <LoadingButton
                loading={this.state.saving}
                onClick={() => this.updateData(this)}
                variant="outlined"
                style={ styles.savebutton }
              >
                {this.props.register ? "Zugang beantragen" : "Speichern & schließen"}
              </LoadingButton>
              <Button
                onClick={this.props.toggleProfile}
                style={ styles.cancelbutton }
                variant="outlined"
              >
                {Strings.cancel}
              </Button>
            </form>
          </div>
        </Drawer>

        {/* CLOSE BUTTON */}
        {this.props.open && <CloseButton onClick={this.props.toggleProfile} />}
        </div>
        </StyleRoot>
      </React.Fragment>
    );
  }
}

function findObjectByName(name, list) {
  for (var i = 0; i < list.length; i++) {
    var item = list[i];
    if (item.name === name) return item;
  }
  return {};
}

function findObjectByID(id, list) {
  for (var i = 0; i < list.length; i++) {
    var item = list[i];
    if (item.id === id) return item;
  }
  return {};
}

function cleanList(list) {
  var newList = [];
  for (var key in list) {
    newList.push(list[key].name);
  }
  return newList;
}

function idList(list, fullList) {
  var newList = [];
  for (var i = 0; i < list.length; i++) {
    newList.push(findObjectByName(list[i], fullList).id);
  }
  return newList;
}

function saveInfos(result) {
  localStorage.setItem(USER_NAME, result.vorname + " " + result.name);
  localStorage.setItem(BUSINESS_NAME, result.kino_firma);
}

export default withAxios(withSnackbar(Radium(Profile)));
