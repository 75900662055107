import React, { Component } from "react";
import Colors from "../../extras/Colors";
import { Typography, ButtonBase } from "@material-ui/core";
import ImageLoader from "../Misc/ImageLoader";
import { ArrowRight, ArrowLeft, SmallPlayIcon } from "../Misc/Images";
import { findDOMNode } from "react-dom";
import { Link } from "react-router-dom";
import URLs from "../../extras/URLs";
import $ from "jquery";
import { TweenMax } from "gsap";

const styles = {
  uploads: {
    display: "flex",
    overflowX: "scroll",
    overflowY: "hidden",
    scrollbarWidth: "none"
  },
  uploadThumb: {
    position: "relative",
    width: 270,
    height: 150,
    marginRight: 10,
    overflow: "hidden",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.lightGrey,
    background: "white"
  },
  videoOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    background: "rgba(0, 0, 0, 0.5)"
  },
  scrollButton: {
    height: 150,
    minWidth: 50,
    maxWidth: 50
  },
  centered: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)"
  },
  ext: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
};

class UpdatesUploads extends Component {
  componentDidMount() {
    // REFERENCES
    this.uploadsList = findDOMNode(this.refs.uploadsList);
    this.leftArrow = findDOMNode(this.refs.leftArrow);
    this.rightArrow = findDOMNode(this.refs.rightArrow);

    window.addEventListener("resize", this.handleResize);
    this.uploadsList.addEventListener("scroll", this.handleScroll);
    this.handleResize();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this.uploadsList.removeEventListener("scroll", this.handleScroll);
  }
  handleResize = () => {
    var contentWidth = $(this.uploadsList).get(0).scrollWidth;
    var width = $(this.uploadsList).innerWidth();

    if (contentWidth > width + 5) {
      $(this.leftArrow).show();
      $(this.rightArrow).show();
    } else {
      $(this.leftArrow).hide();
      $(this.rightArrow).hide();
    }
    $("#updates-content").css("width", ($(document).innerWidth() - 220 ) + 'px'); 
    
    this.handleScroll();
  };
  handleScroll = () => {
    var contentWidth = $(this.uploadsList).get(0).scrollWidth;
    var width = $(this.uploadsList).innerWidth();
    var scroll = $(this.uploadsList).scrollLeft();

    if (scroll >= contentWidth - width) {
      $(this.rightArrow).css("opacity", 0);
      $(this.rightArrow).css("pointer-events", "none");
    } else {
      $(this.rightArrow).css("opacity", 1);
      $(this.rightArrow).css("pointer-events", "auto");
    }

    if (scroll > 0) {
      $(this.leftArrow).css("opacity", 1);
      $(this.leftArrow).css("pointer-events", "auto");
    } else {
      $(this.leftArrow).css("opacity", 0);
      $(this.leftArrow).css("pointer-events", "none");
    }

  };
  scrollLeft = () => {
    TweenMax.to(this.uploadsList, 0.5, { scrollLeft: "-=280" });
  };
  scrollRight = () => {
    TweenMax.to(this.uploadsList, 0.5, { scrollLeft: "+=280" });
  };
  state = {};
  render() {
    const cp = this;
    return (
      <React.Fragment>
        {/* LEFT ARROW /////////////////////////////// */}
        <div style={styles.scrollButton}>
          <ButtonBase onClick={this.scrollLeft} ref="leftArrow" style={styles.scrollButton}>
            <img src={ArrowLeft} alt="" />
          </ButtonBase>
        </div>
        {/* UPLOADS ////////////////////////////////// */}
        <div style={styles.uploads} ref="uploadsList">
          {this.props.data.uploads &&
            this.props.data.uploads.map(function(upload, i) {
              const isVideo = isTrailer(upload);
              const lastItem = i === cp.props.data.uploads.length - 1;
              return (
                <Link
                  key={i}
                  title={upload.title}
                  to={"movie/" + cp.props.data.movie.slug + "/" + cp.props.data.category_slug}
                >
                  <div style={{ ...styles.uploadThumb, marginRight: lastItem ? 0 : 10 }}>
                    <ImageLoader adaptive full={isVideo} src={URLs.MediaURL + "/" + upload.thumb} />

                    {/* PLAY ICON */}
                    {isVideo && (
                      <div style={styles.videoOverlay}>
                        <img style={styles.centered} src={SmallPlayIcon} alt="" />
                      </div>
                    )}

                    {!upload.thumb && (
                      <Typography style={styles.ext} color="primary" variant="h6">
                        {"." + upload.ext}
                      </Typography>
                    )}
                  </div>
                </Link>
              );
            })}
        </div>
        {/* RIGHT ARROW ///////////////////////////// */}
        <ButtonBase onClick={this.scrollRight} ref="rightArrow" style={styles.scrollButton}>
          <img src={ArrowRight} alt="" />
        </ButtonBase>
      </React.Fragment>
    );
  }
}

function isTrailer(upload) {
  if (!upload || !upload.ext) return false;
  const exts = ["mov", "mp4", "wmv"];

  for (var i = 0; i < exts.length; i++) {
    const ext = exts[i];
    if (ext === upload.ext.toLowerCase()) return true;
  }

  return false;
}

export default UpdatesUploads;
