import React, { Component } from "react";

class ArrowNavigation extends Component {
  componentDidMount() {
    window.addEventListener("keyup", this.keyPressed);
  }
  componentWillUnmount() {
    window.removeEventListener("keyup", this.keyPressed);
  }
  keyPressed = e => {
    if (e.key === "ArrowLeft") this.leftPressed();
    else if (e.key === "ArrowRight") this.rightPressed();
  };
  leftPressed = () => {
    if (this.props.leftPressed) this.props.leftPressed();
  };
  rightPressed = () => {
    if (this.props.rightPressed) this.props.rightPressed();
  };
  state = {};
  render() {
    return <React.Fragment />;
  }
}

export default ArrowNavigation;
