import React, { Component } from "react";
import Radium from "radium";
import { IconButton, Snackbar, Tooltip } from "@material-ui/core";
import { DownloadsIcon, SaveIcon } from "../Misc/Images";
import CartManager from "../../extras/CartManager";
import DownloadFile from "../../extras/DownloadFile";
import Strings from "../../extras/Strings";
import { withSnackbar } from "notistack";
import { withAxios } from "../Misc/AxiosManager";
import { withRouter } from "react-router";
import URLs from "../../extras/URLs";

const styles = {
  buttons: {
    display: "flex",
    width: 60,
    alignItems: "center",
    justifyContent: "flex-end"
  },
  icon: {
    padding: 5
  }
};

class AssetButtons extends Component {
  componentWillMount() {
    this.movie_slug = this.props.match.params.movie_slug;
  }
  addToCart = id => {
    var cp = this;
    new CartManager(
      id,
      this.props.isAsset ? "add_asset" : "add_upload",
      function(result) {
        cp.props.enqueueSnackbar(Strings.addedToCart);
      },
      function() {}
    );
  };
  downloadFile = id => {
    this.props.enqueueSnackbar(Strings.startingDownload);
    new DownloadFile(id, this.movie_slug + ".zip", this.props.isAsset);
  };
  getFilename = () => {
    const fileParts = this.props.file.split("/");
    if (fileParts.length > 0) {
      const filename = fileParts[fileParts.length - 1];
      return filename;
    } else return "";
  };
  state = {};
  render() {
    return (
      <div style={[styles.buttons, this.props.style]}>
        <Tooltip title={this.props.isAsset ? Strings.download : Strings.allDownload} placement="top">
          <IconButton style={styles.icon} component="a" href={URLs.MediaURL + this.props.file} download target="_blank">
            <img src={SaveIcon} alt="" />
          </IconButton>
        </Tooltip>
        <Tooltip title={this.props.isAsset ? Strings.toCart : Strings.allToCart} placement="top">
          <IconButton style={styles.icon} onClick={() => this.addToCart(this.props.id)}>
            <img src={DownloadsIcon} alt="" />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default withRouter(withAxios(withSnackbar(Radium(AssetButtons))));
