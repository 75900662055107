import React, { Component } from "react";

import Video from "./../../vid/hero.mp4";
import Colors from "../../extras/Colors";
import { Typography } from "@material-ui/core";
import Footer from "../Misc/Footer";

const styles = {
  content: {
    position: "absolute",
    height: "100%",
    width: "calc(100% - 400px)",
    marginLeft: 400,
    overflow: "hidden",
    backgroundColor: Colors.grey,
    minHeight: 625
  },
  video: {
    position: "absolute",
    height: "100%",
    width: "100%",
    transform: "translateX(-50%)",
    left: "50%",
    objectFit: "cover"
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    marginLeft: 0,
    fontSize: 14,
    color: "white",
    background: "tranparent",
    backgroundImage: "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6))",
    borderWidth: 0
  },
  footerList: {
    listStyle: "none",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: 40,
    marginTop: 10
  },
  footerItem: {
    marginLeft: 20,
    marginRight: 20
  },
  link: {
    color: "white",
    "&:hover": {
      color: "white"
    }
  }
};

class LoginContent extends Component {
  state = {};
  render() {
    return (
      <div style={styles.content}>
        <video id="video" style={styles.video} autoPlay loop muted src={Video} />
        <Footer style={styles.footer} color="white" />
      </div>
    );
  }
}

export default LoginContent;
