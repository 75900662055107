import React, { Component } from "react";
import PageContent from "../Misc/PageContent";
import SearchBar from "../Topbar/SearchBar";
import Colors from "../../extras/Colors";

import URLs from "../../extras/URLs";
import Media from "./Media";
import { withSnackbar } from "notistack";

import Radium from "radium";
import { withAxios } from "../Misc/AxiosManager";
import { withRouter } from "react-router";

const categorySlug = "classics";

const styles = {
  content: {
    width: "100%",
    minHeight: 400,
    color: Colors.lightGrey,
    padding: "115px 50px 30px 50px",
    '@media (max-width: 320px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 370px) and (min-width: 321px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 480px) and (min-width: 371px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 767px) and (min-width: 481px)': {
      padding: "195px 50px 30px 50px"
    }
  },
  header: {
    position: "absolute",
    height: 270,
    width: "calc(100% - 220px)",
    backgroundColor: Colors.lightGrey,
    overflow: "hidden",
    zIndex: 1,
    transform: "translate3d(0,0,0)"
  },
  headerFixed: {
    position: "fixed",
    height: 270,
    width: "calc(100% - 220px)",
    marginTop: -185,
    overflow: "hidden",
    backgroundColor: Colors.grey,
    zIndex: 1,
    transform: "translate3d(0,0,0)"
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  image: {
    width: "100%",
    height: "50px",
    objectFit: "cover"
  },
  buttons: {
    position: "absolute",
    display: "flex",
    right: 45,
    bottom: 20,
    transition: "opacity 0.15s"
  },
  title: {
    position: "absolute",
    fontSize: 40,
    bottom: 0,
    left: 0,
    padding: "30px 100px 30px 50px",
    color: "white",
    width: "100%"
  }
};

function headerStyle(cp) {
  if (cp.state.scrollOffset >= 185) {
    return styles.headerFixed;
  } else return styles.header;
}

class Classics extends Component {
  componentWillMount() {
    this.category_slug = categorySlug;
  }
  loadData = () => {
    var cp = this;
    this.setState({ data: {} });

    this.props.axios
      .post(URLs.MovieContentURL, {
        category_slug: this.category_slug,
      })
      .then(response => {
        const data = response.data.data;
        cp.setState({ data: data });
      });
  };
  state = {
    data: {}
  };
  render() {
    return (
      <PageContent>
        <SearchBar/>
        {/* CONTENT */}
        <div style={styles.content}>
          <CategoryContent currentPage={this.props.currentPage} data={this.state.data} />
        </div>
      </PageContent>
    );
  }
}

function CategoryContent(props) {

      return (
        <Media
          data={props.data}
          category_slug={categorySlug}
          title={"Film-Kataloge"}
          currentPage="classics"
        />
      );
}

export default withRouter(withAxios(withSnackbar(Radium(Classics))));
