import React, { Component } from "react";
import { Dialog, Typography, TextField, DialogContent } from "@material-ui/core";
import LoadingButton from "../Misc/LoadingButton";
import { withAxios } from "../Misc/AxiosManager";
import SimpleReactValidator from "simple-react-validator";
import { withSnackbar } from "notistack";
import Strings from "../../extras/Strings";
import URLs from "../../extras/URLs";
import CloseButton from "../Misc/CloseButton";
import Log from "../Misc/Log";

const styles = {
  textfield: {
    marginTop: 20
  }
};

class ResetPassword extends Component {
  componentWillMount() {
    this.validator = new SimpleReactValidator({
      validators: {
        password: {
          rule: (val, params, validator) => {
            return val;
          },
          required: true
        },
        custom_email: {
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
          }
        }
      },
      messages: {
        email: "E-Mail ist nicht gültig",
        custom_email: "E-Mail ist nicht gültig",
        cinemas: "Kinokette(n) und/oder Kino(s) müssen hinzugefügt werden",
        password: "Kennwörter stimmen nicht überein",
        required: "Dieses Feld muss ausgefühlt werden",
        accepted: "Sie müssen den Bedingungen zustimmen",
        default: "Eingabe nicht gültig"
      }
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.email !== this.props.email) {
      this.setState({ email: this.props.email });
    }
  }
  resetPassword = () => {
    Log("resetPassword");
    if (!this.validator.fieldValid("Email")) {
      this.validator.showMessages();
      this.setState({ loading: false });
      this.showError();
      this.forceUpdate();
      return;
    }

    this.setState({ loading: true });

    this.props.axios
      .post(URLs.ForgotPasswordURL, { email: this.state.email, old: this.props.old })
      .then(response => {
        Log(response.data);
        const status = response.data.status;
        this.setState({ loading: false });
        if (status === "success") {
          this.setState({ success: true });
        } else {
          this.showError();
        }
      })
      .catch(error => {
        this.setState({ loading: false });
        this.showError();
        Log(error);
      });
  };
  sendPassword = () => {
    if (!this.validator.fieldValid("Password")) {
      this.validator.showMessages();
      this.setState({ loading: false });
      this.showError();
      this.forceUpdate();
      return;
    }

    this.setState({ loading: true });

    this.props.axios
      .post(URLs.ResetPasswordURL, {
        password: this.state.password,
        password_confirmation: this.state.passwordRepeat,
        token: this.props.token
      })
      .then(response => {
        const status = response.data.status;
        this.setState({ loading: false });
        if (status === "success") {
          this.setState({ success: true });
        } else {
          this.showError();
        }
      })
      .catch(error => {
        Log(error);
        this.setState({ loading: false });
        this.showError();
      });
  };
  showError = () => {
    this.props.enqueueSnackbar(Strings.submitError, {
      variant: "error"
    });
  };
  state = {
    email: "",
    password: "",
    passwordRepeat: "",
    loading: false,
    success: false
  };
  render() {
    const token = this.props.token;
    const old = this.props.old;
    const emailError = this.validator.message("Email", this.state.email, "custom_email|required");
    const passwordError = this.validator.message(
      "Password",
      this.state.password === this.state.passwordRepeat && this.state.password.length > 0,
      "password"
    );
    return (
      <React.Fragment>
        <Dialog open={this.props.open} onClose={this.props.onClose}>
          <DialogContent style={{ width: 500 }}>
            {/* REQUEST RESET */}
            {!token && !old && !this.state.success && (
              <React.Fragment>
                <Typography color="primary" variant="h1" style={{ marginBottom: 10 }}>
                  Passwort vergessen?
                </Typography>
                <div>
                  <Typography color="primary" variant="body2">
                    Hier können Sie sich ganz unkompliziert ein neues Passwort erstellen. Geben Sie einfach Ihre
                    E-Mail-Adresse ein, die Sie bei der Registrierung benutzt haben und klicken Sie bitte auf
                    „Zurücksetzen“. Sie bekommen im Anschluss eine E-Mail, mit der Sie Ihr Passwort ändern können.
                  </Typography>
                  <TextField
                    style={styles.textfield}
                    disabled={this.state.loading}
                    fullWidth
                    type="email"
                    label="E-Mail"
                    helperText={emailError}
                    error={emailError}
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value, error: false })}
                  />
                </div>
                <LoadingButton loading={this.state.loading} onClick={() => this.resetPassword()}>
                  Zurücksetzen
                </LoadingButton>
              </React.Fragment>
            )}

            {/* OLD USER */}
            {!token && old && !this.state.success && (
              <React.Fragment>
                <Typography color="primary" variant="h1" style={{ marginBottom: 10 }}>
                  Willkommen zurück
                </Typography>
                <div>
                  <Typography color="primary" variant="body2">
                    Herzlich Willkommen auf dem neuen Marketingserver von Universal. Aus Datenschutzgründen konnten wir
                    Ihr bisheriges Passwort nicht übernehmen. Aus diesem Grund müssen Sie sich ein neues Passwort
                    vergeben.
                  </Typography>
                  <TextField
                    style={styles.textfield}
                    disabled={this.state.loading}
                    fullWidth
                    type="email"
                    label="E-Mail"
                    helperText={emailError}
                    error={emailError}
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value, error: false })}
                  />
                </div>
                <LoadingButton loading={this.state.loading} onClick={() => this.resetPassword()}>
                  Zurücksetzen
                </LoadingButton>
              </React.Fragment>
            )}

            {/* RESET PASSWORT */}
            {token && !this.state.success && (
              <React.Fragment>
                <Typography color="primary" variant="h1" style={{ marginBottom: 10 }}>
                  Neues Passwort
                </Typography>
                <div>
                  <Typography color="primary" variant="body2">
                    Geben Sie ein neues Passwort ein und klicken Sie bitte auf „Bestätigen“.
                  </Typography>
                  <TextField
                    style={styles.textfield}
                    disabled={this.state.loading}
                    fullWidth
                    type="password"
                    label="Passwort"
                    helperText={passwordError}
                    error={passwordError}
                    value={this.state.password}
                    onChange={e => this.setState({ password: e.target.value, error: false })}
                  />
                  <TextField
                    style={styles.textfield}
                    disabled={this.state.loading}
                    fullWidth
                    type="password"
                    label="Passwort wiederholen"
                    helperText={passwordError}
                    error={passwordError}
                    value={this.state.passwordRepeat}
                    onChange={e => this.setState({ passwordRepeat: e.target.value, error: false })}
                  />
                  <LoadingButton loading={this.state.loading} onClick={() => this.sendPassword()}>
                    Bestätigen
                  </LoadingButton>
                </div>
              </React.Fragment>
            )}

            {/* SUCCESS */}
            {this.state.success && (
              <React.Fragment>
                <Typography color="primary" variant="h1" style={{ marginBottom: 10 }}>
                  Erfolgreich
                </Typography>
                <div>
                  <Typography color="primary" variant="body2">
                    {!token && (
                      <React.Fragment>
                        {"Sie bekommen im Anschluss eine E-Mail an " +
                          this.state.email +
                          ", mit der Sie Ihr Passwort ganz leicht ändern können."}
                      </React.Fragment>
                    )}
                    {token && (
                      <React.Fragment>
                        {
                          "Ihr Passwort wurde erfolgreich geändert! Sie können sich nun jederzeit über das Login-Formular anmelden."
                        }
                      </React.Fragment>
                    )}
                  </Typography>
                  <LoadingButton onClick={this.props.onClose}>Schließen</LoadingButton>
                </div>
              </React.Fragment>
            )}
          </DialogContent>
        </Dialog>
        {this.props.open && <CloseButton onClick={this.props.onClose} />}
      </React.Fragment>
    );
  }
}

export default withSnackbar(withAxios(ResetPassword));
