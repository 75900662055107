import React, { Component } from "react";

import Radium from "radium";
import { IconButton } from "@material-ui/core";
import { CloseIcon } from "./Images";

const styles = {
  close: {
    position: "fixed",
    top: 20,
    right: 35,
    zIndex: 99999
  }
};

class CloseButton extends Component {
  state = {};
  render() {
    return (
      <IconButton {...this.props} style={{ ...styles.close, ...this.props.style }}>
        <img src={CloseIcon} alt="" style={{ padding: 5 }} />
      </IconButton>
    );
  }
}

export default Radium(CloseButton);
