import React, { Component } from "react";
import $ from "jquery";
import {
  ListItem,
  ListItemText,
  List,
  Collapse,
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Tooltip,
  Badge
} from "@material-ui/core";
import { Link } from "react-router-dom";

import { profilIcon, logoutIcon, mobileLogo, mobileMenuIcon, Logo, HomeIcon, MoviesIcon, UploadsIcon, FavoritenIcon, ClassicsIcon, MenuIcon, OverviewIcon, PresentationIcon, PlayIcon, PlayIconBlack, BookIcon } from "./Images";
import Colors from "../../extras/Colors";
import Strings from "../../extras/Strings";
import { withAxios, DefaultErrorHandling } from "../Misc/AxiosManager";
import URLs from "../../extras/URLs";
import ImageLoader from "../Misc/ImageLoader";
import Moment from "react-moment";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CloseButton from "../Misc/CloseButton";
import MediaQuery from "react-responsive";
import Radium, { StyleRoot } from 'radium';
import Profile from "../Topbar/Profile";
import ls from 'local-storage';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  link:{
    fontSize: 18
  },
  overrides: {
    MuiPaper: {
      root: {
        border:0
      }
    },
    MuiDialog: {
      root: {
        height: "100%"
      },
      paper: {
        height: "100%",
        background: "white",
        borderColor: Colors.lightGrey,
        border:0
      },
      paperWidthSm: {
        maxWidth: 700,
        minHeight:300,
        border:0
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
        color: "white",
        paddingTop: 0,
        paddingBottom: 0,
        boxShadow: "none"
      },
      gutters: {  
        paddingLeft: 24, 
        paddingRight: 24
      }
    },
    MuiListItem: {
      root: {
        paddingLeft: 24, 
        paddingRight: 24,
        minHeight: 50,
        backgroundColor: Colors.lightGrey,
        "&$selected": {
          backgroundColor: Colors.blue
        },
        "&&&&:hover&$selected": {
          backgroundColor: Colors.darkBlue
        },
        "&&&&:hover": {
          backgroundColor: Colors.darkGrey
        },
        "&&&&$selected": {
          backgroundColor: Colors.blue
        },
      },
      gutters: {  
        paddingLeft: 24, 
        paddingRight: 24
      },
      button: {
        backgroundColor: "transparent"
      }
    },
    MuiListItemText: {
      primary: {
        color: "white",
        fontSize: 14,
        width: 999
      }
    }
  }
});

const styles = {
  headline:{
    fontSize: 18,
    fontFamily: "Helvetica Neue",
    fontWeight: "normal",
    lineHeight: 1.6,
    color: "#8A8A8A"
    },
  linktext:{
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: 1.6,
    fontFamily: "Helvetica Neue"
  },
  nav: {
    width: 0,
    maxWidth: 220,
    height: "100%",
    overflowY: "scroll",
    position: "fixed",
    background: "white",
    zIndex: 999,
    overflowX: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "none",
    '@media (min-width: 768px)': {
      width:220
    }
  },
  navmobile: {
    width: "100%",
    // maxWidth: 220,
    height: 110,
    overflowY: "scroll",
    position: "fixed",
    zIndex: 999,
    overflowX: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "none",
    backgroundColor: "rgb(4, 140, 224)",
    border:0,
    '@media (max-width: 767px)': {
      //TODO !!!! Width animate on click 
      width: "100%",
      top: 0
    }
  }, 
  mobiledialognav: {
    width: "100%",
    // maxWidth: 220,
    height: "100%",
    overflowY: "scroll",
    position: "absolute",
    zIndex: 999,
    overflowX: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "none",
    border:0,
    '@media (max-width: 767px)': {
      width: "100%",
      top: 0
    }
  },
  logo: {
    width: "100%",
    marginTop: 50,
    marginBottom: 50,
    height: 66
  },
  logomobile:{
    marginTop: 0,
    marginLeft: 30,
    width: 60,
    height: 36,
    transform: [{ scale: 0.9 }],
    fill: "white"
  },
  logolinkmobile:{
    marginTop: 15,
    display: "inline-block"
  },
  hamburgermobile:{
    marginTop: 15,
    display: "inline-block",
    position: "absolute",
    width: 40,
    right:20,
    paddingRight: 35
  },
  icon: {
    width: 20
  },
  centered: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)"
  },
  videoOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    background: "rgba(0, 0, 0, 0.5)"
  },
  videoPreview: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    background: "black"
  },
  logoutbutton: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: 1.6,
    fontFamily: "Helvetica Neue",
    padding:2,
    paddingLeft:0,
    marginTop:5
  },
  profilebutton: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: 1.6,
    fontFamily: "Helvetica Neue",
    padding:2,
    paddingLeft:0,
    marginTop:10
  }    
};

class MainNav extends Component {
  componentWillMount() {
    this.setState({showPresentation : false});
    this.setState({showClassics : false});
    this.setState({tokenGet: ls.get('token')});
    /*
    this.setState({showPresentation : localStorage.getItem("showPresentation") ? localStorage.getItem("showPresentation") : false});
    this.setState({showClassics : localStorage.getItem("showClassics") ? localStorage.getItem("showClassics") : false});
    */
  }

  componentDidMount() {
    var cp = this;
    this.setState({videoPaused : true});
    this.loadData();

    window.addEventListener("resize", this.handleResize);
    //this.handleResize();

    //this.logout = this.logout.bind(this);
    //this.toggleProfile = this.toggleProfile.bind(this);

    const currentRoute = window.location.pathname;
    let path = window.location.pathname.split("/");
    let movieSlug = path[1];
    if(movieSlug !== 'movie'){
      /*console.log('-----------');
      console.log('Current Route: ' + currentRoute);
      console.log('Movie Slug: ' + movieSlug);
      console.log('-----------');
      */
      this.setState({ showNavBar: true });
    }
    else {
      /*
      console.log('-----------');
      console.log('Current Route: ' + currentRoute);
      console.log('Movie Slug: ' + movieSlug);
      console.log('-----------');
      */
      this.setState({ showNavBar: false });
    }
    console.log(this.state.showNavBar);
  }



  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };
  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };
  handleDialog = (show, type) => {
    this.setState({ dialog: show });
    this.setState({ dialogType: type });
  };
  handleResize = () => {
    if($(document).innerWidth() <= 768){
      setTimeout(
        function() {
          window.location.reload()
        }
        .bind(this),
        3000
      );
    }
    //this.setState({ documentWidth: window.innerWidth });
    //this.setState({ documentHeight: $(document).innerHeight() });
  };
  closeDialog = () => {
    this.setState({ dialogOpen: false });
    this.setState({ playVideo: false });
  };

  state = {
    showMenu: false,
    items: [],
    dialog: false,
    dialogType: null,
    dialogData: null,
    showPresentation: false,
    showClassics: false,
    showMobileNavigation: false,
    documentHeight: 0,
    hover:false,
    showNavBar: false,
    showProfile: false,
    logout: false,
    profileDropdown: false,
    anchorEl: null,
    showNavigationComplete: false,
  };
  loadData = () => {
    // LOAD TOKEN
   this.props.axios
    .post(URLs.MovieContentURL, {
      category_slug: "praesentation",
    })
    .then(response => {
      const data = response.data.data;
      const status = response.data.status;

      var showPresentation = (status == "success" && data.uploads && data.uploads.length && data.uploads[0].assets && data.uploads[0].assets.length);
      var presentation = showPresentation ? data.uploads[0] : false;

      localStorage.setItem("showPresentation", showPresentation);
      //console.log(presentation);

      this.setState({
        showPresentation : showPresentation,
        dialogData : (showPresentation ? data.uploads[0] : false)
      });

    });
    // LOAD TOKEN
   this.props.axios
    .post(URLs.MovieContentURL, {
      category_slug: "classics",
    })
    .then(response => {
      const data = response.data.data;
      const status = response.data.status;

      var showClassics = (status == "success" && data.uploads && data.uploads.length && data.uploads[0].assets && data.uploads[0].assets.length);

      localStorage.setItem("showClassics", showClassics);

      this.setState({
        showClassics : showClassics,
      });
      this.setState({
        showNavigationComplete : true,
      });
      
    });
  }
  render() {
    

    let presentationButtonStyle;
    presentationButtonStyle = {display: 'none'}
    if(!this.state.showPresentation){
      presentationButtonStyle = {display: 'none'}
    } 
    else{
      if(!this.state.hover){
        presentationButtonStyle = {paddingLeft: 24, paddingRight: 24, backgroundColor: 'transparent'};
      }
      else{
        presentationButtonStyle = {paddingLeft: 24, paddingRight: 24, backgroundColor: Colors.lightLightGrey};
      }
    }
    let classicsButtonStyle = {display: 'none'}  
    classicsButtonStyle = !this.state.showClassics ? {display: 'none'} : {paddingLeft: 24, paddingRight: 24};
    let showNavigationComplete =  {display: 'none'}
    showNavigationComplete = !this.state.showNavigationComplete ? {display: 'none'} : {display: 'block'};
    let currentToken = this.state.tokenGet;
    const cp = this;
    return (
      <StyleRoot>
      <React.Fragment>     
        <MediaQuery minWidth={768}>
        <nav style={styles.nav}>
          <Link to="/">
            <img style={styles.logo} src={Logo} alt="" />
          </Link>

          {this.props.collapsed && (
            <List disablePadding>
              <ListItem style={{ paddingLeft: 24, paddingRight: 24 }} button onClick={this.toggleMenu}>
                <img src={MenuIcon} alt="" />
                <ListItemText primary="Hauptmenü" />
              </ListItem>
            </List>
          )}

          {/* MAIN MENU */}
          <Collapse in={this.state.showMenu || !this.props.collapsed}>
            <List style={showNavigationComplete}>
              <Link to="/">
                <ListItem style={{ paddingLeft: 24, paddingRight: 24 }} button selected={window.location.pathname === "/"}>
                  <img style={styles.icon} src={HomeIcon} alt="" />
                  <ListItemText primary="Startseite" />
                </ListItem>
              </Link>
              <Link to="/startliste">
                <ListItem style={{ paddingLeft: 24, paddingRight: 24 }} button selected={window.location.pathname === "/startliste"}>
                  <img style={styles.icon} src={MoviesIcon} alt="" />
                  <ListItemText primary="Startliste" />
                </ListItem>
              </Link>
              <Link to="/updates">
                <ListItem style={{ paddingLeft: 24, paddingRight: 24 }} button selected={window.location.pathname === "/updates"}>
                  <img style={styles.icon} src={UploadsIcon} alt="" />
                  <ListItemText primary="Letzte Updates" />
                </ListItem>
              </Link>
              <Link to="/favoriten">
                <ListItem style={{ paddingLeft: 24, paddingRight: 24 }} button selected={window.location.pathname === "/favoriten"}>
                  <img style={styles.icon} src={FavoritenIcon} alt="" />
                  <ListItemText primary="Favoriten" />
                  {/* <FavoritesBadge />*/}
                  <Badge id="navNotifications" badgeContent="">
                    <React.Fragment />
                  </Badge>
                </ListItem>
              </Link>
              <ListItem onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} style={presentationButtonStyle} button selected={""} onClick={() => this.handleDialog(true, "praesentation")}>
                <img style={styles.icon} src={PresentationIcon} alt="" />
                <ListItemText primary="Präsentation" />
              </ListItem>
              <Link to="/classics">
                <ListItem style={classicsButtonStyle} button selected={window.location.pathname === "/classics"}>
                  <img style={styles.icon} src={ClassicsIcon} alt="" />
                  <ListItemText primary="Film-Kataloge" />
                </ListItem>
              </Link>
              <a rel="noopener noreferrer" href={'https://universal-filmnews.de/digital/intro?ext_token=' + currentToken} target="_blank"> 
                <ListItem style={classicsButtonStyle} button selected={window.location.pathname === "/classics"}>
                <img style={styles.icon} src={BookIcon} alt="" />
                <ListItemText primary="Universal.Film.News" /></ListItem>
              </a>
              {/*
              <ListItem button onClick={() => this.handleDialog(true, "classics")}>
                <img style={styles.icon} src={ClassicsIcon} alt="" />
                <ListItemText primary="Classics" />
              </ListItem>
              */}
              {/*
              <ListItem button onClick={() => this.handleDialog(true, "open_air")}>
                <img style={styles.icon} src={OpenAirIcon} alt="" />
                <ListItemText primary="Open Air" />
              </ListItem>
              */}
            </List>
          </Collapse>

          {/* MOVIE MENU */}
          {this.props.children}

          {/*  */}

          
          <Dialog
            center
            open={this.state.dialog}
            onClose={() => this.handleDialog(false, this.state.dialogType)}
            scroll="body"
            maxWidth="sm"
            style={{ top: this.state.dialogType == "praesentation" ?  "calc(100% / 2 - 256px)": "auto"}}

            //(this.state.documentHeight / 2 ) - 380
          >
            <DialogContent style={{ padding: 0, margin: 0 }}>
              <Content type={this.state.dialogType}  data={this.state.dialogData}/>
            </DialogContent>
            <CloseButton onClick={() => this.handleDialog(false, this.state.dialogType)} />
          </Dialog>
        </nav>
        </MediaQuery>
        <MediaQuery maxWidth={767}>
        {this.state.showNavBar && (
        <div>
        <nav style={styles.navmobile}>
        <Collapse in={this.state.showMenu || !this.props.collapsed}> 
          <Link to="/" style={styles.logolinkmobile} >
            <img style={styles.logomobile} src={mobileLogo} alt="" />
          </Link>
          <ListItem onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} style={styles.hamburgermobile} button selected={""} onClick={() => this.handleDialog(true, "mobilenav")}>
                <img style={styles.icon} src={mobileMenuIcon} alt="" />
          </ListItem>
          </Collapse>

            <Typography variant="h1" color="primary" style={{ marginBottom: 20, color: "white", fontSize: 15, height:20, marginLeft:25, marginTop:15}}>
              Willkommen auf dem <br />
              Marketingserver!
            </Typography>

          {/*  */}
          <Dialog
            center
            open={this.state.dialog}
            onClose={() => this.handleDialog(false, this.state.dialogType)}
            scroll="body"
            maxWidth="sm"
            fullScreen={true}
            style={{ zIndex:999, top: 80, border: 0, border:0, minHeight:800, background: "transparent"}}
            //(this.state.documentHeight / 2 ) - 380
          >
            <DialogContent style={{ padding:0, paddingLeft:30, border: 0, border:0, minHeight:800, background: "transparent", margin: 0 }}>
              <Content style={{ border: 0, border:0, minHeight:800, background: "transparent", padding: 0, margin: 0 }} type={this.state.dialogType}  data={this.state.dialogData}/>
            </DialogContent>
            <CloseButton onClick={() => this.handleDialog(false, this.state.dialogType)} />
          </Dialog>
        </nav>
        </div>
        )}
        </MediaQuery>
      </React.Fragment>
      </StyleRoot>
    );
  }
}

class Content extends Component {

  state = {
    playVideo: false,
    showProfile : false
  };

  toggleProfile = () => {
    this.setState({ dialogOpen: false });
    this.setState({ playVideo: false });
    this.setState({ showProfile: !this.state.showProfile });
    this.setState({ anchorEl: null });
  };

  toggleCart = () => {
    this.setState({ showCart: !this.state.showCart });
  };


  render() {
    var output = "";
    switch(this.props.type) {
      case "classics":
        output = (<div>
          <img
            style={{ width: "100%", borderBottomWidth: 1, borderBottomColor: Colors.lightGrey, borderBottomStyle: "solid"}}
            src="http://universal-marketingserver.de/download/classics/Universal_Classics_Flyer.png"
            alt=""
          />
          <div style={{ display: "flex", alignItems: "center", padding: "15px 15px 15px 20px" }}>
            <Typography color="primary" variant="body1" style={{ flex: 1 }}>
              universal_classics_flyer.pdf
            </Typography>{" "}
            <Tooltip title={Strings.viewPDF} placement="top">
              <IconButton
                style={{ padding: 5 }}
                component="a"
                href="http://universal-marketingserver.de/download/classics/universal_classics_flyer.pdf"
                download="Universal_Classics_Flyer.pdf"
                target="_blank"
              >
              </IconButton>
            </Tooltip>
          </div>
        </div>);
        break;
        case "praesentation":
          output = (
          <div>
            <div style={{
                position: "relative",
                height: 380,
                textAlign: "center",
                borderWidth: 0,
                borderBottomWidth: 1,
                borderStyle: "solid",
                borderBottomColor: Colors.lightGrey,
                overflow: "hidden"
              }}>
            
              {this.props.data.assets && this.props.data.assets.length > 0 && this.props.data.assets[0].type === "video" && (
                <div style={{top:30}}>
                  <ImageLoader adaptive full src={URLs.MediaURL + this.props.data.thumb} />
                  <div
                    onClick={() => this.setState({ playVideo: true })}
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      left: 0,
                      top: 0,
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                      cursor: "pointer",
                      transition: "opacity 0.3s",
                      background: "rgba(0, 0, 0, 0.5)"
                    }}
                  >
                    <img style={styles.centered} src={PlayIcon} alt="" />
                    {this.state.playVideo && (
                      <video
                        id="media-video"
                        style={styles.videoPreview}
                        autoPlay
                        controls
                        src={URLs.MediaURL + "/" + getVideoPreview(this.props.data)}
                      />
                    )}
                  </div>
                </div>
              )}
              {this.props.data.assets && this.props.data.assets.length > 0 && this.props.data.assets[0].type === "audio" && (
                <div>
                  <div
                    onClick={() => this.setState({ playVideo: true })}
                  >
                    <img style={styles.centered} src={PlayIconBlack} alt="" />
                    {this.state.playVideo && (
                      <video
                        id="media-video"
                        style={styles.videoPreview}
                        autoPlay
                        controls
                        src={URLs.MediaURL + "/" + getAudioPreview(this.props.data)}
                      />
                    )}
                  </div>
              </div>
            )}
            </div>
            <div style={{ display: "flex", alignItems: "center", padding: "15px 15px 15px 20px" }}>
              <Typography color="primary" variant="body1" style={{ flex: 1 }}>
                {this.props.data.title}
              </Typography>{" "}
              <Typography align="right" color="primary" variant="body1" style={styles.date}>
                      <Moment format="DD. MMMM YYYY" unix>
                        {this.props.data.created_at}
                      </Moment>
                    </Typography>
            </div>
          </div>);
          break;
      case "mobilenav":
        const cp = this;
        output = (
        <div style={styles.mobiledialognav}>
          <div style={{
                position: "fixed",
                height: "auto",
                textAlign: "left",
                borderWidth: 0,
                borderBottomWidth: 0,
                borderStyle: "solid",
                borderBottomColor: "transparent",
                overflow: "hidden", 
                border:0
              }}>
            {/* Marketing */}
            <Typography
              style={{ ...styles.headline, marginTop: 10 }}
              variant="sectionTitleMedium"
              color={Colors.fontGrey}
            >
              Marketing
            </Typography>
            <ul style={styles.list}>
              <li style={styles.item}>
                <a href="/">
                  <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body3">
                    Startseite
                  </Typography>
                </a>
              </li>
              <li style={styles.item}>
                <a href="/startliste">
                  <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body3">
                  Startliste
                  </Typography>
                </a>
              </li>
              <li style={styles.item}>
                <a href="/updates">
                  <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body3">
                  Updates
                  </Typography>
                </a>
              </li>
              <li style={styles.item}>
                <a href="/favoriten">
                  <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body3">
                  Favoriten
                  </Typography>
                </a>
              </li>
              {/* 
              <li style={styles.item}>
                  <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body2" onClick={() => this.handleDialog(true, "praesentation")}>
                  Präsentation
                  </Typography>
              </li>
              */}
              <li style={styles.item}>
                <a href="/classics">
                  <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body3">
                  Film-Kataloge
                  </Typography>
                </a>
              </li>
            </ul>


            {/* UNIVERSAL */}
                    <Typography
              style={{ ...styles.headline, marginTop: 10 }}
              variant="sectionTitleMedium"
              color={Colors.fontGrey}
            >
              Universal
            </Typography>
        <ul style={styles.list}>
          <li style={styles.item}>
            <a href="mailto:info@universal-marketingserver.de">
              <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body3">
                Kontakt
              </Typography>
            </a>
          </li>
          <li style={styles.item}>
            <a href="/legal">
              <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body3">
                Impressum
              </Typography>
            </a>
          </li>
          <li style={styles.item}>
            <a href="https://www.nbcuniversal.com/privacy-policies/privacy-german" target="_blank">
              <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body2">
                Nutzungsbedingungen
              </Typography>
            </a>
          </li>
          <li style={styles.item}>
            <a href="https://www.nbcuniversal.com/privacy-policies/privacy-german" target="_blank">
              <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body3">
                Datenschutz
              </Typography>
            </a>
          </li>
        </ul>

            {/* SOCIAL */}
            <Typography
              style={{ ...styles.headline, marginTop: 10 }}
              variant="sectionTitleMedium"
              color={Colors.fontGrey}
            >
              Social
            </Typography>
        <ul style={styles.list}>
          <li style={styles.item}>
            <a href="https://www.facebook.com/UniversalPicturesDE" target="_blank">
              <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body3">
              Facebook
              </Typography>
            </a>
          </li>
          <li style={styles.item}>
            <a href="https://www.youtube.com/user/UniversalPicturesDE" target="_blank">
              <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body3">
              Youtube
              </Typography>
            </a>
          </li>
          <li style={styles.item}>
            <a href="https://www.instagram.com/universalpicturesde/" target="_blank">
              <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body3">
              Instagram
              </Typography>
            </a>
          </li>
          <li style={styles.item}>
            <a href="https://twitter.com/upigermany" target="_blank">
              <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body3">
              Twitter
              </Typography>
            </a>
          </li>
          <li style={styles.item}>
            <a href="https://universalpicturesde.tumblr.com" target="_blank">
              <Typography style={{ ...styles.linktext, color: this.props.color }} color="secondary" variant="body">
              Tumblr
              </Typography>
            </a>
          </li>
        </ul>
        
        <ul style={styles.list}>
          <li style={styles.item} >
              <IconButton onClick={() => this.toggleProfile()} style={styles.profilebutton}>
                  <img src={profilIcon} alt="" style={{ marginRight: 3 }}/> Profil
                </IconButton>
          </li>
          <li style={styles.item}>
              <IconButton  onClick={logout} style={styles.logoutbutton}>
                  <img src={logoutIcon} alt=""  style={{ marginRight: 3 }}/> Logout
                </IconButton>
          </li>

        </ul>

        </div>
        <div id="profile-content" style={{ zIndex:99999999 }} >
          <Profile style={{ zIndex:99999999 }} open={this.state.showProfile} toggleProfile={() => this.toggleProfile()} />
        </div>
        <div id="dialog-content">
        <Dialog
            center
            open={this.state.dialog}
            onClose={() => this.handleDialog(false, this.state.dialogType)}
            scroll="body"
            maxWidth="sm"
            style={{ top: this.state.dialogType == "praesentation" ?  "calc(100% / 2 - 256px)": "auto"}}

            //(this.state.documentHeight / 2 ) - 380
          >
            <DialogContent style={{ padding: 0, margin: 0 }}>
              <Content type={this.state.dialogType}  data={this.state.dialogData}/>
            </DialogContent>
            <CloseButton onClick={() => this.handleDialog(false, this.state.dialogType)} />
          </Dialog>
        </div>
        </div>
       
        
        );
        break;
    }
    return output;
  }
}
function logout(){
    localStorage.clear();
    window.location.href = '/';
}
function getVideoPreview(upload) {
  // Find MP4
  for (var i = 0; i < upload.assets.length; i++) {
    var asset = upload.assets[i];
    for (var f = 0; f < asset.files.length; f++) {
      var file = asset.files[f];
      if (file.ext.toLowerCase().indexOf("mp4") !== -1) {
        return file.file;
      }
    }
  }
}

function getAudioPreview(upload) {
  // Find mp3, wav or ogg
  for (var i = 0; i < upload.assets.length; i++) {
    var asset = upload.assets[i];
    for (var f = 0; f < asset.files.length; f++) {
      var file = asset.files[f];
      if (file.ext.toLowerCase().indexOf("mp3") !== -1 || file.ext.toLowerCase().indexOf("wav") !== -1 || file.ext.toLowerCase().indexOf("ogg") !== -1) {
        return file.file;
      }
    }
  }
}

export default withAxios(MainNav);
