import React, { Component } from "react";
import Colors from "../../extras/Colors";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/de";
import { Typography } from "@material-ui/core";

import { Link } from "react-router-dom";

import Radium from "radium";
import URLs from "../../extras/URLs";
import { SocialIcon, UploadsIcon } from "../Misc/Images";
import Log from "../Misc/Log";

import { detect } from "detect-browser";
const browser = detect();

const styles = {
  categoryIconContainer: {
    width: 20,
    height: 30,
    marginRight: 15,
    marginTop: -4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.lightLightGrey,
    zIndex: 1
  },
  categoryIcon: {
    opacity: 0.5,
    width: 20,
    height: 20,
    minWidth: 20,
    minHeight: 20,
    filter: "invert(1)"
  }
};

const calendarStrings = {
  lastDay: "[Gestern -] LT",
  sameDay: "[Heute -] LT",
  nextDay: "[Morgen -] LT",
  lastWeek: "DD. MMMM YYYY [-] LT",
  nextWeek: "DD. MMMM YYYY [-] LT",
  sameElse: "DD. MMMM YYYY [-] LT"
};

class Upload extends Component {
  description = data => {
    const category = data.category_name;
    const action = data.action;
    const type = data.type;
    //const count = data.count;

    if (action === "create" && type === "movie") return "Neuer Film";

    //const countString = count > 2 && category !== "Übersicht" ? count + " " : "";

    return category;
  };
  categoryIcon = (icon, category_slug) => {
    if (category_slug.indexOf("social") !== -1) {
      return SocialIcon;
    }

    return URLs.MediaURL + icon;
  };
  state = {};
  render() {
    const isIE = browser.name === "ie";
    return (
      <div {...this.props}>
        <div
          style={{
            position: "absolute",
            width: 1,
            height: this.props.count === this.props.id ? "50%" : "100%",
            top: this.props.id === 0 ? "50%" : 0,
            left: 10,
            backgroundColor: Colors.lightGrey
          }}
        />
        <Link
          to={"/movie/" + this.props.data.movie_slug + "/" + this.props.data.category_slug}
          style={{ position: "relative", display: "flex", alignItems: "center" }}
        >
          <div style={styles.categoryIconContainer}>
            <img
              style={styles.categoryIcon}
              src={isIE ? UploadsIcon : this.categoryIcon(this.props.data.category_icon, this.props.data.category_slug)}
              alt=""
            />
          </div>
          <div style={{ width: "100%" }}>
            <Typography style={{ textTransform: "none" }} color="secondary" variant="overline">
              <Moment calendar={calendarStrings} unix>
                {this.props.data.updated_at}
              </Moment>
            </Typography>
            <Typography
              color="primary"
              variant="body1"
              noWrap={this.props.home}
              style={{ width: this.props.home ? "calc(100% - 35px)" : "auto" }}
            >
              {this.props.data.movie_title}
            </Typography>
            <Typography color="primary" variant="body2">
              {this.description(this.props.data)}
            </Typography>
          </div>
        </Link>
      </div>
    );
  }
}

export default Radium(Upload);
