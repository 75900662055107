import React, { Component } from "react";
import ForgotNav from "./ForgotNav";
import LoginContent from "./LoginContent";
import Profile from "../Topbar/Profile";

class ForgotPage extends Component {
  toggleRegister = () => {
    this.setState({ register: !this.state.register });
  };
  state = {
    register: false
  };
  render() {
    return (
      <React.Fragment>
        <ForgotNav handler={this.toggleRegister} />
        <LoginContent />
        <Profile register open={this.state.register} toggleProfile={this.toggleRegister} />
      </React.Fragment>
    );
  }
}

export default ForgotPage;
