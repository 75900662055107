import React, { Component } from "react";
import Colors from "../../extras/Colors";

import { TextBlock, TextRow, RectShape } from "react-placeholder/lib/placeholders";

import Radium, { StyleRoot } from 'radium';
import MediaQuery from "react-responsive";

const moviePosterBigPH = (
  <div style={{ width: 170, marginRight: 10, marginBottom: 20 }}>
    <RectShape style={{ width: 170, height: 238 }} color={Colors.lightGrey} />
    <TextRow color={Colors.lightGrey} style={{ width: "80%", marginTop: 12, height: 15 }} />
    <TextRow color={Colors.lightGrey} style={{ width: "50%", marginTop: 6, height: 15 }} />
  </div>
);

const moviePosterSmallPH = (
  <div style={{ width: 140, marginRight: 10, marginBottom: 20 }}>
    <RectShape style={{ width: 140, height: 196 }} color={Colors.lightGrey} />
    <TextRow color={Colors.lightGrey} style={{ width: "80%", marginTop: 12, height: 14 }} />
    <TextRow color={Colors.lightGrey} style={{ width: "50%", marginTop: 6, height: 14 }} />
  </div>
);

const uploadPH = (
  <div style={{ marginBottom: 30, display: "flex", alignItems: "center" }}>
    <RectShape style={{ width: 15, height: 15, padding: 5, marginRight: 18, marginLeft: 2 }} color={Colors.lightGrey} />
    <div style={{ flex: 1 }}>
      <TextRow color={Colors.lightGrey} style={{ height: 12, width: "20%", marginTop: 0 }} />
      <TextRow color={Colors.lightGrey} style={{ height: 14, width: "80%", marginTop: 5 }} />
      <TextRow color={Colors.lightGrey} style={{ height: 14, width: "40%", marginTop: 5 }} />
    </div>
  </div>
);

const movieListPh = (
  <div style={{ marginBottom: 30, marginTop: 0 }}>
    <TextRow color={Colors.lightGrey} style={{ width: 60, height: 14, marginTop: 0 }} />
    <div style={{ display: "flex", marginTop: 30 }}>
      <TextRow style={{ width: 70, marginRight: 20, height: 14, marginTop: 0 }} color={Colors.lightGrey} />
      <TextRow color={Colors.lightGrey} style={{ flex: 1, maxWidth: "30%", height: 14, marginTop: 0 }} />
    </div>
    <div style={{ display: "flex", marginTop: 10 }}>
      <TextRow style={{ width: 70, marginRight: 20, height: 14, marginTop: 0 }} color={Colors.lightGrey} />
      <TextRow color={Colors.lightGrey} style={{ flex: 1, maxWidth: "70%", height: 14, marginTop: 0 }} />
    </div>
    <div style={{ display: "flex", marginTop: 10 }}>
      <TextRow style={{ width: 70, marginRight: 20, height: 14, marginTop: 0 }} color={Colors.lightGrey} />
      <TextRow color={Colors.lightGrey} style={{ flex: 1, maxWidth: "50%", height: 14, marginTop: 0 }} />
    </div>
  </div>
);

/*
const MediaPH = (
  <div
    style={{
      position: "relative",
      height: 350,
      width: "calc(33.3% - 10px)",
      background: "white",
      borderColor: Colors.lightGrey,
      borderStyle: "solid",
      borderWidth: 1,
      boxSizing: "border-box",
      display: "inline-block",
      margin: 5,
      transform: "translate(-5px, -5px)"
    }}
  >
    <RectShape style={{ height: 175 }} color={Colors.lightGrey} />
    <TextRow style={{ height: 14, width: "40%", margin: 20 }} color={Colors.lightGrey} />
    <TextBlock
      style={{ marginLeft: 20, marginRight: -20, marginTop: -5 }}
      rows={3}
      color={Colors.lightGrey}
      widths={[70, 80, 30]}
    />
    <TextRow style={{ height: 14, width: "70%", margin: 20 }} color={Colors.lightGrey} />
  </div>
);
*/

function MediaPH(c) {
  return (
    
    <div
      style={{
        position: "relative",
        height: 350,
        width: "calc(33.3% - 10px)",
       //width: "100%",
        '@media (max-width: 767px)': {
          width: "100%"
        },
        background: "white",
        borderColor: Colors.lightGrey,
        borderStyle: "solid",
        borderWidth: 1,
        boxSizing: "border-box",
        display: "inline-block",
        margin: 5,
        transform: "translate(-5px, -5px)"
      }}
    >
      <RectShape style={{ height: 175 }} color={Colors.lightGrey} />
      <TextRow style={{ height: 14, width: "40%", margin: 20 }} color={Colors.lightGrey} />
      <TextBlock
        style={{ marginLeft: 20, marginRight: -20, marginTop: -5 }}
        rows={3}
        color={Colors.lightGrey}
        widths={[70, 80, 30]}
      />
      <TextRow style={{ height: 14, width: "70%", margin: 20 }} color={Colors.lightGrey} />
    </div>
    
  );
}

export { moviePosterBigPH, moviePosterSmallPH, uploadPH, movieListPh, MediaPH };
