import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/de";
import SectionHeadline from "../Misc/SectionHeadline";
import SearchBar from "../Topbar/SearchBar";
import PageContent from "../Misc/PageContent";
import Colors from "../../extras/Colors";
import URLs from "../../extras/URLs";
import ImageLoader from "../Misc/ImageLoader";
import { Typography, Tooltip, ButtonBase, CircularProgress, Fade, Collapse } from "@material-ui/core";
import { RectShape, TextRow } from "react-placeholder/lib/placeholders";
import ReactPlaceholder from "react-placeholder/lib";
import UpdatesUploads from "./UpdatesUploads";
import { Link } from "react-router-dom";
import Upload from "../Home/Upload";
import ScrollBottomListener from "../Misc/ScrollBottomListener";
import { withAxios, DefaultErrorHandling } from "../Misc/AxiosManager";
import Strings from "../../extras/Strings";
import Radium, { StyleRoot } from 'radium';
import MediaQueryCss from "../Responsive/MediaQueryCss";
import MediaQuery from "react-responsive";
import $ from "jquery";

const styles = {
  content: {
    padding: "115px 50px 30px 50px",
    minHeight: "100%",
    flex: 1,
    color: Colors.grey,
    '@media (max-width: 320px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 370px) and (min-width: 321px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 480px) and (min-width: 371px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 767px) and (min-width: 481px)': {
      padding: "195px 50px 30px 50px"
    },
    paddingBottom: 100,
  },
  placeholder: { height: 150, marginBottom: 20 },
  container: {
    display: "flex",
    width: "calc(100% + 50px)",
    '@media (max-width: 767px)': {
      width: "100%",
    }
  },
  thumb: {
    display: "block",
    minWidth: 105,
    maxWidth: 105,
    height: 150,
    marginRight: 15,
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.lightGrey,
    background: "white"
  },
  infos: {
    position: "relative",
    minWidth: 230,
    maxWidth: 230,
    marginRight: 20,
    marginTop: -20,
    display: "flex",
    alignItems: "center"
  },
  categoryIconContainer: {
    width: 20,
    height: 30,
    marginRight: 15,
    marginTop: -4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.lightLightGrey,
    zIndex: 1
  },
  categoryIcon: {
    filter: "invert(1)",
    opacity: 0.5,
    width: 20,
    height: 20
  },
  line: {
    position: "absolute",
    width: 1,
    height: "100%",
    top: 0,
    left: 10,
    backgroundColor: Colors.lightGrey
  }
};

class UpdatesContent extends Component {
  componentDidMount() {
    //console.log($(document).innerWidth());
    let count_mount = 5;
    if($(document).innerWidth() >= 768){
       count_mount = 10;
    }
    // LOAD DATA
    this.loadData(count_mount);
    
    $("#updates-content").css("width", ($(document).innerWidth() - 220 ) + 'px'); 
    
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.page > prevState.page) {
      let count_mount = 5;
      if($(document).innerWidth() >= 768){
         count_mount = 10;
      }
      this.loadData(count_mount);
    }
  }
  loadData = (count_mount) => {
    this.setState({ loading: true });
    const count = count_mount;
    this.props.axios
      .post(URLs.UpdatesURL + "/" + this.state.page * count + "/" + count)
      .then(response => {
        this.setState({ loading: false });
        this.setState({ loaded: true });
        this.setState({ updates: this.state.updates.concat(response.data.data.updates) });
      })
      .catch(error => {
        DefaultErrorHandling(error);
      });
  };
  state = {
    loaded: false,
    loading: false,
    page: 0,
    updates: [], 
    count: 5
  };
  render() {
    const cp = this;
    return (
      <StyleRoot>
      <PageContent>
        <SearchBar />
        <div style={styles.content} id="updates-content">
          <SectionHeadline>Letzte Updates</SectionHeadline>
          {!this.state.loaded && <ContentPH />}
          {this.state.loaded &&
            this.state.updates.map(function(update, i) {
              return <Update key={i} id={i} count={cp.state.updates.length - 1} data={update} />;
            })}
        </div>
        <Fade in={this.state.loading}>
          <div style={{ width: "100%", justifyContent: "center", display: "flex", marginTop: -20 }}>
            <CircularProgress size={22} color="secondary" style={{ marginBottom: 30 }} />
          </div>
        </Fade>

        <ScrollBottomListener
          onBottom={() => {
            this.setState({ page: this.state.page + 1 });
          }}
        />
      </PageContent>
      </StyleRoot>
    );
  }
}

function Update(props) {
  return (
    
    <div style={styles.container}>
      <Link to={"/" + Strings.movie + "/" + props.data.movie_slug + "/" + Strings.overview}>
        <div style={styles.thumb}>
          <ImageLoader full showDefault src={URLs.MediaURL + props.data.movie.thumb} />
        </div>
      </Link>
      <Upload style={styles.infos} id={props.id} count={props.count} data={props.data} />
      {/* UPLOADS */}
      <MediaQuery minWidth={768}>
          <UpdatesUploads data={props.data} />
      </MediaQuery>
    </div>
    
  );
}

function ContentPH(props) {
  return (
    <React.Fragment>
      <ReactPlaceholder ready={false} children={""} customPlaceholder={ContentItemPH} showLoadingAnimation />
      <ReactPlaceholder ready={false} children={""} customPlaceholder={ContentItemPH} showLoadingAnimation />
      <ReactPlaceholder ready={false} children={""} customPlaceholder={ContentItemPH} showLoadingAnimation />
      <ReactPlaceholder ready={false} children={""} customPlaceholder={ContentItemPH} showLoadingAnimation />
      <ReactPlaceholder ready={false} children={""} customPlaceholder={ContentItemPH} showLoadingAnimation />
    </React.Fragment>
  );
}

const ContentItemPH = (
  <div style={{ display: "flex", alignItems: "center" }}>
    <RectShape style={{ ...styles.thumb, border: "none", background: "auto" }} color={Colors.lightGrey} />
    <div style={{ marginBottom: 30, display: "flex", alignItems: "center", width: 512 }}>
      <RectShape
        style={{ width: 15, height: 15, padding: 5, marginRight: 18, marginLeft: 2 }}
        color={Colors.lightGrey}
      />
      <div style={{ flex: 1 }}>
        <TextRow color={Colors.lightGrey} style={{ height: 12, width: "20%", marginTop: 0 }} />
        <TextRow color={Colors.lightGrey} style={{ height: 14, width: "40%", marginTop: 5 }} />
        <TextRow color={Colors.lightGrey} style={{ height: 14, width: "40%", marginTop: 5 }} />
      </div>
    </div>
    <MediaQuery minWidth={768}>
      <RectShape color={Colors.lightGrey} style={styles.placeholder} />
    </MediaQuery>
  </div>
);

export default withAxios(Radium(UpdatesContent));
