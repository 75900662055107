import React, { Component } from "react";
import PageContent from "../Misc/PageContent";
import SearchBar from "../Topbar/SearchBar";
import Colors from "../../extras/Colors";

import { IconButton, Typography, Tooltip } from "@material-ui/core";
import URLs from "../../extras/URLs";
import MovieOverview from "./MovieOverview";
import MovieDescription from "./MovieDescription";
import MovieMedia from "./MovieMedia";
import { TweenMax } from "gsap";
import { withSnackbar } from "notistack";

import $ from "jquery";
import ImageLoader from "../Misc/ImageLoader";
import { backIcon, FavoritesIcon, FavoritesActiveIcon, NotificationsActiveIcon, NotificationsIcon } from "../Misc/Images";

import Gradient from "../Misc/Gradient";
import Strings from "../../extras/Strings";
import { withAxios, DefaultErrorHandling } from "../Misc/AxiosManager";
import { withRouter } from "react-router";
import Radium, { StyleRoot } from 'radium';
import MovieNavMobile from "./MovieNavMobile";
import MainNav from "../Misc/MainNav";
import MediaQuery from "react-responsive";
import { Redirect } from 'react-router-dom'

const styles = {
  content: {
    position: "absolute",
    marginTop: 270,
    padding: "30px 50px 50px 50px",
    width: "calc(100% - 220px)",
    minHeight: 400,
    color: Colors.lightGrey,
    '@media (max-width: 767px)': {
      width: "100%",
    },
    '@media (max-width: 500px)': {
      padding: "30px 15px 50px 15px",
    },
  },
  header: {
    position: "absolute",
    height: 270,
    width: "calc(100% - 220px)",
    backgroundColor: Colors.lightGrey,
    overflow: "hidden",
    zIndex: 1,
    transform: "translate3d(0,0,0)",
    '@media (max-width: 767px)': {
      width: "100%"
    }
  },
  headerFixed: {
    position: "fixed",
    height: 270,
    width: "calc(100% - 220px)",
    marginTop: -185,
    overflow: "hidden",
    backgroundColor: Colors.grey,
    zIndex: 1,
    transform: "translate3d(0,0,0)"
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  image: {
    width: "100%",
    minWidth: 1280,
    minHeight: "100%"
  },
  buttons: {
    position: "absolute",
    display: "flex",
    right: 45,
    transition: "opacity 0.15s",
    '@media (max-width: 767px)': {
      right: 10,
      top: 10
    },
    '@media (min-width: 768px)': {
      right: 45,
      bottom: 20
    }
  },
  title: {
    position: "absolute",
    fontSize: 40,
    bottom: 0,
    left: 0,
    padding: "30px 100px 30px 50px",
    color: "white",
    width: "100%"
  },
  backiconst: {
    position: "absolute",
    display: "flex",
    left: 10,
    top:10,
    transition: "opacity 0.15s"
  },
  backiconstdebug: {
    position: "absolute",
    display: "flex",
    left: 30,
    top:10,
    transition: "opacity 0.15s"
  },
  imaged:{
    maxHeight: 120, 
    maxWidth: 600,
    '@media (max-width: 700px)': {
      maxHeight: 77, 
      maxWidth: 500
    },
    '@media (max-width: 370px)': {
      maxHeight: 55, 
      maxWidth: 370
    }
  }
};

function headerStyle(cp) {
  if (cp.state.scrollOffset >= 185) {
    return styles.headerFixed;
  } else return styles.header;
}

class MovieContent extends Component {
  componentWillMount() {
    this.movie_slug = this.props.match.params.movie_slug;
    this.category_slug = this.props.match.params.category_slug;
    /* needed for mobile */
    this.loadData();
  }
  componentDidMount() {
    
    var cp = this;
    $(window).scroll(function() {
      // 185
      const scrollOffset = $(this.window).scrollTop();
      const isFixed = scrollOffset >= 185;

      $("#movie-header").css("position", isFixed ? "fixed" : "absolute");
      $("#movie-header").css("margin-top", isFixed ? "-185px" : "0");
      //$("#movie-header").css("z-index", isFixed ? "1" : "0");

      const hideLogo = scrollOffset >= 35;
      $("#movie-logo").css("opacity", hideLogo ? "0" : "1");
      $("#movie-buttons").css("opacity", hideLogo ? "0" : "1");
    });
    if($(document).innerWidth() >= 768){
      this.setState({ isMobile: false });
    }
    else{
      this.setState({ isMobile: true }); 
    } 
    $("#movie-page").css("height", ($(document).innerHeight() - 100 ) + 'px'); 
    $("#movie-content").css("height", ($(document).innerHeight() - 100 - $('#movie-header').height()) + 'px'); 

    //console.log("Current Page: " + this.props.currentPage);
  }
  componentDidUpdate(prevProps, prevState) {
    // UPDATE SLUGS
    this.movie_slug = this.props.match.params.movie_slug;
    this.category_slug = this.props.match.params.category_slug;

    // LOAD WHEN CURRENTPAGE IS NEW
    if (prevProps.currentPage !== this.props.currentPage) {
      this.loadData();
    }
    /*
    console.log("--------------");
    console.log("Current Page ---> updated: " + this.props.currentPage);
    console.log("Prev Current Page ---> updated: " + prevProps.currentPage);
    console.log("--------------");
    */
  }
  loadData = () => {
    var cp = this;
    this.setState({ data: {} });
    this.setState({ movieSlug: this.movie_slug });
    this.props.axios
      .post(URLs.MovieContentURL, {
        category_slug: this.category_slug,
        movie_slug: this.movie_slug
      })
      .then(response => {
        const data = response.data.data;
        console.log(data.category.name);

        cp.setState({ data: data });
        cp.setState({ isFavorite: data.movie.favorite });
        cp.setState({ hasContent: data.movie.has_content });
        cp.setState({ title: data.movie.title });
        
        cp.setState({ category: data.category });
        cp.setState({ contents: data.contents });
        cp.setState({ movie: data.movie });
        cp.setState({ currentPage: data.category.slug });
        cp.setState({ currentPageTitle: data.category.name });

        if (data.movie.header_desktop !== null)
          cp.setState({ header: URLs.MediaURL + "/" + data.movie.header_desktop });
        if (data.movie.logo_desktop !== null) cp.setState({ logo: URLs.MediaURL + "/" + data.movie.logo_desktop });
      });

      
  };
  favMovie = () => {
    var cp = this;

    this.props.axios
      .post(URLs.FavoriteURL, { movie_slug: this.movie_slug })
      .then(response => {
        cp.setState({ isFavorite: !cp.state.isFavorite });
        cp.props.enqueueSnackbar(
          cp.state.isFavorite
            ? cp.state.hasContent
              ? Strings.addedFavorite
              : Strings.addedNotifications
            : cp.state.hasContent
            ? Strings.removedFavorite
            : Strings.removedNotifications
        );
      })
      .catch(error => {
        DefaultErrorHandling(error);
      });
  };

  movieNavigation = () => {
    var cp = this;
    console.log('Movie Navigation');
    this.setState({ showMobileNav: true });
    console.log(this.state.showMobileNav);
  };
  movieNavigationBack = () => {
    window.location.assign("/");
  };      
  handleSnack = active => {
    this.setState({ showSnack: active });
  };
  state = {
    hasContent: false,
    isFavorite: null,
    title: null,
    header: null,
    logo: null,
    data: {},
    showLogo: true, 
    showMobileNav: false,
    isMobile : false,
    category: {},
    contents: {},
    movie: {},
    currentPage: "",
    currentPageTitle: ""
  };

  consoleLogOutput = () => {
    /*console.log("Category: " + this.state.category);
    console.log("Contents: " + this.state.contents);
    console.log("Movie: " + this.state.movie);
    console.log("Current Page: " + this.state.currentPage);
    console.log("Current Page Title: " + this.state.currentPageTitle);
    */
    
  };   
  
  render() {
    return (
      <StyleRoot>
      <PageContent>
        <SearchBar opaque />
        <div id="movie-header" style={styles.header}>
          <ImageLoader
            containerStyle={styles.imageContainer}
            imageStyle={styles.image}
            src={this.state.header}
            header
          />
          {this.state.header && (
            <React.Fragment>
              <Gradient style={{ position: "absolute", width: "100%", height: 150, top: 0, left: 0 }} />
              <Gradient inverted style={{ position: "absolute", width: "100%", height: 150, bottom: 0, left: 0 }} />
            </React.Fragment>
          )}

          {/* LOGO & TITLE */}
          <div id="movie-logo" style={{ transition: "opacity 0.15s" }}>
            <ImageLoader
              imageStyle={styles.imaged}
              containerStyle={[styles.title, { width: "auto", alignItems: "flex-end" }]}
              src={this.state.logo}
            />
            {this.state.logo === null && (
              <Typography variant="h2" style={styles.title}>
                {this.state.title}
              </Typography>
            )}
          </div>
          <MediaQuery maxWidth={767}>
          {/*<IconButton style={styles.backiconstdebug} onClick={() => this.consoleLogOutput()}>
            <img src={ backIcon } alt="" />
          </IconButton>
            */}
          {this.state.isMobile && !this.state.showMobileNav && (
            <Tooltip placement="left" title={Strings.backText}>
              <IconButton style={styles.backiconst} onClick={() => this.movieNavigation()}>
                  <img src={ backIcon } alt="" />
              </IconButton>
            </Tooltip>
            )}
            {this.state.isMobile && this.state.showMobileNav && (
             <Tooltip placement="left" title={Strings.backText}>
             <IconButton style={styles.backiconst} onClick={() => this.movieNavigationBack()}>
                 <img src={ backIcon } alt="" />
             </IconButton>
           </Tooltip>
            )}
          </MediaQuery>
          {/* NOTIFICATION & FAV BUTTONS */}
          {this.state.isFavorite !== null && (
            <div id="movie-buttons" style={styles.buttons}>
              <Tooltip placement="left" title={this.state.isFavorite ? Strings.removeFavorite : Strings.addFavorite}>
                <IconButton onClick={() => this.favMovie()}>
                  <img src={this.state.isFavorite ? FavoritesActiveIcon : FavoritesIcon} alt="" />
                </IconButton>
              </Tooltip>
               
            </div>
          )}
        </div>

        {this.state.showMobileNav && (
          <MovieNavMobile
            currentPage={this.state.currentPage}
            currentPageTitle={this.state.currentPageTitle}
            moviePage={this}
            movieContent={this}
            showMobileNav={this.state.showMobileNav}
            history={this.props.history}
            style={{ marginTop: 43 }}
          />
        
        )}
        {/* CONTENT */}
        {!this.state.showMobileNav && (
        <div id="movie-content" style={styles.content}>
          <CategoryContent currentPage={this.state.currentPage} currentPageTitle={this.state.currentPageTitle} data={this.state.data} />
        </div>
        )}
      </PageContent>
      </StyleRoot>
    );
  }
}

function CategoryContent(props) {

  //console.log("---------------");
  //console.log("Currentpage in Category Content" + JSON.stringify(props, null, 2));
  //console.log(props.data.category.name);
  
  switch (props.currentPage) {
    case "uebersicht":
      return <MovieOverview data={props.data} title="Übersicht" />;
    case "kurzinhalt":
      return <MovieDescription data={props.data} title="Kurzinhalt" />;
    default:
      return (
        <MovieMedia
          data={props.data}
          title={props.currentPageTitle}
          currentPage={props.currentPage}
        />
      );
  }
}

export default withRouter(withAxios(withSnackbar(Radium(MovieContent))));
