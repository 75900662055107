import React, { Component } from "react";
import SectionHeadline from "../Misc/SectionHeadline";
import { TextBlock } from "react-placeholder/lib/placeholders";
import Colors from "../../extras/Colors";

import { DocImage, SaveIcon, DownloadsIcon, CopyIcon } from "../Misc/Images";

import "react-placeholder/lib/reactPlaceholder.css";
import { IconButton, Tooltip, Snackbar, Typography } from "@material-ui/core";
import { withSnackbar } from "notistack";

import { CopyToClipboard } from "react-copy-to-clipboard";
import DownloadFile from "../../extras/DownloadFile";
import CartManager from "../../extras/CartManager";
import Strings from "../../extras/Strings";
import ReactHtmlParser from "react-html-parser";
import striptags from "striptags";
import $ from "jquery";
import Document from "../Movie/Document";
import { withRouter } from "react-router";
import URLs from "../../extras/URLs";
import Radium, { StyleRoot } from 'radium';
import MediaQuery from "react-responsive";

const styles = {
  content: {
    display: "flex",
    '@media (max-width: 767px)': {
      display: "block"
    },
  },
  description: {
    flex: 1,
    marginRight: 60
  },
  downloads: {
    minWidth: 180,
    width: 180
  }
};

class MovieDescription extends Component {
  componentWillMount() {
    this.movie_slug = this.props.match.params.movie_slug;
    this.category_slug = this.props.match.params.category_slug;
  }
  componentDidUpdate() {
    if (this.props.data.contents != undefined && !this.state.loaded) {
      this.setState({ loaded: true });
    }
  }
  copyDesc = () => {
    this.setState({ showCopied: true });
  };
  downloadFile = id => {
    new DownloadFile(id, this.movie_slug + ".zip", true);
    this.props.enqueueSnackbar(Strings.startingDownload);
  };
  toCart = id => {
    new CartManager(id, "add_asset", function(result) {}, function() {});
    this.props.enqueueSnackbar(Strings.addedToCart);
  };
  state = {
    loaded: false
  };
  render() {
    var cp = this;
    return (
      <React.Fragment>
        <div style={styles.content}>
          <div style={styles.description}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start"
              }}
            >
              <SectionHeadline>{this.props.title}</SectionHeadline>
              {this.state.loaded && this.props.data.contents.synopsis && (
                <Tooltip title={Strings.copyToClipboard} placement="top">
                  <CopyToClipboard
                    text={cleanText(this.props.data.contents.synopsis.content)}
                    onCopy={() => this.props.enqueueSnackbar(Strings.copiedToClipboard)}
                  >
                    <IconButton style={{ padding: 5, marginTop: 0, marginLeft: 5 }}>
                      <img src={CopyIcon} alt="" />
                    </IconButton>
                  </CopyToClipboard>
                </Tooltip>
              )}
            </div>

            {!this.state.loaded && <TextBlock rows={20} color={Colors.lightGrey} />}

            {this.state.loaded && this.props.data.contents.synopsis && (
              <Typography color="primary" variant="body2">
                {ReactHtmlParser(this.props.data.contents.synopsis.content)}
              </Typography>
            )}
          </div>

          {this.state.loaded && this.props.data.uploads.length > 0 && (
            <div style={styles.downloads}>
              <SectionHeadline>Downloads</SectionHeadline>
              {this.props.data.uploads.map(function(doc, i) {
                return (
                  <React.Fragment key={i}>
                    {doc.assets.map(function(asset, a) {
                      return (
                        <React.Fragment key={a}>
                          {asset.files.map(function(file, f) {
                            return (
                              <Document
                              file={file.file}
                              toCart={() => cp.toCart(file.id)}
                              downloadFile={URLs.MediaURL + file.file}
                              key={f}
                              docType={file.ext}
                              docName={doc.title}
                            />
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

function cleanText(text) {
  var el = $("<div></div>").html(striptags(text));
  var text = el.html();

  return text.split("&nbsp;").join("");
}

export default withRouter(withSnackbar(Radium(MovieDescription)));
