import React, { Component } from "react";
import { TextField, MenuItem } from "@material-ui/core";

import DropDownArrow from "../../img/DropDownArrow.svg";

class DropDown extends Component {
  state = {};
  render() {
    return (
      <TextField
        value={this.props.defaultValue}
        onChange={this.props.onChange}
        select
        label={this.props.label}
        required={this.props.required}
        fullWidth
        error={this.props.error}
        helperText={this.props.helperText}
        SelectProps={{
          IconComponent: () => (
            <img
              style={{
                top: "50%",
                right: 0,
                position: "absolute",
                pointerEvents: "none"
              }}
              src={DropDownArrow}
              alt=""
            />
          )
        }}
      >
        {this.props.list.map(item => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

export default DropDown;
