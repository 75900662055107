import React, { Component } from "react";
import CloseButton from "../Misc/CloseButton";
import { Drawer, Typography, Button, IconButton, Grid } from "@material-ui/core";
import CartManager from "../../extras/CartManager";
import { DeleteIcon, BinIcon, PlayIcon, SmallPlayIcon } from "../Misc/Images";
import ImageLoader from "../Misc/ImageLoader";
import URLs from "../../extras/URLs";
import Colors from "../../extras/Colors";
import { withSnackbar } from "notistack";
import Strings from "../../extras/Strings";
import MediaQuery from "react-responsive";
import Radium, { StyleRoot } from 'radium';

const styles = {
  content: {
    width: 760,
    padding: "30px 50px 50px 50px",
    '@media (max-width: 320px)': {
      width:280,
      padding: "30px 30px 30px 30px",
    },
    '@media (max-width: 370px) and (min-width: 321px)': {
      width: 300,
      padding: "30px 30px 30px 30px",
    },
    '@media (max-width: 480px) and (min-width: 371px)': {
      width: 370,
      padding: "30px 30px 30px 30px",
    },
    '@media (max-width: 767px) and (min-width: 481px)': {
      width: 450,
      padding: "30px 30px 30px 30px",
    }
  },
  cartimage:{
     height: 110 
  },
  container: {
    marginRight: 0
  },
  item: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    paddingBottom: 20
  },
  imageContainer: {
    position: "relative",
    width: 100,
    height: 55,
    '@media (max-width: 500px)': {
      width: 100,
      height: 55,
    },
    marginRight: 20,
    textAlign: "center",
    borderWidth: 1,
    borderColor: Colors.lightGrey,
    borderStyle: "solid",
    overflow: "hidden",
    display: "flex",
    alignItems: "center"
  },
  videoOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    background: "rgba(0, 0, 0, 0.5)"
  },
  centered: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)"
  }
};

class Cart extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.loadData();
    }
  }
  componentDidMount() {
    this.loadData();
  }
  loadData = () => {
    var cp = this;
    // LOAD DATA
    new CartManager(
      null,
      "content",
      function(result) {
        cp.setState({ cart: result.cart });
      },
      function() {}
    );
  };
  downloadCart = () => {
    var cp = this;
    new CartManager(
      null,
      "download",
      function() {
        cp.clearCart();
      },
      function() {}
    );

    this.props.enqueueSnackbar(Strings.startingDownload);
  };
  clearCart = () => {
    var cp = this;
    new CartManager(
      null,
      "clear",
      function() {
        cp.setState({ cart: [] });
      },
      function() {}
    );
  };
  deleteItem = id => {
    var cp = this;
    new CartManager(
      id,
      "remove",
      function(result) {
        cp.setState({ cart: [] });
        cp.setState({ cart: result.cart });
      },
      function() {}
    );
  };
  state = {
    cart: []
  };
  render() {
    var cp = this;
    return (
      <React.Fragment>
        <StyleRoot>
        <Drawer open={this.props.open} anchor="right" onClose={this.props.toggleCart}>
        <StyleRoot>
          <div style={styles.content}>
            <div style={styles.container}>
              <Typography color="primary" variant="h3" style={{ paddingBottom: 30 }}>
                Warenkorb
              </Typography>
              {/*ITEMS*/}
              {this.state.cart.length === 0 && (
                <Typography color="primary" variant="body1">
                  Fügen Sie Inhalte zum Warenkorb hinzu, um diese runterladen zu können.
                </Typography>
              )}

              {this.state.cart.map(function(item, i) {
                return (
                  <div key={i} style={styles.item}>
                    <div style={styles.imageContainer}>
                      <ImageLoader
                        full={item.type === "video"}
                        adaptive
                        style={styles.cartimage}
                        src={URLs.MediaURL + "/" + item.thumb}
                      />
                      {!item.thumb && (
                        <Typography variant="h6" color="primary" style={styles.centered}>
                          {"." + item.ext}
                        </Typography>
                      )}
                      {/* PLAY OVERLAY */}
                      {item.type === "video" && (
                        <div style={styles.videoOverlay}>
                          <img style={styles.centered} src={SmallPlayIcon} alt="" />
                        </div>
                      )}
                    </div>
                    <div style={{ flex: 1, alignItems: "center" }}>
                      <Typography color="primary" variant="body1">
                        {item.movie_title}
                      </Typography>
                      <Typography color="primary" variant="body2">
                        {item.title}
                      </Typography>
                      <Typography color="secondary" variant="body2">
                        {item.freetext2}
                      </Typography>
                    </div>
                    <Typography color="primary" variant="body1" style={{ width: 75, textAlign: "right" }}>
                      {item.type !== "file" ? item.quality : ""}
                    </Typography>
                    <Typography align="right" color="primary" variant="body1" style={{ width: 55, textAlign: "right" }}>
                      {item.ext}
                    </Typography>
                    <IconButton style={{ padding: 5, marginLeft: 20 }} onClick={() => cp.deleteItem(item.asset_id)}>
                      <img src={BinIcon} alt="" />
                    </IconButton>
                    {i < cp.state.cart.length - 1 && (
                      <div
                        style={{
                          position: "absolute",
                          bottom: 10,
                          width: "100%",
                          height: 1,
                          background: Colors.lightGrey,
                          left: 0
                        }}
                      />
                    )}
                  </div>
                );
              })}
              {/*DOWNLOAD BUTTON*/}
              {this.state.cart.length > 0 && (
                <Grid container spacing={16}>
                  <Grid item>
                    <Button variant="outlined" onClick={() => this.downloadCart()}>
                      Dateien herunterladen
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" onClick={() => this.clearCart()}>
                      Warenkorb leeren
                    </Button>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
          </StyleRoot>
        </Drawer>
        {/* CLOSE BUTTON */}
        {this.props.open && (
          <React.Fragment>
            <div
              style={{
                position: "fixed",
                width: 99,
                height: 83,
                top: 1,
                right: 1,
                zIndex: 9999,
                background: "white"
              }}
            />
            <CloseButton onClick={this.props.toggleCart} />
           
          </React.Fragment>
        )}
         </StyleRoot>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Cart);
