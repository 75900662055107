import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Colors from "../../extras/Colors";
import { FormControl, CircularProgress, Typography } from "@material-ui/core";

import { Logo } from "../Misc/Images";
import URLs from "../../extras/URLs";
import { TOKEN, BUSINESS_NAME, USER_NAME } from "../../extras/Keys";
import SimpleReactValidator from "simple-react-validator";
import { withSnackbar } from "notistack";
import { withAxios } from "../Misc/AxiosManager";
import Strings from "../../extras/Strings";
import ResetPassword from "./ResetPassword";
import query from "../Misc/QueryString";
import Log from "../Misc/Log";
import LoadingButton from "../Misc/LoadingButton";
import MediaQuery from "react-responsive";
import Radium, { StyleRoot } from 'radium';


const styles = {
  nav: {
    position: "absolute",
    minWidth: 400,
    maxWidth: 400,
    '@media (max-width: 500px)': {
      minWidth: 321,
      maxWidth: 321
    },
    paddingLeft: 60,
    paddingRight: 60,
    height: "100%",
    minHeight: 625,
    backgroundColor: "white"
  },
  content: {
    marginTop: "5vh"
  },
  logo: {
    width: "100%",
    marginTop: 60,
    marginBottom: 60,
    height: 66
  },
  headline: {},
  subline: {
    marginTop: 10
  },
  textfield: {
    marginTop: 20
  },
  forgottenLink: {
    color: Colors.grey,
    "&:hover": {
      color: Colors.grey
    }
  },
  register: {
    position: "absolute",
    left: 0,
    width: 400,
    backgroundColor: "white",
    bottom: 0,
    fontWeight: 300,
    textAlign: "center",
    paddingBottom: 40,
    marginTop: 10
  },
  registerLink: {
    color: Colors.grey,
    fontWeight: 500,
    "&:hover": {
      color: Colors.grey
    }
  },
  loginButtonWrapper: {
    position: "relative"
  },
  loginButton: {
    position: "relative",
    height: 44
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    color: Colors.grey,
    marginTop: 42,
    marginLeft: -10
  }
};

class ForgotNav extends Component {
  componentWillMount() {
    this.validator = new SimpleReactValidator({
      validators: {
        custom_email: {
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
          }
        }
      },
      messages: {
        email: "E-Mail ist nicht gültig",
        custom_email: "E-Mail ist nicht gültig",
        required: "Dieses Feld muss ausgefühlt werden",
        accepted: "Sie müssen den Bedingungen zustimmen",
        default: "Eingabe nicht gültig"
      }
    });
  }
  componentDidMount() {
    // CHECK TOKEN
    const token = query.getParams().token;
    if (token !== undefined) {
      this.setState({ forgot: true });
      this.setState({ token: token });
    }

    // TODO - CHECK ALREADY LOGGED IN
  }
  CheckLogin = () => {
    var cp = this;

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      cp.setState({ loading: false });
      cp.props.enqueueSnackbar(Strings.submitError, {
        variant: "error"
      });
      this.forceUpdate();
      return;
    }

    this.setState({ loading: true });

    this.props.axios
      .post(URLs.LoginURL, { email: cp.state.email, password: cp.state.password, remember_me: true })
      .then(response => {
        Log(response.data);

        const old = response.data.account === "old";

        if (old) {
          this.setState({ old: true });
        } else {
          const status = response.data.status;
          const account = response.data.account;

          if (status === "success" && account !== "deactivated") {
            const data = response.data.data;
            var token = data.access_token;
            if (token !== undefined) {
              // Save Token
              localStorage.setItem(TOKEN, token);

              // Save User Info
              localStorage.setItem(BUSINESS_NAME, data.user.kino_firma);
              localStorage.setItem(USER_NAME, data.user.vorname + " " + data.user.name);

              // Show Start
              window.location.assign("/");
            } else {
              // Show Error
              showError(cp);
            }
          } else {
            showError(cp);
          }
        }

        cp.setState({ loading: false });
      })
      .catch(error => {
        showError(cp);
        Log(error);
      });
  };
  handleKeyPress = e => {
    if (e.which === 13) {
      this.CheckLogin();
    }
  };
  state = {
    email: "",
    password: "",
    loading: false,
    forgot: true,
    token: undefined,
    old: false
  };
  render() {
    const emailError = this.validator.message("Email", this.state.email, "custom_email|required");
    const passwordError = this.validator.message("Password", this.state.email, "required");
    return (
      <React.Fragment>
        <StyleRoot>
        <nav style={styles.nav}>
          <img style={styles.logo} src={Logo} alt="" />

          <div style={styles.content}>
            <Typography color="primary" variant="h3">
              Member Login
            </Typography>
            <Typography color="primary" variant="body2" style={styles.subline}>
              Melden Sie sich an, um auf unseren Marketingserver zu gelangen.
            </Typography>

            <form>
              <TextField
                disabled={this.state.loading}
                error={emailError}
                helperText={emailError}
                type="text"
                label="E-Mail"
                maxLength="100"
                style={styles.textfield}
                fullWidth
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value, error: false })}
                onKeyPress={this.handleKeyPress}
                autoFocus
              />

              <FormControl fullWidth>
                <TextField
                  disabled={this.state.loading}
                  error={passwordError}
                  helperText={passwordError}
                  type="password"
                  label="Passwort"
                  autoComplete="off"
                  style={styles.textfield}
                  fullWidth
                  value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value, error: false })}
                  onKeyPress={this.handleKeyPress}
                />
                <Typography
                  color="primary"
                  variant="body2"
                  style={{ paddingTop: 10 }}
                  onClick={() => {
                    this.setState({ forgot: true });
                  }}
                >
                  <a style={styles.forgottenLink} href="#">
                    Passwort vergessen?
                  </a>
                </Typography>
              </FormControl>

              <LoadingButton
                style={styles.loginButton}
                disabled={this.state.loading}
                onClick={this.CheckLogin}
                variant="outlined"
                fullWidth
                loading={this.state.loading}
              >
                {!this.state.loading && "Login"}
              </LoadingButton>
            </form>

            <div style={styles.register}>
              <Typography color="primary" variant="body2">
                Kein Konto?{" "}
                <a href="#" onClick={this.props.handler} style={styles.registerLink}>
                  Hier Zugang beantragen
                </a>
              </Typography>
            </div>
          </div>
        </nav>
        {/* RESET PASSWORT */}
        <ResetPassword
          open={this.state.forgot || this.state.old}
          token={this.state.token}
          old={this.state.old}
          email={this.state.email}
          onClose={() => {
            this.setState({ forgot: false });
            this.setState({ old: false });
            this.setState({ token: undefined });
          }}
        />
        </StyleRoot>
      </React.Fragment>
    );
  }
}

function showError(cp) {
  cp.setState({ loading: false });
  cp.props.enqueueSnackbar(Strings.loginError, {
    variant: "error"
  });
}

export default withAxios(withSnackbar(Radium(ForgotNav)));
