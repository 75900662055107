const Colors = {
  darkGrey: "#8D8D8D",
  grey: "#9F9F9F",
  lightGrey: "#BBBBBB",
  lightLightGrey: "#F1F1F1",
  blue: "#048CE0",
  darkBlue: "#047DC8",
  fontGrey: "#8A8A8A",
  fontLightGrey: "#CACACA",
  line: "#E2E2E2"
};

export default Colors;
