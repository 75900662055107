import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { moviePosterSmallPH, moviePosterBigPH } from "../Misc/Placeholder";
import SectionHeadline from "../Misc/SectionHeadline";

import { IconButton, GridList, ButtonBase } from "@material-ui/core";
import { InlineIcon, WrapIcon, ArrowLeft, ArrowRight } from "../Misc/Images";

import MoviePoster from "../Misc/MoviePoster";
import ReactPlaceholder from "react-placeholder/lib";
import $ from "jquery";
import { Link } from "react-router-dom";

import { TweenMax } from "gsap";
import Strings from "../../extras/Strings";
import MediaQuery from "react-responsive";
import Radium, { StyleRoot } from 'radium';
import ls from 'local-storage';

const styles = {
  toggleIconsContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: -9
  },
  toggleIcons: {
    padding: 5,
    marginTop: -18
  }
};

class MovieList extends Component {
  componentDidMount() {
    var wrap = localStorage.getItem(this.props.title);
    if (wrap === undefined || wrap === null) {
      wrap = "nowrap";
    }
    this.setState({ moviesWrap: wrap });
    //this.setState({tokenGetIt: ls.get('token')});
    // REFS
    this.movieList = findDOMNode(this.refs.movieList);
    this.leftArrow = findDOMNode(this.refs.leftArrow);
    this.rightArrow = findDOMNode(this.refs.rightArrow);

    window.addEventListener("resize", this.handleResize);
    this.movieList.addEventListener("scroll", this.handleScroll);
    this.handleResize();
    if($(document).innerWidth() >= 768){
      this.setState({ mediaqueryWidth: $(document).innerWidth() - 320 }); 
    }
    else{
      this.setState({ mediaqueryWidth: $(document).innerWidth() - 93 }); 
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this.movieList.removeEventListener("scroll", this.handleScroll);
  }
  handleResize = () => {
    //this.setState({ documentWidth: window.innerWidth });
    this.setState({ documentWidth: $(document).innerWidth() });
    if($(document).innerWidth() >= 768){
      this.setState({ mediaqueryWidth: $(document).innerWidth() - 320 }); 
    }
    else{
      this.setState({ mediaqueryWidth: $(document).innerWidth() - 93 }); 
    }
    
  };
  handleWrap = props => {
    // RESET SCROLL
    TweenMax.set(this.movieList, { scrollLeft: 0 });
    // SET STATE
    this.setState({ [props.key]: props.value });
    // SAVE STATE
    localStorage.setItem(this.props.title, props.value);

    setTimeout(() => {
      this.handleScroll();
      this.props.onToggleWrap && this.props.onToggleWrap();
    }, 100);
  };

  handleScroll = () => {
    var contentWidth = $(this.movieList).get(0).scrollWidth;
    var width = $(this.movieList).innerWidth();
    var scroll = $(this.movieList).scrollLeft();

    if (scroll >= contentWidth - width) {
      $(this.rightArrow).css("opacity", 0);
      $(this.rightArrow).css("pointer-events", "none");
    } else {
      $(this.rightArrow).css("opacity", 1);
      $(this.rightArrow).css("pointer-events", "auto");
    }

    if (scroll > 0) {
      $(this.leftArrow).css("opacity", 1);
      $(this.leftArrow).css("pointer-events", "auto");
    } else {
      $(this.leftArrow).css("opacity", 0);
      $(this.leftArrow).css("pointer-events", "none");
    }
  };

  scrollLeft = () => {
    TweenMax.to(this.movieList, 0.5, { scrollLeft: "-=180" });
  };
  scrollRight = () => {
    TweenMax.to(this.movieList, 0.5, { scrollLeft: "+=180" });
  };
  state = {
    moviesWrap: "wrap",
    documentWidth: 0,
    mediaqueryWidth: 0,
    placeholder: [0, 1, 2, 3]
  };
  render() {
    const cp = this;
    this.props.loaded && this.handleScroll();





    return (
      <StyleRoot>
      <React.Fragment>
        <div style={{ display: "flex" }}>
          {((this.props.loaded && this.props.movies.length > 0) || !this.props.loaded) && (
            <Link to={this.props.href}>
              <SectionHeadline>{this.props.title}</SectionHeadline>
            </Link>
          )}
          {this.props.loaded && this.props.movies.length > 0 && this.props.toggleWrap && (
            <div style={styles.toggleIconsContainer}>
              <div style={{ opacity: this.state.moviesWrap !== "wrap" ? 1 : 0.5 }}>
                <IconButton
                  disabled={this.state.moviesWrap !== "wrap"}
                  onClick={() => this.handleWrap({ key: "moviesWrap", value: "nowrap" })}
                  style={styles.toggleIcons}
                >
                  <img src={InlineIcon} alt="" />
                </IconButton>
              </div>
              <div style={{ opacity: this.state.moviesWrap === "wrap" ? 1 : 0.5 }}>
                <IconButton
                  disabled={this.state.moviesWrap === "wrap"}
                  onClick={() => this.handleWrap({ key: "moviesWrap", value: "wrap" })}
                  style={styles.toggleIcons}
                >
                  <img src={WrapIcon} alt="" />
                </IconButton>
              </div>
            </div>
          )}
        </div>

        {/*LEFT BUTTON ///////////////////////////////////*/}
        <ButtonBase
          component="div"
          ref="leftArrow"
          onClick={this.scrollLeft}
          style={{
            position: "absolute",
            width: 50,
            height: this.props.size === "small" ? 195 : 237,
            left: -50,
            marginTop: -2,
            visibility: this.props.loaded ? "visible" : "collapse"
          }}
        >
          <img src={ArrowLeft} alt="" />
        </ButtonBase>
        {/*RIGHT BUTTON ///////////////////////////////////*/}
        <ButtonBase
          component="div"
          ref="rightArrow"
          onClick={this.scrollRight}
          style={{
            position: "absolute",
            width: 50,
            height: this.props.size === "small" ? 195 : 237,
            right: 0,
            marginTop: -2,
            visibility: this.props.loaded ? "visible" : "collapse"
          }}
        >
          <img src={ArrowRight} alt="" />
        </ButtonBase>

        <GridList
          ref="movieList"
          style={{
            scrollbarWidth: "none",
            //width: this.state.documentWidth - 320 - (this.state.documentWidth < 1150 ? 0 : 280),
            width: this.state.mediaqueryWidth - (this.state.documentWidth < 1150 ? 0 : 280),
            //width: "calc(100% - 92px)",
            flexWrap: this.props.toggleWrap ? this.state.moviesWrap : "wrap"
          }}
        >
          {!this.props.loaded &&
            this.state.placeholder.map(function(i) {
              return (
                <ReactPlaceholder
                  key={i}
                  ready={false}
                  children={""}
                  showLoadingAnimation
                  customPlaceholder={cp.props.size === "small" ? moviePosterSmallPH : moviePosterBigPH}
                />
              );
            })}
            {/*  
          {this.props.reverse === "true" ? (
            <>
              {this.props.movies.reverse().map(function(movie, i) {
                return (
                  <MoviePoster
                    key={i}
                    title={movie.title}
                    subtitle={movie.startdate}
                    href={"/" + Strings.movie + "/" + movie.slug + "/" + Strings.overview}
                    image={movie.thumb}
                    size={cp.props.size}
                  />
                );
              })}
            </>
             ) : (
              <>
              {this.props.loaded && this.props.movies.map(function(movie, i) {
                return (
                  <MoviePoster
                    key={i}
                    title={movie.title}
                    subtitle={movie.startdate}
                    href={"/" + Strings.movie + "/" + movie.slug + "/" + Strings.overview}
                    image={movie.thumb}
                    size={cp.props.size}
                  />
                );
              })}
            
              </>
              
              )}
              
              
          )}*/}
          {this.props.loaded && this.props.movies.map(function(movie, i) {
            console.log(movie.title + ' :' +movie.id);
            let currentToken = ls.get('token');
            let subTitle = movie.startdate;
            if(movie.id === 194) {
              subTitle = 'Jetzt reinklicken!';
            }
            return (
              <MoviePoster
                key={i}
                title= {movie.id === 194 ? 'UNIVERSAL.FILM.NEWS' : movie.title}
                subtitle= {subTitle}
                href={movie.id === 194 ? '//universal-filmnews.de/digital/intro?ext_token=' + currentToken : "/" + Strings.movie + "/" + movie.slug + "/" + Strings.overview}
                image={movie.thumb}
                size={cp.props.size}
                target={movie.id === 194 ? "_blank" : ""}
              />
            );
          })}
        </GridList>
      </React.Fragment>
      </StyleRoot>
    );
  }
}

export default Radium(MovieList);
