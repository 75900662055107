import React, { Component } from "react";
import Colors from "../../extras/Colors";
import { Typography } from "@material-ui/core";
import Radium, { StyleRoot } from 'radium';
import MediaQuery from "react-responsive";

const styles = {
  container: {
    zIndex: 2,
    position: "fixed",
    width: "calc(100% - 220px)",
    height: 100,
    marginLeft: 220,
    bottom: 0,
    background: "white",
    borderColor: Colors.lightLightGrey,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 1,
    borderRightWidth: 0,
    borderStyle: "solid",
    '@media (max-width: 767px)': {
      marginLeft:0,
      width: "100%"
    }
  },
  list: {
    display: "flex",
    listStyle: "none",
    justifyContent: "center",
    width: "100%",
    padding: 20
  },
  item: {
    margin: 20
  },
  link: {
    color: Colors.grey,
    "&:hover": {
      color: Colors.grey
    }
  }
};

class Footer extends Component {
  state = {};
  render() {
    return (
      <StyleRoot>
      <MediaQuery minWidth={768}>
        <footer style={{ ...styles.container, ...this.props.style }}>
          <ul style={styles.list}>
            <li style={styles.item}>
              <a href="mailto:info@universal-marketingserver.de" target="_blank">
                <Typography style={{ ...styles.link, color: this.props.color }} color="primary" variant="body2">
                  Kontakt
                </Typography>
              </a>
            </li>
            <li style={styles.item}>
              <a href="/legal">
                <Typography style={{ ...styles.link, color: this.props.color }} color="primary" variant="body2">
                  Impressum
                </Typography>
              </a>
            </li>
            <li style={styles.item}>
              <a href="https://www.nbcuniversal.com/privacy-policies/privacy-german" target="_blank">
                <Typography style={{ ...styles.link, color: this.props.color }} color="primary" variant="body2">
                  Nutzungsbedingungen
                </Typography>
              </a>
            </li>
            <li style={styles.item}>
              <a href="https://www.nbcuniversal.com/privacy-policies/privacy-german" target="_blank">
                <Typography style={{ ...styles.link, color: this.props.color }} color="primary" variant="body2">
                  Datenschutz
                </Typography>
              </a>
            </li>
          </ul>
        </footer>
        </MediaQuery>
      </StyleRoot>
      
    );
  }
}

export default Footer;
