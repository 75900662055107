import React, { Component } from "react";
import { DocImage, SaveIcon, DownloadsIcon, OverviewIcon } from "../Misc/Images";
import Strings from "../../extras/Strings";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import URLs from "../../extras/URLs";

const styles = {
  docContainer: {
    marginBottom: 20
  },
  doc: {
    position: "relative",
    display: "flex"
  },
  docImage: {
    position: "relative",
    width: 80,
    minWidth: 80
  },
  docType: {
    position: "absolute",
    width: "100%",
    top: 70,
    textAlign: "center"
  },
  icons: {
    width: 40,
    marginTop: -9,
    marginLeft: 5
  },
  icon: {
    padding: 5
  },
  name: {
    fontSize: 12,
    marginTop: 5
  }
};

class Document extends Component {
  state = {};
  render() {
    return (
      <div style={{ ...styles.docContainer, ...this.props.style }}>
        <div style={styles.doc}>
          <div style={styles.docImage}>
            <img src={DocImage} alt="" />
            <Typography variant="h6" color="primary" style={styles.docType}>
              {"." + this.props.docType}
            </Typography>
          </div>
          <div style={styles.icons}>
            {this.props.downloadFile && (
              <Tooltip title={Strings.download} placement="left">
                <IconButton
                  style={styles.icon}
                  component="a"
                  href={typeof this.props.downloadFile == String(undefined)  ? 'no string' : this.props.downloadFile.startsWith("https://") || this.props.downloadFile.startsWith("http://") ? this.props.downloadFile : URLs.MediaURL + this.props.downloadFile}
                  downloadFile={id => this.props.downloadFile(id, true)}
                  download
                  target="_blank"
                >
                  <img src={SaveIcon} alt="" />
                </IconButton>
              </Tooltip>
            )}
            {this.props.toCart && (
              <Tooltip title={Strings.toCart} placement="left">
                <IconButton style={styles.icon} onClick={() => this.props.toCart()}>
                  <img src={DownloadsIcon} alt="" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        <Typography style={styles.name} color="primary">
          {this.props.docName}
        </Typography>
      </div>
    );
  }
}

export default Document;
