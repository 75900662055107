import React, { Component } from "react";
import { List, ListItem, ListItemText, Collapse, Fade } from "@material-ui/core";

import Colors from "../../extras/Colors";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import URLs from "../../extras/URLs";
import { categorySlug, movieSlug } from "../../extras/Helper";
import Radium from "radium";
import { withAxios, DefaultErrorHandling } from "../Misc/AxiosManager";
import Strings from "../../extras/Strings";
import { withRouter } from "react-router";
import { DropdownIcon } from "../Misc/Images";
import { relative } from "path";
import $ from "jquery";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiMenuItem: {
      root: {
        fontSize: 14,
        color: "white",
        paddingTop: 0,
        paddingBottom: 0,
        boxShadow: "none",
        backgroundColor: Colors.grey
      },
      gutters: {  
        paddingLeft: 24, 
        paddingRight: 24
      }
    },
    MuiListItem: {
      root: {
        paddingLeft: 24, 
        paddingRight: 24,
        minHeight: 50,
        backgroundColor: Colors.lightGrey,
        "&$selected": {
          backgroundColor: Colors.blue
        },
        "&&&&:hover&$selected": {
          backgroundColor: Colors.darkBlue
        },
        "&&&&:hover": {
          backgroundColor: Colors.darkGrey
        },
        "&&&&$selected": {
          backgroundColor: Colors.blue
        },
      },
      gutters: {  
        paddingLeft: 24, 
        paddingRight: 24
      }
    },
    MuiListItemText: {
      primary: {
        color: "white",
        fontSize: 14,
        width: 125
      }
    }
  }
});

const styles = {
  icon: {
    width: 20,
    minWidth: 20
  },
  gutters: {  
    paddingLeft: 24, 
    paddingRight: 24
  },
  secondary:{
    paddingLeft: 24, 
    paddingRight: 24
  },
  movienavmobile: {
    position: "relative",
    top: 219,
    minHeight: 933,
    overflow: "scroll",
    minHeight: 947
  }
};

class MovieNavMobile extends Component {
  componentWillMount() {
    this.movie_slug = this.props.match.params.movie_slug;
    this.category_slug = this.props.match.params.category_slug;

    this.props.moviePage.setState({ currentPage: this.category_slug });
    this.props.movieContent.setState({ currentPage: this.category_slug });
  }
  componentDidMount() {
    var cp = this;
    this.props.axios
      .post(URLs.MovieMenuURL, { category_slug: "moviemenu", movie_slug: this.movie_slug })
      .then(response => {
        cp.setState({ menu: response.data.data.menu.submenu });
        cp.setState({ menuPos: getMenuPos(response.data.data.menu.submenu, this.category_slug) });
      })
      .catch(error => {
        DefaultErrorHandling(error);
      });

      window.addEventListener("resize", this.handleResize);
      this.handleResize();
      $("#movie-nav-mobile").css("height", $(document).innerHeight() + 'px'); 
      $("#movie-nav-mobile").css("min-height", $(document).innerHeight() + 'px');
      $("#movie-nav-mobile").css("width", $(document).innerWidth() + 'px');
  }
  handleClick = pos => {
    if (pos === this.state.menuPos) {
      pos = -1;
    }
    this.setState({ menuPos: pos });
  };
  handleResize = () => {
    this.setState({ documentHeight: $(document).innerHeight() });
    $("#movie-nav-mobile").css("min-height", $(document).innerHeight() + 'px'); 
    //$("#movie-nav-mobile ul").css("min-height", $(document).innerHeight() + 'px');
    $("#movie-nav-mobile").css("height", $(document).innerHeight() + 'px'); 
    //$("#movie-nav-mobile ul").css("height", $(document).innerHeight() + 'px');
    $("#movie-nav-mobile").css("width", $(document).innerWidth() + 'px');
  };
  handleSubClick = (slug,slugname) => {
    this.props.moviePage.setState({ showMobileNav: false });
    this.props.moviePage.setState({ currentPage: slug });
    this.props.moviePage.setState({ currentPageTitle: slugname });
    this.props.history.push("/movie/" + this.movie_slug + "/" + slug);
    //if($(document).innerWidth() <= 768){
      /*if(slug === "kurzinhalt"){
        window.location.reload();
      }
      if(slug === "uebersicht"){
        window.location.reload();
      }
      */
     window.location.reload();
   //}
    
    
  };
  state = {
    loaded: false,
    menu: [],
    menuPos: 0
  };
  render() {
    const cp = this;
    return (
      <div id="movie-nav-mobile" style={styles.movienavmobile}> 
      <Fade in={this.state.menu.length > 0}>
        <List style={this.props.style}>
          {this.state.menu.map(function(menuItem, i) {
            return (
              <React.Fragment key={menuItem.id}>
                <ListItem
                  style={styles.secondary}
                  button
                  onClick={() => cp.handleClick(i)}
                  selected={cp.state.menuPos === i}
                >
                  <img style={styles.icon} src={URLs.MediaURL + menuItem.icon} alt="" />
                  <ListItemText primary={menuItem.name} />
                  <img
                    style={{
                      transition: "transform 0.2s",
                      transform: "rotate(" + (cp.state.menuPos === i ? -180 : 0) + "deg)"
                    }}
                    src={DropdownIcon}
                    alt=""  
                  />
                </ListItem>
                {/* SUBITEMS */}
                <MuiThemeProvider theme={theme}>
                  <Collapse in={cp.state.menuPos === i}>
                    <List disablePadding>
                      {menuItem.submenu.map(function(subItem, s) {
                        return (
                          <ListItem
                            key={subItem.id}
                            style={styles.secondary}
                            button
                            selected={subItem.slug === cp.props.currentPage}
                            onClick={() => cp.handleSubClick(subItem.slug,subItem.name)}
                          >
                            <img src={URLs.MediaURL + subItem.icon} alt="" style={[styles.icon, { marginLeft: 20 }]} />
                            <ListItemText primary={subItem.name} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </MuiThemeProvider>
              </React.Fragment>
            );
          })}
        </List>
      </Fade>
      </div>
    );
  }
}

function getMenuPos(menu, category_slug) {
  var tempPos = 0;
  // Find menuPos from category
  for (var i = 0; i < menu.length; i++) {
    for (var y = 0; y < menu[i].submenu.length; y++) {
      if (menu[i].submenu[y].slug === category_slug) {
        return tempPos;
      }
    }
    tempPos++;
  }
  return tempPos;
}

export default withRouter(withAxios(Radium(MovieNavMobile)));
