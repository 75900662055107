import React, { Component } from "react";
import Colors from "../../extras/Colors";
import AssetButtons from "./AssetButtons";
import { Typography } from "@material-ui/core";
import Radium, { StyleRoot } from 'radium';
import MediaQuery from "react-responsive";

const styles = {
  container: {
    display: "flex",
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: "center"
  },
  line: {
    position: "absolute",
    height: 1,
    width: "calc(100% - 40px)",
    background: Colors.line
  },
  trailercontainer:{
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      //width: !hasVideo ? 265 : 400,
      borderLeftWidth: 1,
      borderLeftColor: Colors.line,
      borderLeftStyle: "solid",
      '@media (max-width: 767px)': {
        width: 160
      },
  }
};

class ImageAsset extends Component {
  state = {};
  render() {
    const hasVideo = this.props.hasVideo;
    return (
      <StyleRoot>
      <div {...this.props}>
        <div style={styles.line} />
        <div style={styles.container}>
          <Typography color="primary" variant="body2" style={{ width: hasVideo ? 100 : 70 }}>
            {this.props.asset.color ? this.props.asset.color : ""}
          </Typography>
          {!hasVideo && (
            <Typography color="primary" variant="body2" style={{ width: 40 }}>
              {this.props.asset.quality ? this.props.asset.quality : ""}
            </Typography>
          )}
          <MediaQuery minWidth={768}>
          {!hasVideo && (
            <Typography color="primary" variant="body2" style={{ width: 100 }}>
              {this.props.asset.resolution_print ? this.props.asset.resolution_print.toLowerCase() + " cm" : ""}
            </Typography>
          )}
          
          <Typography color="primary" variant="body2" style={{ width: 120 }}>
            {this.props.asset.resolution ? this.props.asset.resolution + " px" : ""}
          </Typography>
          {!hasVideo && (
            <Typography color="primary" variant="body2" style={{ width: 60 }}>
              {this.props.asset.dpi ? this.props.asset.dpi + " dpi" : ""}
            </Typography>
          )}
          </MediaQuery>
          {this.props.hasVideo}

          <div
            style={styles.trailercontainer}
          >
            {this.props.asset.files.map(function(file, i) {
              return (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    alignItems: "top",
                    paddingLeft: 20
                  }}
                >
                  <Typography
                    color="primary"
                    variant="body1"
                    style={{ width: 60, paddingRight: 5, textAlign: "right" }}
                  >
                    {file.ext}
                  </Typography>
                  <AssetButtons style={{ marginRight: -8, height: 20 }} id={file.id} file={file.file} isAsset />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      </StyleRoot>
    );
  }
}

export default ImageAsset;
