import React, { Component } from "react";
import Colors from "../../extras/Colors";
import { ButtonBase, GridListTile, Typography, IconButton, Tooltip } from "@material-ui/core";

import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/de";
import moment from "moment/moment.js";

import ImageLoader from "./ImageLoader";
import URLs from "../../extras/URLs";

import { Link } from "react-router-dom";
import Radium from "radium";
import { PosterFavoriteActiveIcon, PosterFavoriteIcon, UpdatesIcon } from "./Images";
import { withSnackbar } from "notistack";
import Strings from "../../extras/Strings";
import { findDOMNode } from "react-dom";
import { TweenMax, Power2 } from "gsap";
import $ from "jquery";
import { withAxios, DefaultErrorHandling } from "./AxiosManager";
import { red } from "@material-ui/core/colors";

const big = {
  width: 170,
  height: 238
};

const small = {
  width: 140,
  height: 196
};

function posterWidth(size) {
  switch (size) {
    case "small":
      return small.width;
  }
  return big.width;
}

function posterHeight(size) {
  switch (size) {
    case "small":
      return small.height;
  }
  return big.height;
}

function getStyles(size, cp) {
  const styles = {
    container: {
      position: "relative",
      minWidth: posterWidth(size),
      width: posterWidth(size),
      overflow: "hidden",
      marginRight: 10,
      marginBottom: 20,
      float: "left",
      color: "white"
    },
    poster: {
      width: posterWidth(size),
      height: posterHeight(size),
      boxSizing: "border-box",
      border: 1,
      borderStyle: "solid",
      borderColor: cp.props.search ? "white" : Colors.lightGrey,
      background: Colors.lightGrey,
      overflow: "hidden"
    },
    title: {
      width: "100%",
      marginTop: 10,
      textTransform: "uppercase"
    },
    subtitle: {}
  };

  return styles;
}

class MoviePoster extends Component {
  componentDidMount() {
    this.title = findDOMNode(this.refs.title);
  }
  toggleFavorite = () => {
    var cp = this;
    this.props.axios
      .post(URLs.FavoriteURL, { movie_slug: cp.props.movieSlug })
      .then(response => {
        cp.setState({ isFavorite: !cp.state.isFavorite });
        cp.props.enqueueSnackbar(cp.state.isFavorite ? Strings.addedFavorite : Strings.removedFavorite);
      })
      .catch(error => {});
  };
  overTitle = () => {
    // GET TEXT WIDTH
    //$(this.title).css("text-overflow", "clip");
    var textWidth = $(this.title).textWidth();

    var parentWidth = $(this.title)
      .parent()
      .innerWidth();

    if (textWidth < parentWidth) return;

    // ANIMATE
    TweenMax.to(this.title, textWidth / 80, {
      delay: 0.2,
      marginLeft: -(textWidth - parentWidth),
      width: (parentWidth / 100) * textWidth + "%",
      ease: Power2.easeInOut
    });
  };
  outTitle = () => {
    var textWidth = $(this.title).textWidth();
    TweenMax.to(this.title, textWidth / 100, { marginLeft: 0, width: "100%", overwrite: 1, ease: Power2.easeInOut });
    //$(this.title).css("text-overflow", "ellipsis");
  };
  state = {
    isFavorite: this.props.favorite
  };
  render() {
    const inCinemasFilter = d => {
        
        if (d === "Thu Jan 01 1970 01:00:00 GMT+0100"){
          return "tba";
        }
        else{
          if(d.indexOf("GMT+") !== 25 && d === 'not'){
            return "Jetzt";
          }
          else{
            var movieDate = Date.parse(d);
            if (new Date() >= movieDate) {
              return "Jetzt im Kino";
            }
            return moment(d).format("DD. MMMM YYYY");
           
          }
         
        } 


    };
    const updates = cleanUpdates(this);
    const hasUpdates = updates.length > 0;
    return (
      <GridListTile
        onMouseOver={() => this.overTitle()}
        onMouseOut={() => this.outTitle()}
        style={getStyles(this.props.size, this).container}
      >
        {/* CLICK & IMAGE ////////////////////////////////// */}
        <Link
          style={{ color: "white" }}
          onClick={() => {
            // TODO - FIND BETTER SOLUTION FOR THIS
            if (this.props.search) window.location.assign = this.props.href;
          }}
          to={this.props.href}
          target={this.props.target}
        >
          <ButtonBase>
            <div style={getStyles(this.props.size, this).poster}>
              <ImageLoader showDefault poster src={URLs.MediaURL + this.props.image} style={{ width: "100%" }} />
            </div>
          </ButtonBase>
        </Link>
        {/*  FAVORITE OVERLAY /////////////////////////////// */}
        {this.props.favorite && (
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: posterHeight(this.props.size),
              background: "transparent",
              borderWidth: 1,
              borderStyle: "solid",
              boxSizing: "border-box",
              borderColor: hasUpdates ? Colors.blue : Colors.lightGrey,
              ":hover div": {
                opacity: 1
              }
            }}
          >
            <div
              className="white"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                left: 0,
                top: 0,
                background: "rgba(255,255,255,0.95)",
                transition: "opacity 0.2s",
                opacity: 0,
                ":hover": {
                  opacity: 1
                }
              }}
            >
              <Link
                to={this.props.href}
                style={{ position: "absolute", width: "100%", height: "100%", left: 0, top: 0 }}
              />
              <Tooltip title={this.state.isFavorite ? Strings.removeFavorite : Strings.addFavorite} placement="top">
                <IconButton
                  onClick={() => this.toggleFavorite()}
                  style={{ position: "absolute", right: 0, bottom: 0, padding: 5, margin: 5 }}
                >
                  <img src={this.state.isFavorite ? PosterFavoriteActiveIcon : PosterFavoriteIcon} alt="" />
                </IconButton>
              </Tooltip>
            </div>
            {/* UPDATES INDICATOR //////////////////////////////// */}
            {hasUpdates && (
              <Tooltip title={updatesTooltip(updates)} placement="left" interactive={false}>
                <div
                  style={{
                    //position: "absolute",
                    width: 30,
                    height: 30,
                    background: Colors.blue,
                    right: 10,
                    top: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    pointerEvents: "fill"
                  }}
                >
                  <img src={UpdatesIcon} alt="" />
                </div>
              </Tooltip>
            )}
          </div>
        )}
        {/* TEXT /////////////////////////////////////////////// */}
        <Typography
          ref="title"
          noWrap
          paragraph={false}
          color={this.props.search ? "inherit" : "primary"}
          variant="body1"
          style={getStyles(this.props.size, this).title}
        >
          {this.props.title}
        </Typography>

        {!this.props.search && this.props.subtitle !== 'not' && this.props.subtitle !== 'Jetzt reinklicken!' &&  this.props.subtitle !== 'Bald im Kino' &&(
          <React.Fragment>
            <Typography
              noWrap
              paragraph={false}
              color="primary"
              variant="body2"
              style={getStyles(this.props.size, this).subtitle}
            >
              <Moment filter={inCinemasFilter} unix>
                {this.props.subtitle}
              </Moment>
            </Typography>
          </React.Fragment>
        )}

        {!this.props.search && (this.props.subtitle === 'Jetzt reinklicken!' || this.props.subtitle === 'Bald im Kino') &&(
          <React.Fragment>
            <Typography
              noWrap
              paragraph={false}
              color="primary"
              variant="body2"
              style={getStyles(this.props.size, this).subtitle}
            >
                {this.props.subtitle}
            </Typography>
          </React.Fragment>
        )}
      </GridListTile>
    );
  }
}

function cleanUpdates(cp) {
  var lastCall = moment.unix(cp.props.lastCall);

  var updates = cp.props.updates;
  var cleanUpdates = [];

  if (!updates) return [];

  for (var i = 0; i < updates.length; i++) {
    var update = updates[i];
    var created = moment.unix(update.updated_at);

    if (created > lastCall) {
      if (!cleanUpdates.includes(update.category_name)) {
        cleanUpdates.push(update.category_name);
      }
    }
  }

  return cleanUpdates;
}

function updatesTooltip(updates) {
  return (
    <div style={{ lineHeight: 1.6 }}>
      <b>Neue Updates:</b>
      <br />
      {updates.map(function(update, i) {
        return (
          <React.Fragment key={i}>
            <React.Fragment>
              {update}
              <br />
            </React.Fragment>
          </React.Fragment>
        );
      })}
    </div>
  );
}

$.fn.textWidth = function() {
  var contents = this.contents(),
    wrapper = '<span style="display: inline-block;" />',
    width = "";

  contents.wrapAll(wrapper);
  width = contents.parent().width(); // parent is now the wrapper
  contents.unwrap();
  return width;
};

export default withAxios(withSnackbar(Radium(MoviePoster)));
