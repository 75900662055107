import React, { Component } from "react";
import PageContent from "../Misc/PageContent";
import SearchBar from "../Topbar/SearchBar";
import SectionHeadline from "../Misc/SectionHeadline";
import Colors from "../../extras/Colors";
import { GridList, Typography } from "@material-ui/core";
import MoviePoster from "../Misc/MoviePoster";
import ReactPlaceholder from "react-placeholder/lib";
import { moviePosterBigPH } from "../Misc/Placeholder";
import { withAxios, DefaultErrorHandling } from "../Misc/AxiosManager";
import Strings from "../../extras/Strings";
import URLs from "../../extras/URLs";

const styles = {
  content: {
    padding: "115px 50px 30px 50px",
    minHeight: "100%",
    flex: 1,
    color: Colors.grey,
    '@media (max-width: 320px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 370px) and (min-width: 321px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 480px) and (min-width: 371px)': {
      padding: "195px 50px 30px 50px"
    },
    '@media (max-width: 767px) and (min-width: 481px)': {
      padding: "195px 50px 30px 50px"
    },
    paddingBottom: 100,
  }
};

class FavoritesContent extends Component {
  componentDidMount() {
    var cp = this;

    // LOAD DATA
    this.props.axios
      .post(URLs.FavoritesPageURL)
      .then(response => {
        cp.setState({ movies: response.data.data.favorites });
        cp.setState({ loaded: true });
      })
      .catch(error => {
        DefaultErrorHandling(error);
      });
  }
  state = {
    loaded: false,
    movies: []
  };
  render() {
    return (
      <PageContent>
        <SearchBar />
        <div style={styles.content}>
          <SectionHeadline>Favoriten</SectionHeadline>
          {this.state.loaded && this.state.movies.length === 0 && (
            <Typography color="primary" variant="body2" style={{ maxWidth: 600 }}>
              {Strings.noFavorites}
            </Typography>
          )}
          <GridList>
            {/* PLACEHOLDER CONTENT ///////////////////////////*/}
            {!this.state.loaded && contentPh()}
            {/* CONTENT */}
            {this.state.loaded &&
              sortByRelease(this.state.movies).map(function(movie, i) {
                return (
                  <MoviePoster
                    key={i}
                    favorite
                    movieSlug={movie.slug}
                    key={movie.id}
                    title={movie.title}
                    subtitle={movie.startdate}
                    href={"/" + Strings.movie + "/" + movie.slug + "/" + Strings.overview}
                    image={movie.thumb}
                    updates={movie.latest_updates}
                    lastCall={movie.latest_called}
                  />
                );
              })}
          </GridList>
        </div>
      </PageContent>
    );
  }
}

function contentPh() {
  return (
    <React.Fragment>
      <ReactPlaceholder ready={false} children={""} showLoadingAnimation customPlaceholder={moviePosterBigPH} />
      <ReactPlaceholder ready={false} children={""} showLoadingAnimation customPlaceholder={moviePosterBigPH} />
      <ReactPlaceholder ready={false} children={""} showLoadingAnimation customPlaceholder={moviePosterBigPH} />
      <ReactPlaceholder ready={false} children={""} showLoadingAnimation customPlaceholder={moviePosterBigPH} />
      <ReactPlaceholder ready={false} children={""} showLoadingAnimation customPlaceholder={moviePosterBigPH} />
      <ReactPlaceholder ready={false} children={""} showLoadingAnimation customPlaceholder={moviePosterBigPH} />
      <ReactPlaceholder ready={false} children={""} showLoadingAnimation customPlaceholder={moviePosterBigPH} />
      <ReactPlaceholder ready={false} children={""} showLoadingAnimation customPlaceholder={moviePosterBigPH} />
    </React.Fragment>
  );
}

function withContent(movies) {
  var newMovies = movies.filter(function(movie) {
    return movie.has_content;
  });

  return newMovies;
}

function sortByRelease(movies) {
  movies = movies.sort(function(a, b) {
    return a.startdate > b.startdate;
  });

  return (movies = withContent(movies));
}

export default withAxios(FavoritesContent);
