import React, { Component } from "react";

import Logo from "../../img/Logo.svg";

import CloseIcon from "./../../img/Close.svg";

import MenuIcon from "../../img/Main.svg";
import HomeIcon from "../../img/Home.svg";
import MoviesIcon from "../../img/Startliste.svg";
import UploadsIcon from "../../img/Updates.svg";
import FavoritenIcon from "../../img/Favoriten.svg";
import ClassicsIcon from "../../img/Leinwand.svg";
import OpenAirIcon from "../../img/OpenAir.svg";
import PresentationIcon from "../../img/Presentation.svg";
import GeneralIcon from "../../img/Basics.svg";
import VVKIcon from "../../img/VVK.svg";
import CinemaIcon from "../../img/Leinwand2.svg";
import FoyerIcon from "../../img/Lobby.svg";
import SocialIcon from "../../img/Social.svg";
import WebsiteIcon from "../../img/Website.svg";

import DocImage from "../../img/Doc.svg";
import DownloadsIcon from "../../img/Downloads.svg";
import SaveIcon from "../../img/Save.svg";
import CopyIcon from "../../img/Copy.svg";

import InlineIcon from "../../img/InlineIcon.svg";
import WrapIcon from "../../img/WrapIcon.svg";

import ArrowLeft from "../../img/ArrowLeft.svg";
import ArrowRight from "../../img/ArrowRight.svg";

import NotificationsIcon from "../../img/Bell.svg";
import NotificationsActiveIcon from "../../img/Bell_Active.svg";
import FavoritesIcon from "../../img/Fav.svg";
import FavoritesActiveIcon from "../../img/Fav_Active.svg";
import FavoritesBlackIcon from "../../img/Heart_empty.svg";
import FavoritesBlackActiveIcon from "../../img/Heart_full.svg";
import PosterFavoriteIcon from "../../img/PosterFavoriteIcon.svg";
import PosterFavoriteActiveIcon from "../../img/PosterFavoriteActiveIcon.svg";
import UpdatesIcon from "../../img/UpdatesIcon.svg";
import backIcon from "../../img/BackIcon.png";

import DeleteIcon from "../../img/DeleteIcon.svg";
import BinIcon from "../../img/bin.svg";
import AddIcon from "../../img/AddIcon.svg";

import DropdownIconWhite from "../../img/DropdownWhite.svg";
import DropdownIcon from "../../img/DropDownArrow.svg";

import PlayIcon from "../../img/PlayIcon.svg";
import PlayIconBlack from "../../img/PlayIconBlack.svg";
import SmallPlayIcon from "../../img/SmallPlayIcon.svg";
import SmallPlayIconBlack from "../../img/SmallPlayIconBlack.svg";

import GS from "../../img/gs.svg";
import TD from "../../img/3d.svg";
import IMAX from "../../img/imax.svg";
import OV from "../../img/OV.svg";

import MediaImageIcon from "../../img/Bild.svg";
import MediaImageTextIcon from "../../img/BildText.svg";

import OverviewIcon from "../../img/Uebersicht.svg";
import DiskIcon from "../../img/Disk.svg";
//import FontIcon from "./../img/Titel.svg";
//import FilmstartIcon from "./../img/Filmstart.svg";
//import InfoIcon from "./../img/InfosWhite.svg";
//import ImageIcon from "./../img/Image.svg";
//import MovieIcon from "./../img/Movie.svg";
//import TextIcon from "./../img/Text.svg";
//import PosterIcon from "./../img/Poster.svg";

import AppStoreIcon from "../../img/AppStore.svg";
import GooglePlayIcon from "../../img/PlayStore.svg";

import mobileLogo from "../../img/Logo.png";
import mobileMenuIcon from "../../img/MenuIcon.png";

import profilIcon from "../../img/ProfilIcon.png";
import logoutIcon from "../../img/LogoutIcon.png";
import BookIcon from "../../img/Book.svg";

class IconContainer extends Component {
  state = {};
  render() {
    return (
      <div
        style={{
          width: 20,
          height: 30,
          backgroundColor: this.props.backgroundColor
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

class InfoIcon extends Component {
  state = {};
  render() {
    return (
      <IconContainer backgroundColor={this.props.backgroundColor}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <title>Menu Item/Icon/Infos White</title>
          <desc>Created with Sketch.</desc>
          <g id="Menu-Item/Icon/Infos-White" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M17,9.99933731 C17,13.8654738 13.8654738,17 9.99933731,17 C6.1332008,17 3,13.8654738 3,9.99933731 C3,6.1332008 6.1332008,3 9.99933731,3 C13.8654738,3 17,6.1332008 17,9.99933731 Z"
              id="Stroke-1"
              stroke={this.props.color}
            />
            <text
              id="i"
              font-family="HelveticaNeue-Medium, Helvetica Neue"
              font-size="11"
              font-weight="400"
              fill={this.props.color}
            >
              <tspan x="8.6745" y="11">
                i
              </tspan>
            </text>
          </g>
        </svg>
      </IconContainer>
    );
  }
}

class FilmstartIcon extends Component {
  state = {};
  render() {
    return (
      <IconContainer backgroundColor={this.props.backgroundColor}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <title>Menu Item/Icon/Filmstart</title>
          <desc>Created with Sketch.</desc>
          <g id="Menu-Item/Icon/Filmstart" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon
              id="poster"
              stroke={this.props.color}
              points="4.21960356 17 15.7803964 17 15.7803964 9.22363281 13.2785645 6.54101563 11.0262451 4.28312783 4.21960356 4.28312783"
            />
            <polyline id="Path-16" stroke={this.props.color} points="11 4 11 9.48083496 15.8503418 9.48083496" />
          </g>
        </svg>
      </IconContainer>
    );
  }
}

class FontIcon extends Component {
  state = {};
  render() {
    return (
      <IconContainer backgroundColor={this.props.backgroundColor}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <title>Menu Item/Icon/Titel</title>
          <desc>Created with Sketch.</desc>
          <g id="Menu-Item/Icon/Titel" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="T" transform="translate(4.000000, 2.800000)" stroke={this.props.color}>
              <polyline
                id="Path-14"
                points="0.468125137 4.96506601 0.468125137 2.10429042 11.4821112 2.10429042 11.4821112 4.96506601"
              />
              <path d="M6.04195804,1.62048223 L6.04195804,12.3512427" id="Path-15" />
              <path d="M2.8951049,12.2441195 L9.18881119,12.2441195" id="Path-17" />
            </g>
          </g>
        </svg>
      </IconContainer>
    );
  }
}

class PosterIcon extends Component {
  state = {};
  render() {
    return (
      <IconContainer backgroundColor={this.props.backgroundColor}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <title>Menu Item/Icon/Poster</title>
          <desc>Created with Sketch.</desc>
          <g id="Menu-Item/Icon/Poster" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M4.21960356,17 L15.7803964,17 L15.7803964,4.28312783 L4.21960356,4.28312783 L4.21960356,17 Z M4.70168862,16.5179149 L8.50750164,9.72594912 L11.1560793,14.1098018 L12.6011784,11.9907084 L15.395422,17 M10.0485553,2.186 L10.0485553,6.38025566 L10.0485553,2.186 Z"
              id="poster"
              stroke={this.props.color}
            />
          </g>
        </svg>
      </IconContainer>
    );
  }
}

class MovieIcon extends Component {
  state = {};
  render() {
    return (
      <IconContainer backgroundColor={this.props.backgroundColor}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <title>Menu Item/Icon/Movie</title>
          <desc>Created with Sketch.</desc>
          <g id="Menu-Item/Icon/Movie" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M4,16 L16,16 L16,5 L4,5 L4,16 Z M7,5 L7,16 L7,5 Z M13,5 L13,16 L13,5 Z M4,8.5 L7,8.5 L4,8.5 Z M4,12.5 L7,12.5 L4,12.5 Z M13,8.5 L16,8.5 L13,8.5 Z M13,12.5 L16,12.5 L13,12.5 Z"
              id="trailer-3"
              stroke={this.props.color}
            />
          </g>
        </svg>
      </IconContainer>
    );
  }
}

class ImageIcon extends Component {
  state = {};
  render() {
    return (
      <IconContainer backgroundColor={this.props.backgroundColor}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <title>Menu Item/Icon/Image</title>
          <desc>Created with Sketch.</desc>
          <g id="Menu-Item/Icon/Image" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M4,16 L16,16 L16,5 L4,5 L4,16 Z M4.37939453,16 L7.20027657,10 L9.43041527,13.6160203 L10.6472085,11.8680865 L13,16 L4.37939453,16 Z M14,8.5 C14,9.32919255 13.3282887,10 12.4994179,10 C11.6717113,10 11,9.32919255 11,8.5 C11,7.67197205 11.6717113,7 12.4994179,7 C13.3282887,7 14,7.67197205 14,8.5 Z"
              id="pic"
              stroke={this.props.color}
            />
          </g>
        </svg>
      </IconContainer>
    );
  }
}

class TextIcon extends Component {
  state = {};
  render() {
    return (
      <IconContainer backgroundColor={this.props.backgroundColor}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <title>Menu Item/Icon/Text</title>
          <desc>Created with Sketch.</desc>
          <g id="Menu-Item/Icon/Text" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M4,16 L16,16 L16,5 L4,5 L4,16 Z M6.834,8 L13.25,8 L6.834,8 Z M6.834,10.5 L13.25,10.5 L6.834,10.5 Z M6.834,13 L10.25,13 L6.834,13 Z"
              id="txt-2"
              stroke={this.props.color}
            />
          </g>
        </svg>
      </IconContainer>
    );
  }
}


export {
  Logo,
  MenuIcon,
  HomeIcon,
  MoviesIcon,
  UploadsIcon,
  FavoritenIcon,
  ClassicsIcon,
  OpenAirIcon,
  PresentationIcon,
  GeneralIcon,
  VVKIcon,
  CinemaIcon,
  FoyerIcon,
  SocialIcon,
  OverviewIcon,
  FontIcon,
  FilmstartIcon,
  InfoIcon,
  ImageIcon,
  MovieIcon,
  TextIcon,
  WebsiteIcon,
  PosterIcon,
  DocImage,
  DownloadsIcon,
  SaveIcon,
  CopyIcon,
  InlineIcon,
  WrapIcon,
  ArrowLeft,
  ArrowRight,
  NotificationsActiveIcon,
  NotificationsIcon,
  FavoritesActiveIcon,
  FavoritesIcon,
  FavoritesBlackIcon,
  FavoritesBlackActiveIcon,
  CloseIcon,
  DeleteIcon,
  AddIcon,
  DropdownIconWhite,
  PosterFavoriteActiveIcon,
  PosterFavoriteIcon,
  UpdatesIcon,
  PlayIcon,
  PlayIconBlack,
  SmallPlayIcon,
  SmallPlayIconBlack,
  GS,
  TD,
  IMAX,
  OV,
  BinIcon,
  MediaImageIcon,
  MediaImageTextIcon,
  DropdownIcon,
  DiskIcon,
  AppStoreIcon,
  GooglePlayIcon, 
  mobileLogo,
  mobileMenuIcon,
  backIcon, 
  logoutIcon, 
  profilIcon,
  BookIcon
};
