import React, { Component } from "react";
import { TextField, MenuItem, Popper, Fade, Paper, Typography, IconButton, InputAdornment } from "@material-ui/core";
import Colors from "../../extras/Colors";
import { DeleteIcon, AddIcon } from "./Images";
import DropDownArrow from "../../img/DropDownArrow.svg";

const styles = {
  container: {
    position: "relative"
  },
  popper: {
    zIndex: 9999,
    width: 285,
    maxHeight: 400,
    overflow: "scroll",
    marginTop: 10,
    marginLeft: 8
  },
  selectedContainer: {
    paddingTop: 5
  },
  selected: {
    height: 32,
    width: "100%",
    borderColor: Colors.lightGrey,
    borderWidth: 1,
    borderStyle: "solid",
    marginTop: 5,
    display: "flex",
    alignItems: "center"
  }
};

class TextFieldMultiSelect extends Component {
  updateQuery = e => {
    this.setState({ query: e.target.value });
    this.setState({ anchorEl: e.target });
  };
  itemSelected = item => {
    var cp = this;
    if (!this.props.selectedList.includes(item) && item !== "") {
      this.props.selectedList.push(item);
    }

    cp.setState({ anchorEl: null });
    setTimeout(
      function() {
        cp.setState({ query: "" });
      },
      this.props.list ? 350 : 0
    );
    this.props.parent.forceUpdate();
  };
  removeItem = item => {
    var index = this.props.selectedList.indexOf(item);
    this.props.selectedList.splice(index, 1);
    this.forceUpdate();
    this.props.parent.forceUpdate();
  };
  state = {
    query: "",
    anchorEl: null
  };
  render() {
    const cp = this;
    const results = searchQuery(this.state.query, this.props.list);
    return (
      <div style={styles.container}>
        <TextField
          onKeyDown={e => {
            if (e.key === "Enter" && !this.props.list && this.state.query.length > 0) {
              cp.itemSelected(this.state.query);
            }
          }}
          onBlur={() => {
            if (!this.props.list && this.state.query.length > 0) {
              cp.itemSelected(this.state.query);
            }
          }}
          value={this.state.query}
          onChange={e => this.updateQuery(e)}
          label={this.props.label}
          fullWidth
          error={this.props.error}
          helperText={this.props.helperText}
          InputProps={{
            endAdornment: (
              <React.Fragment>
                {!this.props.list && (
                  <InputAdornment variant="filled" position="end">
                    <IconButton onClick={() => cp.itemSelected(this.state.query)} style={{ padding: 5 }}>
                      <img src={AddIcon} alt="" />
                    </IconButton>
                  </InputAdornment>
                )}
                {this.props.list && <img src={DropDownArrow} />}
              </React.Fragment>
            )
          }}
        />
        {this.props.selectedList.length > 0 && (
          <div style={styles.selectedContainer}>
            {this.props.selectedList.map(function(item, i) {
              return (
                <div key={i} style={styles.selected}>
                  <Typography style={{ flex: 1, marginLeft: 10 }} color="primary" variant="body2">
                    {item}
                  </Typography>
                  <IconButton onClick={() => cp.removeItem(item)} style={{ padding: 0 }}>
                    <img src={DeleteIcon} alt="" />
                  </IconButton>
                </div>
              );
            })}
          </div>
        )}
        <Popper
          transition
          modifiers={{
            flip: {
              enabled: false
            },
            preventOverflow: {
              enabled: false
            }
          }}
          style={styles.popper}
          fullWidth
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl) && results.length > 0 && this.state.query.length > 0}
          placement="bottom"
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                {results.map(function(item, i) {
                  return (
                    <MenuItem key={i} onClick={() => cp.itemSelected(item)}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    );
  }
}

function searchQuery(query, list) {
  var newList = [];
  if (!list) return newList;
  for (var i = 0; i < list.length; i++) {
    if (list[i].toLowerCase().indexOf(query.toLowerCase()) !== -1) {
      newList.push(list[i]);
    }
  }
  return newList;
}

export default TextFieldMultiSelect;
