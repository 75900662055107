import React, { Component } from "react";
import { withAxios } from "../Misc/AxiosManager";
import { Logo } from "../Misc/Images";
import { Typography, CircularProgress } from "@material-ui/core";
import query from "../Misc/QueryString";
import URLs from "../../extras/URLs";
import { Link } from "react-router-dom";
import Log from "../Misc/Log";

class RejectContent extends Component {
  componentDidMount() {
    this.user_id = query.getParams().id;

    if (this.user_id !== undefined && this.user_id !== "") {
      this.props.axios
        .get(URLs.RejectURL + this.user_id)
        .then(response => {
          Log(response.data);
          const status = response.data.status;
          if (status === "success") {
            this.setState({ success: true });
          } else if (status === "rejected") {
            this.setState({ rejected: true });
          }

          this.setState({ loading: false });
        })
        .catch(error => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
    }
  }
  state = {
    loading: true,
    success: false,
    rejected: false
  };
  render() {
    return (
      <div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center"
          }}
        >
          <Link to="/">
            <img src={Logo} />
          </Link>
          <div style={{ marginTop: 20 }}>
            {this.state.loading && <CircularProgress size={22} />}
            {!this.state.loading && this.state.success && (
              <div>
                <Typography variant="h1" color="primary">
                  Benutzer abgelehnt
                </Typography>
                <Typography variant="body2" color="primary" style={{ marginTop: 5 }}>
                  Der Benutzer wurde abgelehnt und
                  <br />
                  wird umgehend per E-Mail benachrichtigt.
                </Typography>
              </div>
            )}
            {!this.state.loading && this.state.rejected && (
              <div>
                <Typography variant="h1" color="primary">
                  Benutzer bereits abgelehnt
                </Typography>
                <Typography variant="body2" color="primary" style={{ marginTop: 5 }}>
                  Dieser Benutzer wurde bereits abgelehnt.
                </Typography>
              </div>
            )}
            {!this.state.loading && !this.state.success && !this.state.rejected && (
              <div>
                <Typography variant="h1" color="primary">
                  Benutzer konnte nicht abgeleht werden
                </Typography>
                <Typography variant="body2" color="primary" style={{ marginTop: 5 }}>
                  Der Benutzer konnte leider nicht abgelehnt werden.
                  <br />
                  Überprüfen Sie die Benutzer ID und versuchen Sie es erneut.
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withAxios(RejectContent);
