import React, { Component } from "react";
import SectionHeadline from "../Misc/SectionHeadline";
import { MediaPH } from "../Misc/Placeholder";

import "react-placeholder/lib/reactPlaceholder.css";
import ReactPlaceholder from "react-placeholder";
import URLs from "../../extras/URLs";
import Colors from "../../extras/Colors";
import Moment from "react-moment";
import { Dialog, DialogContent, Typography, IconButton, Tooltip, Portal } from "@material-ui/core";
import ImageLoader from "../Misc/ImageLoader";
import DownloadFile from "../../extras/DownloadFile";

import ImageAsset from "../Movie/ImageAsset";
import { PlayIcon, PlayIconBlack, SmallPlayIcon, SmallPlayIconBlack, MediaImageIcon, MediaImageTextIcon, DiskIcon, WrapIcon } from "../Misc/Images";
import ArrowButton from "../Misc/ArrowButton";
import ArrowNavigation from "../Misc/ArrowNavigation";
import CloseButton from "../Misc/CloseButton";
import { withAxios, DefaultErrorHandling } from "../Misc/AxiosManager";
import { COMPACT, MEDIA_TYPE } from "../../extras/Keys";
import { withRouter } from "react-router";
import WindowSize from "../Misc/WindowSize";
import Strings from "../../extras/Strings";
import Radium, { StyleRoot } from 'radium';
import MediaQuery from "react-responsive";
import $ from "jquery";

const TYPE = {
  BIG: "big",
  TEXT: "text",
  SMALL: "small"
};

const styles = {
  content: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    '@media (max-width: 767px)': {
      display: "block"
    },
  },
  container: {
    position: "relative",
    height: 350,
    width: "calc(33.3% - 10px)",
    '@media (max-width: 767px)': {
      width: "100%"
    },
    background: "white",
    borderColor: Colors.lightGrey,
    borderStyle: "solid",
    borderWidth: 1,
    boxSizing: "border-box",
    display: "inline-block",
    margin: 5,
    transform: "translate(-5px, -5px)"
  },
  textContainer: {
    padding: "15px 20px 20px 20px"
  },
  title: {
    paddingBottom: 10
  },
  id: {
    padding: 0,
    margin: 0,
    marginTop: 4
  },
  copywrite: {
    padding: 0
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 42,
    paddingLeft: 20,
    paddingRight: 13,
    display: "flex",
    alignItems: "center",
    zIndex: 2
  },
  line: {
    position: "absolute",
    height: 1,
    width: "calc(100% - 40px)",
    background: Colors.line
  },
  title: {
    flex: 1
  },
  centered: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)"
  },
  videoOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    background: "rgba(0, 0, 0, 0.5)"
  },
  fsk: {
    position: "absolute",
    right: 7,
    top: 7,
    width: 35,
    height: 35
  },
  videoPreview: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    background: "black"
  },
  dialogArrowButton: {
    position: "fixed",
    top: "50%",
    transform: "translateY(-50%)"
  },
  toggleIconsContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: -7
  },
  toggleIcons: {
    padding: 5,
    marginTop: -18
  },
  iconButton: {
    padding: 5
  }
};

class Media extends Component {
  componentWillMount() {
    this.category_slug = this.props.category_slug;
  }
  componentDidMount() {
    let mediaType = localStorage.getItem(MEDIA_TYPE);
    if (!mediaType) mediaType = TYPE.BIG;
    this.setState({ mediaType: mediaType });
    this.loadData();
  }
  loadData = () => {
    const cp = this;
    this.props.axios
      .post(URLs.MovieContentURL, {
        category_slug: this.category_slug,
      })
      .then(response => {
        cp.setState({ loaded: true });
        cp.setState({ media: sortData(response.data.data.uploads) });
      })
      .catch(error => {
        DefaultErrorHandling(error);
      });
  };
  openDialog = pos => {
    this.setState({ dialogOpen: true });
    this.setState({ currentPos: pos });
  };
  closeDialog = () => {
    this.setState({ dialogOpen: false });
    this.setState({ playVideo: false });
  };
  downloadFile = (id, isAsset) => {
    new DownloadFile(id, "download.zip", isAsset);
  };
  nextMedia = () => {
    var pos = this.state.currentPos;
    if (pos < this.state.media.length - 1) pos++;
    else pos = 0;

    this.setState({ currentPos: pos });
  };
  prevMedia = () => {
    var pos = this.state.currentPos;
    if (pos > 0) pos--;
    else pos = this.state.media.length - 1;

    this.setState({ currentPos: pos });
  };
  setMediaType = type => {
    this.setState({ mediaType: type });
    localStorage.setItem(MEDIA_TYPE, type);
  };
  state = {
    loaded: false,
    mediaType: false,
    media: [],
    currentPos: -1,
    dialogOpen: false,
    playVideo: false
  };
  render() {
    const cp = this;
    const columns = this.props.screenWidth > 1100 ? (this.props.screenWidth > 1500 ? 4 : 3) : 2;
    const currentMedia = this.state.media[this.state.currentPos];
    return (
      <StyleRoot>
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <SectionHeadline style={{ opacity: this.props.title !== "████" ? 1 : 0.5 }}>
            {this.props.title}
          </SectionHeadline>
        </div>
        <div style={{ width: "calc(100% + 10px)", paddingBottom: "60px"}}>
          <div style={styles.content}>
            {/* PLACEHOLDER */}
            {!this.state.loaded && (
              <React.Fragment>
                <ReactPlaceholder
                  ready={false}
                  children={""}
                  showLoadingAnimation
                  customPlaceholder={MediaPH(columns)}
                />
                <ReactPlaceholder
                  ready={false}
                  children={""}
                  showLoadingAnimation
                  customPlaceholder={MediaPH(columns)}
                />
                <ReactPlaceholder
                  ready={false}
                  children={""}
                  showLoadingAnimation
                  customPlaceholder={MediaPH(columns)}
                />
              </React.Fragment>
            )}

            {this.state.loaded && (
              <React.Fragment>
                {this.state.media.map(function(item, i) {
                  return (
                    <DynamicMedia cols={columns} mediaType={cp.state.mediaType} key={i} id={i} data={item} cp={cp} />
                  );
                })}
              </React.Fragment>
            )}
          </div>
        </div>

        {/* SELECTED DIALOG /////////////////////////////////////////// */}
        {currentMedia && (
          <Dialog open={this.state.dialogOpen} onClose={this.closeDialog} scroll="body" maxWidth="sm">
            <DialogContent style={{ padding: 0, margin: 0 }}>
              <div
                style={{
                  position: "relative",
                  height: 380,
                  textAlign: "center",
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  borderStyle: "solid",
                  borderBottomColor: Colors.lightGrey,
                  overflow: "hidden"
                }}
              >
                <ImageLoader classic full={isVideo(currentMedia)} src={URLs.MediaURL + "/" + currentMedia.thumb} />
                {!currentMedia.thumb && currentMedia.assets && currentMedia.assets.length > 0 && currentMedia.assets[0].type != "audio" && (
                  <Typography variant="h6" color="primary" style={styles.centered}>
                    {"." + currentMedia.assets[0].files[0].ext}
                  </Typography>
                )}
                {/* PLAY ICON & FSK ON VIDEOS */}
                {currentMedia.assets && currentMedia.assets.length > 0 && currentMedia.assets[0].type === "video" && (
                  <div
                    style={[styles.videoOverlay, { cursor: "pointer" }]}
                    onClick={() => this.setState({ playVideo: true })}
                  >
                    {/* FSK */}
                    <img style={styles.centered} src={PlayIcon} alt="" />
                    {currentMedia.fsk && <img style={styles.fsk} src={URLs.MediaURL + "/" + currentMedia.fsk} alt="" />}
                    {this.state.playVideo && (
                      <video
                        id="media-video"
                        style={styles.videoPreview}
                        autoPlay
                        controls
                        src={URLs.MediaURL + "/" + getVideoPreview(currentMedia)}
                      />
                    )}
                  </div>
                )}
                {/* DARK PLAY ICON ON AUDIO */}
                {currentMedia.assets && currentMedia.assets.length > 0 && currentMedia.assets[0].type === "audio" && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ playVideo: true })}
                  >
                    <img style={styles.centered} src={PlayIconBlack} alt="" />
                    {this.state.playVideo && (
                      <video
                        id="media-video"
                        style={styles.videoPreview}
                        autoPlay
                        controls
                        src={URLs.MediaURL + "/" + getAudioPreview(currentMedia)}
                      />
                    )}
                  </div>
                )}
              </div>

              <div style={[styles.textContainer, { paddingBottom: 0 }]}>
                <div style={{ display: "flex" }}>
                  <Typography color="primary" variant="body1" style={styles.title}>
                    {currentMedia.title}
                  </Typography>
                  <Typography align="right" color="primary" variant="body1" style={styles.date}>
                    <Moment format="DD. MMMM YYYY" unix>
                      {currentMedia.created_at}
                    </Moment>
                  </Typography>
                </div>
                <Typography color="primary" variant="body2" style={styles.id}>
                  {currentMedia.freetext2}
                </Typography>
                <Typography color="secondary" variant="body2" style={styles.copywrite}>
                  {currentMedia.freetext}
                </Typography>
                {currentMedia.assets.map(function(asset, i) {
                  return (
                    <ImageAsset
                      hasVideo={hasVideo(currentMedia)}
                      asset={asset}
                      key={i}
                      style={{ paddingTop: i === 0 ? 20 : 0 }}
                      downloadFile={id => cp.downloadFile(id, true)}
                    />
                  );
                })}
              </div>

              {/* ARROWS & CLOSE BUTTON /////////////////////////////////////// */}
              <CloseButton onClick={this.closeDialog} />
              {this.state.media.length > 1 && (
                <React.Fragment>
                  <ArrowButton onClick={() => this.prevMedia()} style={{ ...styles.dialogArrowButton, left: 30 }} />
                  <ArrowButton
                    onClick={() => this.nextMedia()}
                    right
                    style={{ ...styles.dialogArrowButton, right: 30 }}
                  />
                  <ArrowNavigation leftPressed={() => this.prevMedia()} rightPressed={() => this.nextMedia()} />
                </React.Fragment>
              )}
            </DialogContent>
          </Dialog>
        )}
      </React.Fragment>
      </StyleRoot>
    );
  }
}

function DynamicMedia(props) {
  return <CompactMediaRadium id={props.id} data={props.data} cp={props.cp} cols={props.cols} />;
}

const CompactMediaRadium = Radium(CompactMedia);
function CompactMedia(props) {
  return (
    
    <div key={props.data.id} style={{ ...tileStyle(props.cols), height: 220 }}>
      <div
        onClick={() => props.cp.openDialog(props.id)}
        style={{
          position: "relative",
          width: "100%",
          height: 175,
          overflow: "hidden",
          borderWidth: 0,
          borderBottomWidth: 1,
          borderStyle: "solid",
          borderColor: Colors.lightGrey,
          cursor: "pointer"
        }}
      >
        <ImageLoader classic full={isVideo(props.data)} src={URLs.MediaURL + "/" + props.data.thumb} />
        {/* FILE EXTENSION IF THE IS NO THUMBNAIL */}
        {!props.data.thumb && props.data.assets && props.data.assets.length > 0 && props.data.assets[0].type != "audio" && (
          <Typography variant="h6" color="primary" style={styles.centered}>
            {"." + props.data.assets[0].files[0].ext}
          </Typography>
        )}
        {/* PLAY ICON & FSK ON VIDEOS */}
        {props.data.assets && props.data.assets.length > 0 && props.data.assets[0].type === "video" && (
          <div style={styles.videoOverlay}>
            <img style={styles.centered} src={SmallPlayIcon} alt="" />
            {/* FSK */}
            {props.data.fsk && <img style={styles.fsk} src={URLs.MediaURL + "/" + props.data.fsk} alt="" />}
          </div>
        )}
        {/* DARK PLAY ICON ON AUDIO */}
        {props.data.assets && props.data.assets.length > 0 && props.data.assets[0].type === "audio" && (
          <div>
            <img style={styles.centered} src={SmallPlayIconBlack} alt="" />
          </div>
        )}
      </div>
      <div style={styles.footer}>
        <Typography color="primary" variant="body1" style={styles.title}>
          {props.data.title}
        </Typography>
        <Tooltip title={Strings.showAssets} placement="top">
          <IconButton style={styles.iconButton} onClick={() => props.cp.openDialog(props.id)}>
            <img src={DiskIcon} alt="" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
    
  );
}

function tileStyle(c) {
  if($(document).innerWidth() >= 768){
      return {
        position: "relative",
        height: 350,
        //width: "calc(" + 100 / c + "% - 10px)",
        width: "100%",
        width: "calc(" + 100 / c + "% - 10px)",
        background: "white",
        borderColor: Colors.lightGrey,
        borderStyle: "solid",
        borderWidth: 1,
        boxSizing: "border-box",
        display: "inline-block",
        margin: 5,
        transform: "translate(-5px, -5px)"
      };
}
else{
  return {
    position: "relative",
    height: 350,
    width: "100%",
    background: "white",
    borderColor: Colors.lightGrey,
    borderStyle: "solid",
    borderWidth: 1,
    boxSizing: "border-box",
    display: "inline-block",
    margin: 5,
    transform: "translate(-5px, -5px)"
  };
}
  
  
}

function isVideo(upload) {
  return upload.assets && upload.assets.length > 0 && upload.assets[0].type === "video";
}

function hasVideo(upload) {
  for (var i = 0; i < upload.assets.length; i++) {
    const assetType = upload.assets[i].type;
    if (assetType === "video") return true;
  }
  return false;
}

function getVideoPreview(upload) {
  // Find MP4
  for (var i = 0; i < upload.assets.length; i++) {
    var asset = upload.assets[i];
    for (var f = 0; f < asset.files.length; f++) {
      var file = asset.files[f];
      if (file.ext.toLowerCase().indexOf("mp4") !== -1) {
        return file.file;
      }
    }
  }
}

function getAudioPreview(upload) {
  // Find MP4
  for (var i = 0; i < upload.assets.length; i++) {
    var asset = upload.assets[i];
    for (var f = 0; f < asset.files.length; f++) {
      var file = asset.files[f];
      if (file.ext.toLowerCase().indexOf("mp3") !== -1 || file.ext.toLowerCase().indexOf("wav") !== -1 || file.ext.toLowerCase().indexOf("ogg") !== -1) {
        return file.file;
      }
    }
  }
}

function sortData(data) {
  return data.sort(function(a, b) {
    return a.created_at > b.created_at;
  });
}

export default WindowSize(withRouter(withAxios(Radium(Media))));
