import React, { Component } from "react";
import LoginNav from "./LoginNav";
import LoginContent from "./LoginContent";
import Profile from "../Topbar/Profile";

class LoginPage extends Component {
  toggleRegister = () => {
    this.setState({ register: !this.state.register });
  };
  state = {
    register: false
  };
  render() {
    return (
      <React.Fragment>
        <LoginNav handler={this.toggleRegister} />
        <LoginContent />
        <Profile register open={this.state.register} toggleProfile={this.toggleRegister} />
      </React.Fragment>
    );
  }
}

export default LoginPage;
