import React, { Component } from "react";
import Colors from "../../extras/Colors";
import ResetPageScroll from "./ResetPageScroll";
import Radium, { StyleRoot } from 'radium';
import MediaQueryCss from "../Responsive/MediaQueryCss";
import $ from "jquery";

const styles = {
  content: {
    display: "flex",
    width: "calc(100% - 220px)",
    //width: "100%",
    marginLeft: 220,
    backgroundColor: Colors.lightLightGrey,
    minHeight: 700,
    overflowY: 'scroll',
    '@media (max-width: 320px)': {
      marginLeft: 0,
      width: "100%",
      //backgroundColor: 'red'
      //marginTop: 80
    },
    '@media (max-width: 370px) and (min-width: 321px)': {
      marginLeft: 0,
      width: "100%", 
      //backgroundColor: 'yellow'
      //marginTop: 80
    },
    '@media (max-width: 480px) and (min-width: 371px)': {
      marginLeft: 0,
      width: "100%",
      //backgroundColor: 'green'
      //marginTop: 80
    },
    '@media (max-width: 767px) and (min-width: 481px)': {
      marginLeft: 0,
      //marginTop: 65,
      width: "100%",
      //backgroundColor: 'orange',
      //marginTop: 80
    }
  }
};

class PageContent extends Component {
  componentDidMount() {
      //$("#page-content").css("width", ($(document).innerWidth() - 220 ) + 'px'); 
      
  }
  state = {};
  render() {
    return (
      <StyleRoot>
      <React.Fragment>
        <ResetPageScroll />
        <div id="page-content" style={styles.content}>{this.props.children}</div>
      </React.Fragment>
      </StyleRoot>
    );
  }
}

export default Radium(PageContent);
