import React, { Component } from "react";
import { Typography } from "@material-ui/core";

const styles = {
  SectionHeadline: {
    paddingBottom: 20
  }
};

class SectionHeadline extends Component {
  state = {};
  render() {
    return (
      <div style={this.props.style}>
        <Typography color="primary" variant="h2" style={styles.SectionHeadline}>
          {this.props.children}
        </Typography>
      </div>
    );
  }
}

export default SectionHeadline;
