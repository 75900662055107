import React, { Component } from "react";

import Radium from "radium";

class Gradient extends Component {
  state = {};
  render() {
    return (
      <div style={[this.props.style, { backgroundImage: gradientStyle(this.props.inverted) }]}>
        {this.props.children}
      </div>
    );
  }
}

function gradientStyle(inverted) {
  if (inverted) return "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))";
  return "linear-gradient(rgba(0, 0, 0, 0.5) , rgba(0, 0, 0, 0))";
}

export default Radium(Gradient);
